import React from "react";
import InnerBanner from "../../Home/InnerBanner";
import BredCrumb from "../../Home/BredCrumb";
import CaseProceedingReport from "./Tabs/CaseProceedingReport";

const CaseProceedingReportParent = ({ benchDetails }) => {
  const { id, name, navigateTo } = benchDetails;
  return (
    <>
      <InnerBanner title="Case Status" />
      <BredCrumb title="Case Status" navigateTo={navigateTo} />
      <div className="common-bg mb-3">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="case-details  wow fadeInUp">
                <ul className="nav inner toptab-one">
                  <li>
                    <a className="nav-link active" data-toggle="pill">
                      Case Proceeding Report
                    </a>
                  </li>
                </ul>
                <div className="tab-content-wraper">
                  <div className="tab-pane container active" id="case_details">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="tab-content ">
                          <CaseProceedingReport schemaId={id} bench={name} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseProceedingReportParent;
