import React, { useEffect } from "react";

import BredCrumb from "../Home/BredCrumb";
import InnerBanner from "../Home/InnerBanner";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import Skeleton from "react-loading-skeleton";
import Loader from "../../shared/Loader";
import { useTitle } from "../../../Utilities/pageTitle";
import { capitalizeWords } from "../../../Utilities/FuncUtils";

const FooterMenuContentPage = ({ pageId, navigateTo, endPoint }) => {
  useTitle(capitalizeWords(endPoint));
  const {
    data: pageData,
    isLoading,
    refetch,
  } = useGetInternalDataQuery(`get-page/${pageId}`, { enabled: false });

  useEffect(() => {
    refetch();
  }, [pageId, refetch]);

  // if (isLoading) {
  //   return (
  //     <>
  //       <InnerBanner title={<Skeleton />} />
  //       <BredCrumb title={<Skeleton />} navigateTo={navigateTo} />
  //       <div className="about-content internal-content-wrap">
  //         <div className="container">
  //           <h6>
  //             <Skeleton width={200} />
  //           </h6>
  //           <p>
  //             <Skeleton count={20} />
  //           </p>
  //         </div>
  //       </div>
  //     </>
  //   );
  // }
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <InnerBanner title={endPoint} />
      <BredCrumb title={endPoint} navigateTo={navigateTo} />
      <div className="about-content internal-content-wrap">
        <div className="container">
          {pageData && pageData.data && pageData.status ? (
            <>
              <h6>{pageData.data && pageData.data.title}</h6>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    pageData.data &&
                    pageData.data.body.replace(
                      /(<table>)/,
                      '<table class="table table-striped table-bordered">'
                    ),
                }}
              />
            </>
          ) : (
            <>NO Data Found</>
          )}
        </div>
      </div>
    </>
  );
};

export default FooterMenuContentPage;
