import React from "react";
import { useNavigate } from "react-router-dom";
import BredCrumb from "../../../../frontend/containers/Home/BredCrumb";
import InnerBanner from "../../../../frontend/containers/Home/InnerBanner";
import { Table } from "react-bootstrap";
import { useGetInternalDataQuery } from "../../../../redux/slices/catApiSlice";
import Skeleton from "react-loading-skeleton";
import Loader from "../../../../frontend/shared/Loader";
import { useTitle } from "../../../../Utilities/pageTitle";

const PresentMembers = ({ memberId, navigateTo }) => {
  useTitle("Present Members");
  const navigate = useNavigate();
  const { data: memberList, isLoading } = useGetInternalDataQuery(
    `get-members/${memberId}`
  );

  const handleDate = (value) => {
    return value.split("-").reverse().join("-");
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <InnerBanner title="Present Members" />
      <BredCrumb title="Present Members" navigateTo={navigateTo} />
      <div className="common-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <div className="about-content">
                <h6>Present Members</h6>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="chairman-detail member-detail">
                <div className="container">
                  <div className="table-responsive model-mh">
                    <Table striped bordered hover className="custom-table">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>Profile Image</th>
                          <th>Name</th>
                          <th>Designation</th>
                          <th>Date&nbsp;of&nbsp;Joining</th>
                          <th>Profile</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {memberList &&
                        memberList.status &&
                        memberList.data.length > 0 ? (
                          memberList.data.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <img
                                  className="member-image"
                                  src={item.image}
                                />
                              </td>
                              <td>{item.name}</td>
                              <td>{item.designation}</td>
                              <td>
                                {item.date_of_joining &&
                                  handleDate(item.date_of_joining)}
                              </td>
                              <td className="text-center">
                                <a target="_blank" href={item.upload_docs}>
                                  <i className="fas fa-file-pdf"></i>
                                </a>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() =>
                                    navigate(
                                      `${navigateTo}/listing-chairman-read-more`,
                                      { state: { item } }
                                    )
                                  }
                                  className="search"
                                >
                                  Details
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <>
                            <tr>
                              <td colSpan="7"> No Record Found </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PresentMembers;
