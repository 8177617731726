import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchTourProgramme,
  resetFooterSliderMenuData,
} from "../../../redux/slice/sliceInternal/catSliceFooterTopMenu";
import Loader from "../../shared/Loader";
import BredCrumb from "../Home/BredCrumb";
import InnerBanner from "../Home/InnerBanner";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import { useTitle } from "../../../Utilities/pageTitle";

function TourProgramme({ benchId, navigateTo }) {
  useTitle("Tour Programme");
  const { data: tourProgramme, isLoading } = useGetInternalDataQuery(
    `get-modules/${benchId}/tour-programme`
  );

  const handleDate = (value) => {
    return value.split("-").reverse().join("-");
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <div>
        <InnerBanner title={"Tour Programme "} />
        <BredCrumb title={"Tour Programme "} navigateTo={navigateTo} />
        <div className="common-bg">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                <div className="about-content">
                  <h6>Tour Programme</h6>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="table-responsive">
                  <table className="table table-striped table-bordered common-table bluetable">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>CAT</th>
                        <th>Date of Release</th>
                        <th>Last Date of Application</th>
                        <th>Detail</th>
                        <th>Attachment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tourProgramme &&
                      tourProgramme.status &&
                      tourProgramme.data.length > 0 ? (
                        tourProgramme.data.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.subject}</td>
                            <td>{handleDate(item.date_of_issue)}</td>
                            <td>{handleDate(item.last_date)}</td>
                            <td>{item.detail}</td>
                            <td className="text-center">
                              <a target="_blank" href={`${item.attachment}`}>
                                <i className="fas fa-file-pdf"></i>
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <>
                          <tr>
                            <td colSpan="7">No Data Found</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TourProgramme;
