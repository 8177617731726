import React from "react";
import HeaderMiddle from "./HeaderMiddle";
import Menus from "./Menus";
import TopHeader from "./TopHeader";

const Header = () => {
  return (
    <>
      {/* <TopHeader /> */}
      <HeaderMiddle bench={"principal bench"} to={"/"} />
      <Menus menuId={1} homeLink={"/"} />
    </>
  );
};

export default Header;
