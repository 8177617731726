import React, { useState, useRef } from "react";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import Loader from "../../shared/Loader";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import Skeleton from "react-loading-skeleton";
import BlueNavLinkSkeleton from "../../../Skeleton/BlueNavLinkSkeleton";

const OtherLinks = ({ navLinkId }) => {
  const { data: footerNavLinks, isLoading } = useGetInternalDataQuery(
    `get-footerslider/${navLinkId}`
  );
  const slider = useRef();

  const [playPause, setPlayPause] = useState(false);

  const settings = {
    //dots: true,
    arrows: true,
    infinite: true,
    speed: 800,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  // function to remove
  const newSlug = (name) => {
    return name.replaceAll(" ", "-").toLowerCase();
  };

  const HandlePlayPause = () => {
    setPlayPause(!playPause);
    if (playPause) {
      slider.current.slickPlay();
    }
    if (!playPause) {
      slider.current.slickPause();
    }
  };

  if (isLoading) {
    // Display skeleton loader while data is being fetched
    return <Loader />;
  }

  return (
    <>
      <div className="bottom-slide">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="text-right">
                <strong className="right" onClick={HandlePlayPause}>
                  {!playPause ? (
                    <i className="fas fa-pause"></i>
                  ) : (
                    <i className="fas fa-play"></i>
                  )}
                </strong>
              </div>
              <Slider {...settings} id="impLink_bottom_slider" ref={slider}>
                {footerNavLinks && footerNavLinks.status ? (
                  footerNavLinks.data &&
                  footerNavLinks.data.map((item, index) => (
                    <div key={index}>
                      <NavLink to={newSlug(item.title)}>
                        <span>
                          <img src={item.upload_image_path} alt={item.title} />
                        </span>
                        {item.title}
                      </NavLink>
                    </div>
                  ))
                ) : (
                  <>No data found</>
                )}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherLinks;
