import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: `https://${process.env.REACT_APP_INTERNAL_URL}/CAT_application/public/index.php/api/v1`,
  prepareHeaders: (headers) => {
    headers.set("X-user", "zur1xjb4");
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "CatSliceInternal",
  baseQuery,
  tagTypes: ["CatInternal"],
  endpoints: (builder) => ({}),
});

const baseQueryExternal = fetchBaseQuery({
  baseUrl: `https://${process.env.REACT_APP_EXTERNAL_URL}/catapi`,
  // serializeRequest: (req) => {
  //   if (req.body instanceof FormData) {
  //     // Convert FormData to a plain JavaScript object
  //     const serializedBody = Object.fromEntries(req.body.entries());
  //     return { ...req, body: serializedBody };
  //   }
  //   return req;
  // },
});

export const apiSliceExternal = createApi({
  reducerPath: "CatSliceExternal",
  baseQuery: baseQueryExternal,
  tagTypes: ["CatExternal"],
  endpoints: (builder) => ({}),
});

const baseQueryIp = fetchBaseQuery({
  baseUrl: `https://geolocation-db.com/json/`,
});

export const apiSliceIp = createApi({
  reducerPath: "catSliceIp",
  baseQuery: baseQueryIp,
  tagTypes: ["CatExternal"],
  endpoints: (builder) => ({}),
});
export const apiSliceExternalChart = createApi({
  reducerPath: "CatSliceExternalChart",
  baseQuery: baseQueryExternal,
  tagTypes: ["CatExternalChart"],
  endpoints: (builder) => ({}),
});
