import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import Loader from "../../shared/Loader";

const WhatsNew = ({ benchId }) => {
  const { data: news, isLoading } = useGetInternalDataQuery(
    `get-news/${benchId}`
  );

  const modifiedNewsData =
    news?.data?.length === 1 ? [news?.data[0], news?.data[0]] : news?.data;

  const [playPause, setPlayPause] = useState(false);
  const slider = useRef();
  const settings = {
    //dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 0,
    cssEase: "linear",
  };
  const HandlePlayPause = () => {
    setPlayPause(!playPause);
    if (playPause) {
      slider.current.slickPlay();
    }
    if (!playPause) {
      slider.current.slickPause();
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="whats-new">
      <div className="container">
        <div className="whats-new-cont">
          <div className="news-control">
            <div className="news-left-right">
              <span>What's New</span>
              <a className="pause-btn" onClick={HandlePlayPause}>
                {!playPause ? (
                  <i className="fas fa-pause"></i>
                ) : (
                  <i className="fas fa-play"></i>
                )}
              </a>
            </div>
          </div>
          <div className="whhats-new-slider">
            <div id="whtnew" className="carousel slide" data-ride="carousel">
              <div className="carousel-inner">
                <Slider {...settings} ref={slider}>
                  {news ? (
                    modifiedNewsData?.map((item, index) => (
                      <div key={index} className=" carousel-item-left">
                        <a href={item.upload_image_path} target="_blank">
                          {item.description}
                        </a>
                      </div>
                    ))
                  ) : (
                    <>No Data Found</>
                  )}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsNew;
