import React from "react";
import BredCrumb from "./BredCrumb";
import InnerBanner from "./InnerBanner";
import { useLocation } from "react-router-dom";
const ListingChairmanReadMore = ({ navigateTo }) => {
  const location = useLocation();

  const { designation, name, image, description } = location.state.item;

  return (
    <>
      <InnerBanner title={designation === "Chairman" ? "Chairman" : "Member"} />
      <BredCrumb
        title={designation === "Chairman" ? "Chairman" : "Member"}
        navigateTo={navigateTo}
      />
      <div className="chairman-detail">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 chairmen-detail-left">
              <div className="chairmen-detail-img-sec">
                <img src={image} alt="Chairman" />
              </div>
              <div className="chairman-sec">
                <h5>{name}</h5>
                <h3>{designation}</h3>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 chairmen-detail-right">
              <h5>{name}</h5>
              <h3>{designation}</h3>

              <p
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingChairmanReadMore;
