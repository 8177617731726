import { apiSliceExternalChart } from "./apiSlice";

const catApiSliceExternalChart = apiSliceExternalChart.injectEndpoints({
  endpoints: (builder) => ({
    todayFiledCasesDelhi: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesDelhi: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesDelhi: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesDelhi: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),
    todayFiledCasesAhmedabad: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesAhmedabad: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesAhmedabad: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesAhmedabad: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),

    todayFiledCasesAllahabad: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesAllahabad: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesAllahabad: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesAllahabad: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),

    todayFiledCasesBangalore: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesBangalore: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesBangalore: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesBangalore: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),

    todayFiledCasesChandigarh: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesChandigarh: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesChandigarh: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesChandigarh: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),

    todayFiledCasesChennai: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesChennai: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesChennai: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesChennai: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),

    todayFiledCasesCuttack: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesCuttack: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesCuttack: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesCuttack: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),
    todayFiledCasesErnakulam: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesErnakulam: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesErnakulam: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesErnakulam: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),

    todayFiledCasesHyderabad: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesHyderabad: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesHyderabad: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesHyderabad: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),

    todayFiledCasesGuwahati: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesGuwahati: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesGuwahati: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesGuwahati: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),

    todayFiledCasesJabalpur: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesJabalpur: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesJabalpur: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesJabalpur: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),
    todayFiledCasesJaipur: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesJaipur: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesJaipur: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesJaipur: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),

    todayFiledCasesJammu: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesJammu: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesJammu: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesJammu: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),

    todayFiledCasesJodhpur: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesJodhpur: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesJodhpur: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesJodhpur: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),

    todayFiledCasesKolkata: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesKolkata: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesKolkata: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesKolkata: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),

    todayFiledCasesLucknow: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesLucknow: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesLucknow: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesLucknow: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),

    todayFiledCasesMumbai: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesMumbai: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesMumbai: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesMumbai: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),

    todayFiledCasesPatna: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesPatna: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesPatna: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesPatna: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),

    todayFiledCasesSrinagar: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCasesSrinagar: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCasesSrinagar: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCasesSrinagar: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useTotalFilledCasesDelhiQuery,
  useTotalDisposedCasesDelhiQuery,
  useTotalPendingCasesDelhiQuery,
  useTodayFiledCasesDelhiQuery,
  useTotalFilledCasesAhmedabadQuery,
  useTotalDisposedCasesAhmedabadQuery,
  useTotalPendingCasesAhmedabadQuery,
  useTodayFiledCasesAhmedabadQuery,
  useTotalFilledCasesAllahabadQuery,
  useTotalDisposedCasesAllahabadQuery,
  useTotalPendingCasesAllahabadQuery,
  useTodayFiledCasesAllahabadQuery,
  useTotalFilledCasesBangaloreQuery,
  useTotalDisposedCasesBangaloreQuery,
  useTotalPendingCasesBangaloreQuery,
  useTodayFiledCasesBangaloreQuery,
  useTotalFilledCasesChandigarhQuery,
  useTotalDisposedCasesChandigarhQuery,
  useTotalPendingCasesChandigarhQuery,
  useTodayFiledCasesChandigarhQuery,
  useTotalFilledCasesChennaiQuery,
  useTotalDisposedCasesChennaiQuery,
  useTotalPendingCasesChennaiQuery,
  useTodayFiledCasesChennaiQuery,
  useTotalFilledCasesCuttackQuery,
  useTotalDisposedCasesCuttackQuery,
  useTotalPendingCasesCuttackQuery,
  useTodayFiledCasesCuttackQuery,
  useTotalFilledCasesErnakulamQuery,
  useTotalDisposedCasesErnakulamQuery,
  useTotalPendingCasesErnakulamQuery,
  useTodayFiledCasesErnakulamQuery,
  useTotalFilledCasesHyderabadQuery,
  useTotalDisposedCasesHyderabadQuery,
  useTotalPendingCasesHyderabadQuery,
  useTodayFiledCasesHyderabadQuery,
  useTotalFilledCasesGuwahatiQuery,
  useTotalDisposedCasesGuwahatiQuery,
  useTotalPendingCasesGuwahatiQuery,
  useTodayFiledCasesGuwahatiQuery,
  useTotalFilledCasesJabalpurQuery,
  useTotalDisposedCasesJabalpurQuery,
  useTotalPendingCasesJabalpurQuery,
  useTodayFiledCasesJabalpurQuery,
  useTotalFilledCasesJaipurQuery,
  useTotalDisposedCasesJaipurQuery,
  useTotalPendingCasesJaipurQuery,
  useTodayFiledCasesJaipurQuery,
  useTotalFilledCasesJammuQuery,
  useTotalDisposedCasesJammuQuery,
  useTotalPendingCasesJammuQuery,
  useTodayFiledCasesJammuQuery,
  useTotalFilledCasesJodhpurQuery,
  useTotalDisposedCasesJodhpurQuery,
  useTotalPendingCasesJodhpurQuery,
  useTodayFiledCasesJodhpurQuery,
  useTotalFilledCasesKolkataQuery,
  useTotalDisposedCasesKolkataQuery,
  useTotalPendingCasesKolkataQuery,
  useTodayFiledCasesKolkataQuery,
  useTotalFilledCasesLucknowQuery,
  useTotalDisposedCasesLucknowQuery,
  useTotalPendingCasesLucknowQuery,
  useTodayFiledCasesLucknowQuery,
  useTotalFilledCasesMumbaiQuery,
  useTotalDisposedCasesMumbaiQuery,
  useTotalPendingCasesMumbaiQuery,
  useTodayFiledCasesMumbaiQuery,
  useTotalFilledCasesPatnaQuery,
  useTotalDisposedCasesPatnaQuery,
  useTotalPendingCasesPatnaQuery,
  useTodayFiledCasesPatnaQuery,
  useTotalFilledCasesSrinagarQuery,
  useTotalDisposedCasesSrinagarQuery,
  useTotalPendingCasesSrinagarQuery,
  useTodayFiledCasesSrinagarQuery,
} = catApiSliceExternalChart;
