/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Viewer from "react-viewer";
import InnerBanner from "../InnerBanner";
import BredCrumb from "../BredCrumb";

import Loader from "../../../shared/Loader";
import { useGetInternalDataQuery } from "../../../../redux/slices/catApiSlice";
import { Link } from "react-router-dom";

function EventGallery({ benchId, categoryId, navigateTo }) {
  const { data: gallery, isLoading } = useGetInternalDataQuery(
    `get-gallery/${benchId}/${categoryId}`
  );

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <InnerBanner title={"Event Gallery"} />
      <BredCrumb title={"Event Gallery"} navigateTo={navigateTo} />
      <div className="gallery about-content my-4">
        <div className="container">
          <div className="row">
            <h6>{"Event Gallery"}</h6>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 gallerysec ">
              {gallery &&
                gallery.status &&
                gallery.data.map((item, index) => (
                  <Link
                    className="masonry-item"
                    key={index}
                    to={`gallery-detail/${item.id}`}
                  >
                    <img
                      src={item.file_path}
                      width="550"
                      height="314"
                      alt="Art and Culture"
                      loading="lazy"
                      typeof="foaf:Image"
                      className="img-responsive"
                    />
                    <div className="inner  details ">
                      <h3>{item.title}</h3>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventGallery;
