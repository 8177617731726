import React from "react";
import BredCrumb from "../BredCrumb";
import InnerBanner from "../InnerBanner";
import { NavLink } from "react-router-dom";

function MediaGallery({ navigateTo }) {
  return (
    <div>
      <InnerBanner title={"Media Gallery"} />
      <BredCrumb title={"Media Gallery"} navigateTo={navigateTo} />
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="menu-list">
              <ul>
                <li>
                  <div className="withouthover">
                    <NavLink
                      to={
                        navigateTo === "/"
                          ? `/photo-gallery`
                          : `${navigateTo}/photo-gallery`
                      }
                    >
                      <div className="headingofbox text-center">
                        <div className="iconphoto">
                          <span>
                            <img src="images/photo-library.png" alt="" />
                          </span>
                        </div>
                        <div className="heading-center">Image </div>
                      </div>
                      <div className="box-overlay"></div>
                    </NavLink>
                  </div>
                  <div className="box-menu">
                    <img src="images/phototab.png" alt="" />
                  </div>
                </li>
                <li>
                  <div className="withouthover">
                    <NavLink
                      to={
                        navigateTo === "/"
                          ? `/video-gallery`
                          : `${navigateTo}/video-gallery`
                      }
                    >
                      <div className="headingofbox text-center">
                        <div className="iconphoto">
                          <span>
                            <img src="images/play-button.png" alt="" />
                          </span>
                        </div>
                        <div className="heading-center">Video </div>
                      </div>
                      <div className="box-overlay"></div>
                    </NavLink>
                  </div>
                  <div className="box-menu">
                    <img src="images/phototab.png" alt="" />
                  </div>
                </li>
                <li>
                  <div className="withouthover">
                    <NavLink
                      to={
                        navigateTo === "/"
                          ? `/event-gallery`
                          : `${navigateTo}/event-gallery`
                      }
                    >
                      <div className="headingofbox text-center">
                        <div className="iconphoto">
                          <span>
                            <img src="images/event.png" alt="" />
                          </span>
                        </div>
                        <div className="heading-center">Event </div>
                      </div>
                      <div className="box-overlay"></div>
                    </NavLink>
                  </div>
                  <div className="box-menu">
                    <img src="images/phototab.png" alt="" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MediaGallery;
