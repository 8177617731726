import React from "react";
import InnerBanner from "../InnerBanner";
import BredCrumb from "../BredCrumb";

import Loader from "../../../shared/Loader";
import { useGetInternalDataQuery } from "../../../../redux/slices/catApiSlice";

function VideoGallery({ navigateTo, benchId, categoryId }) {
  const { data: gallery, isLoading } = useGetInternalDataQuery(
    `get-gallery/${benchId}/${categoryId}`
  );

  if (isLoading) {
    return <Loader />;
  }

  console.log(gallery);

  return (
    <>
      <InnerBanner title={"Video Gallery"} />
      <BredCrumb title={"Video Gallery"} navigateTo={navigateTo} />
      <div className="gallery about-content my-4">
        <div className="container">
          <div className="row">
            <h6>{"Video Gallery"}</h6>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 gallerysec ">
              {gallery &&
              gallery.status &&
              gallery.data &&
              gallery.data.length > 0 ? (
                gallery.data.map((item, index) => (
                  <a className="masonry-item" key={index}>
                    <video controls>
                      <source src={item.file_path} type="video/mp4" />
                    </video>
                    <div className="inner details ">
                      <h3>{item.title}</h3>
                    </div>
                  </a>
                ))
              ) : (
                <h5>Upload a video first</h5>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoGallery;
