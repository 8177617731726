import React from "react";
import BredCrumb from "../Home/BredCrumb";
import InnerBanner from "../Home/InnerBanner";
import { NavLink } from "react-router-dom";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";

const ImportantLinks = ({ menuId, navigateTo, pageTitle }) => {
  const { data: headerMenus } = useGetInternalDataQuery(
    `get-menu/${menuId}/header`
  );
  const { data: footerMenus } = useGetInternalDataQuery(
    `get-menu/${menuId}/footer`
  );
  const { data: footerNavLinks } = useGetInternalDataQuery(
    `get-footerslider/${menuId}`
  );

  const newSlug = (name) => {
    return name.replaceAll(" ", "-").toLowerCase();
  };

  const getSearchData = (data) =>
    data?.data?.map((item) => ({
      name: item.name || item.title,
      slug: `${navigateTo}/${newSlug(item.name || item.title)}`,
      submenu: item.submenu
        ? item.submenu.map((subitem) => ({
            name: subitem.name || subitem.title,
            slug: `${navigateTo}/${newSlug(subitem.name || subitem.title)}`,
          }))
        : [],
    }));

  console.log(getSearchData(headerMenus));

  return (
    <>
      <div className="about">
        <InnerBanner title={pageTitle} />
        <BredCrumb title={pageTitle} navigateTo={navigateTo} />

        <div className="container pt-4 pb-4">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="tender-heading-sec mb-3">
                <span className="tender-heading">{pageTitle}</span>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4">
              <ul className="navbar-nav mr-auto menu">
                {getSearchData(headerMenus)?.map((item) => (
                  <li className="nav-item">
                    <NavLink
                      to={item.submenu.length > 0 ? "#" : `${item.slug}`}
                    >
                      {item.name}
                    </NavLink>
                    {item.submenu &&
                      item.submenu.map((subItem) => (
                        <li className="nav-item">
                          <NavLink to={`${subItem.slug}`}>
                            {subItem.name}
                          </NavLink>
                        </li>
                      ))}
                  </li>
                ))}
                {getSearchData(footerMenus)?.map((item) => (
                  <li className="nav-item">
                    <NavLink to={item.slug}>{item.name}</NavLink>
                  </li>
                ))}
                {getSearchData(footerNavLinks)?.map((item) => (
                  <li className="nav-item">
                    <NavLink to={item.slug}>{item.name}</NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportantLinks;
