import React from "react";
import { Routes, Route } from "react-router-dom";
import { CatBenches } from "../../Benches/Benches";
import HomeDL from "./Container/HomeDL";

// external api links
import CaseStatusParent from "../../frontend/containers/CaseManagementServices/CaseDetail/CaseStatusParent";
import CauseListParent from "../../frontend/containers/CaseManagementServices/CauseList/CauseListParent";
import DailyOrderParent from "../../frontend/containers/CaseManagementServices/DailyOrder/DailyOrderParent";
import OralAndFinalOrderParent from "../../frontend/containers/CaseManagementServices/OralAndFinalOrder/OralAndFinalOrderParent";
import CaseProceedingReportParent from "../../frontend/containers/CaseManagementServices/CaseProceedingReport/CaseProceedingReportParent";

// main menu pages
import Introduction from "../../Microsite_Frontend/Ahmedabad/Containers/MicroSiteMenuPages/Introduction";
import PresentMembers from "../Ahmedabad/Containers/MicroSiteMenuPages/PresentMembers";
import Officers from "../Ahmedabad/Containers/MicroSiteMenuPages/Officers";
import OrganisationalStructure from "../Ahmedabad/Containers/MicroSiteMenuPages/OrganisationalStructure";
import MenuPageWithTableContent from "../../frontend/containers/FooterSliderPages/MenuPageWithTableContent";

// other home page links
import MediaGallery from "../../frontend/containers/Home/PhotoVideoEvent/MediaGallery";
import PhotoGallery from "../../frontend/containers/Home/PhotoVideoEvent/PhotoGallery";
import VideoGallery from "../../frontend/containers/Home/PhotoVideoEvent/VideoGallery";
import EventGallery from "../../frontend/containers/Home/PhotoVideoEvent/EventGallery";
import CircularMoreDetails from "../../frontend/containers/Home/CircularMoreDetails";
import ListingChairmanReadMore from "../../frontend/containers/Home/ListingChairmanReadMore";
import MemberDetailPage from "../Ahmedabad/Containers/MicroSiteMenuPages/MemberDetailPage";
import ReadMoreChairman from "../../frontend/containers/Home/ReadMoreChairman";

// Footer Top Menu Links
import Vacancies from "../../frontend/containers/FooterSliderPages/Vacancies";
import ImportantLinks from "../../frontend/containers/FooterSliderPages/ImportantLinks";
import Forms from "../../frontend/containers/FooterSliderPages/Forms";
import EmployCorner from "../../frontend/containers/FooterSliderPages/EmployCorner";
import Calendar from "../../frontend/containers/FooterSliderPages/Calendar";
import TourProgramme from "../../frontend/containers/FooterSliderPages/TourProgramme";

// footer menu links
import FooterMenuContentPage from "../../frontend/containers/FooterPages/FooterMenuContentPage";
import GalleryDetail from "../../frontend/containers/Home/PhotoVideoEvent/GalleryDetail";

const DelhiRouting = () => {
  return (
    <Routes>
      <>
        <Route exact path="/" index element={<HomeDL />}></Route>
        {/* about us */}
        <>
          <Route
            path="/introduction"
            element={
              <Introduction
                pageId={162}
                navigateTo={CatBenches.delhi.navigateTo}
              />
            }
          ></Route>
          <Route
            exact
            path="act"
            element={
              <MenuPageWithTableContent
                benchId={20}
                navigateTo={CatBenches.delhi.navigateTo}
                endPoint={"act"}
              />
            }
          ></Route>
          <Route
            exact
            path="rule"
            element={
              <MenuPageWithTableContent
                benchId={20}
                navigateTo={CatBenches.delhi.navigateTo}
                endPoint={"rule"}
              />
            }
          ></Route>
          <Route
            exact
            path="jurisdiction"
            element={
              <MenuPageWithTableContent
                benchId={20}
                navigateTo={CatBenches.delhi.navigateTo}
                endPoint={"jurisdiction"}
              />
            }
          ></Route>
          <Route
            exact
            path="benches"
            element={
              <MenuPageWithTableContent
                benchId={20}
                navigateTo={CatBenches.delhi.navigateTo}
                endPoint={"benches"}
              />
            }
          ></Route>
          <Route
            exact
            path="organisational-structure"
            element={
              <FooterMenuContentPage
                pageId={689}
                navigateTo={CatBenches.delhi.navigateTo}
                endPoint={"Organisational Structure"}
              />
            }
          ></Route>
        </>
        {/* present Members */}
        <Route
          path="present-member"
          element={
            <PresentMembers
              memberId={20}
              navigateTo={CatBenches.delhi.navigateTo}
            />
          }
        ></Route>

        {/* officers */}
        <>
          <Route
            path="/officers"
            element={
              <Officers pageId={169} navigateTo={CatBenches.delhi.navigateTo} />
            }
          />
          <Route
            exact
            path="former-principal-registrar"
            element={
              <MenuPageWithTableContent
                benchId={20}
                navigateTo={CatBenches.delhi.navigateTo}
                endPoint={"former-principal-registrar"}
              />
            }
          ></Route>
        </>
        {/* standing counsel */}
        <Route
          exact
          path="standing-counsel"
          element={
            <MenuPageWithTableContent
              benchId={20}
              navigateTo={CatBenches.delhi.navigateTo}
              endPoint={"standing-counsel"}
            />
          }
        ></Route>

        {/* rti */}
        <>
          <Route
            exact
            path="rti"
            element={
              <MenuPageWithTableContent
                benchId={20}
                navigateTo={CatBenches.delhi.navigateTo}
                endPoint={"rti"}
              />
            }
          ></Route>
          <Route
            exact
            path="boards/committees"
            element={
              <MenuPageWithTableContent
                benchId={20}
                navigateTo={CatBenches.delhi.navigateTo}
                endPoint={"boards-committees"}
              />
            }
          ></Route>
          <Route
            exact
            path="pio"
            element={
              <MenuPageWithTableContent
                benchId={20}
                navigateTo={CatBenches.delhi.navigateTo}
                endPoint={"pio"}
              />
            }
          ></Route>
          <Route
            path="disciplinary-action"
            element={
              <MenuPageWithTableContent
                benchId={20}
                navigateTo={CatBenches.delhi.navigateTo}
                endPoint={"disciplinary-action"}
              />
            }
          ></Route>
          <Route
            exact
            path="rti-training"
            element={
              <MenuPageWithTableContent
                benchId={20}
                navigateTo={CatBenches.delhi.navigateTo}
                endPoint={"rti-training"}
              />
            }
          ></Route>
          <Route
            exact
            path="budgetary-allocation"
            element={
              <MenuPageWithTableContent
                benchId={20}
                navigateTo={CatBenches.delhi.navigateTo}
                endPoint={"budgetary-allocation"}
              />
            }
          ></Route>
          <Route
            exact
            path="policy/decisions"
            element={
              <MenuPageWithTableContent
                benchId={20}
                navigateTo={CatBenches.delhi.navigateTo}
                endPoint={"policy/decisions"}
              />
            }
          ></Route>
          <Route
            exact
            path="third-party-audit"
            element={
              <MenuPageWithTableContent
                benchId={20}
                navigateTo={CatBenches.delhi.navigateTo}
                endPoint={"third-party-audit"}
              />
            }
          ></Route>
        </>
        {/* case management services */}
        <>
          <Route
            exact
            path="/case-status"
            element={<CaseStatusParent benchDetails={CatBenches.delhi} />}
          ></Route>
          <Route
            exact
            path="/cause-list"
            element={<CauseListParent benchDetails={CatBenches.delhi} />}
          ></Route>
          <Route
            exact
            path="daily-order"
            element={<DailyOrderParent benchDetails={CatBenches.delhi} />}
          ></Route>
          <Route
            exact
            path="oral-and-final-order"
            element={
              <OralAndFinalOrderParent benchDetails={CatBenches.delhi} />
            }
          ></Route>
          <Route
            exact
            path="case-proceeding-report"
            element={
              <CaseProceedingReportParent benchDetails={CatBenches.delhi} />
            }
          ></Route>
        </>
      </>
      {/* // --------------------- Other home Links------------------------------------------ */}
      <Route
        exact
        path="read-more-chairman"
        element={
          <ReadMoreChairman
            memberId={1}
            navigateTo={CatBenches.delhi.navigateTo}
          />
        }
      ></Route>
      <Route
        exact
        path="present-member/read-more-chairman"
        element={<MemberDetailPage />}
      ></Route>
      <Route
        exact
        path="photo-media-gallery"
        element={<MediaGallery navigateTo={CatBenches.delhi.navigateTo} />}
      />
      <Route
        exact
        path="photo-gallery"
        element={
          <PhotoGallery
            navigateTo={CatBenches.delhi.navigateTo}
            benchId={20}
            categoryId={2}
          />
        }
      />
      <Route
        exact
        path="video-gallery"
        element={
          <VideoGallery
            navigateTo={CatBenches.delhi.navigateTo}
            benchId={20}
            categoryId={3}
          />
        }
      />
      <Route
        exact
        path="event-gallery"
        element={
          <EventGallery
            navigateTo={CatBenches.delhi.navigateTo}
            benchId={20}
            categoryId={4}
          />
        }
      />
      <Route
        exact
        path="event-gallery/gallery-detail/:id"
        element={<GalleryDetail />}
      />
      <Route
        exact
        path="photo-gallery/gallery-detail/:id"
        element={<GalleryDetail />}
      />
      {/* // ---------------------Nav Links BLUE menu pages------------------------------------------ */}
      <Route
        exact
        path="notices/circulars"
        element={
          <CircularMoreDetails
            circularId={20}
            navigateTo={CatBenches.delhi.navigateTo}
          />
        }
      ></Route>
      <Route
        exact
        path="important-links"
        element={
          <ImportantLinks
            menuId={20}
            navigateTo={CatBenches.delhi.navigateTo}
            pageTitle={"Important Links"}
          />
        }
      ></Route>
      <Route
        exact
        path="forms"
        element={
          <Forms benchId={20} navigateTo={CatBenches.delhi.navigateTo} />
        }
      ></Route>
      <Route
        exact
        path="vacancies"
        element={
          <Vacancies benchId={20} navigateTo={CatBenches.delhi.navigateTo} />
        }
      ></Route>
      <Route
        exact
        path="tour-programme"
        element={
          <MenuPageWithTableContent
            benchId={20}
            navigateTo={CatBenches.delhi.navigateTo}
            endPoint={"tour-programme"}
          />
        }
      ></Route>
      <Route
        exact
        path="employee-corner"
        element={
          <EmployCorner benchId={20} navigateTo={CatBenches.delhi.navigateTo} />
        }
      ></Route>
      <Route
        exact
        path="calendar"
        element={
          <Calendar benchId={20} navigateTo={CatBenches.delhi.navigateTo} />
        }
      ></Route>
      {/* // ---------------------footer menu pages------------------------------------------ */}
      <Route
        exact
        path="terms-&-condition"
        element={
          <FooterMenuContentPage
            pageId={278}
            navigateTo={CatBenches.delhi.navigateTo}
            endPoint={"terms-&-condition"}
          />
        }
      ></Route>{" "}
      <Route
        exact
        path="privacy-policy"
        element={
          <FooterMenuContentPage
            pageId={279}
            navigateTo={CatBenches.delhi.navigateTo}
            endPoint={"privacy-policy"}
          />
        }
      ></Route>{" "}
      <Route
        exact
        path="copyright-policy"
        element={
          <FooterMenuContentPage
            pageId={280}
            navigateTo={CatBenches.delhi.navigateTo}
            endPoint={"copyright-policy"}
          />
        }
      ></Route>{" "}
      <Route
        exact
        path="hyperlinking-policy"
        element={
          <FooterMenuContentPage
            pageId={281}
            navigateTo={CatBenches.delhi.navigateTo}
            endPoint={"hyperlinking-policy"}
          />
        }
      ></Route>{" "}
      <Route
        exact
        path="disclaimer"
        element={
          <FooterMenuContentPage
            pageId={282}
            navigateTo={CatBenches.delhi.navigateTo}
            endPoint={"disclaimer"}
          />
        }
      ></Route>{" "}
      <Route
        exact
        path="accessibility-statement"
        element={
          <FooterMenuContentPage
            pageId={283}
            navigateTo={CatBenches.delhi.navigateTo}
            endPoint={"accessibility-statement"}
          />
        }
      ></Route>{" "}
      <Route
        exact
        path="help"
        element={
          <FooterMenuContentPage
            pageId={284}
            navigateTo={CatBenches.delhi.navigateTo}
            endPoint={"help"}
          />
        }
      ></Route>
      <Route
        exact
        path="sitemap"
        element={
          <ImportantLinks
            menuId={20}
            navigateTo={CatBenches.delhi.navigateTo}
            pageTitle={"Sitemap"}
          />
        }
      ></Route>
      <Route
        exact
        path="contact-us"
        element={
          <FooterMenuContentPage
            pageId={221}
            navigateTo={CatBenches.delhi.navigateTo}
            endPoint={"contact-us"}
          />
        }
      ></Route>
      <Route
        exact
        path="suo-motu"
        element={
          <MenuPageWithTableContent
            benchId={20}
            navigateTo={CatBenches.delhi.navigateTo}
            endPoint={"suo-moto"}
          />
        }
      ></Route>
      <Route
        exact
        path="citizen-charter"
        element={
          <MenuPageWithTableContent
            benchId={20}
            navigateTo={CatBenches.delhi.navigateTo}
            endPoint={"citizen-charter"}
          />
        }
      ></Route>
      <Route
        exact
        path={`listing-chairman-read-more`}
        element={
          <ListingChairmanReadMore navigateTo={CatBenches.delhi.navigateTo} />
        }
      ></Route>
    </Routes>
  );
};

export default DelhiRouting;
