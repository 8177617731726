import React, { useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { lazy, Suspense } from "react";
import Layout from "./frontend/shared/Layout";
import { useSelector } from "react-redux";
import ScrollToTop from "react-scroll-to-top";
import DelhiLayout from "./Microsite_Frontend/Delhi/DelhiLayout";
import Loader from "./frontend/shared/Loader";
import TopHeader from "./frontend/shared/header/TopHeader";
import PopUpExternal from "./frontend/shared/PopUpExternal";
const AhmadabadLayout = lazy(() =>
  import("./Microsite_Frontend/Ahmedabad/AhmadabadLayout")
);
const AllahabadLayout = lazy(() =>
  import("./Microsite_Frontend/Allahbad/AllahabadLayout")
);
const BangaloreLayout = lazy(() =>
  import("./Microsite_Frontend/Bangalore/BangaloreLayout")
);
const ChandigarhLayout = lazy(() =>
  import("./Microsite_Frontend/Chandigarh/ChandigarhLayout")
);
const ChennaiLayout = lazy(() =>
  import("./Microsite_Frontend/Chennai/ChennaiLayout")
);
const CuttackLayout = lazy(() =>
  import("./Microsite_Frontend/Cuttack/CuttackLayout")
);
const ErnakulamLayout = lazy(() =>
  import("./Microsite_Frontend/Ernakulam/ErnakulamLayout")
);
const GuwahatiLayout = lazy(() =>
  import("./Microsite_Frontend/Guwahati/GuwahatiLayout")
);
const HyderabadLayout = lazy(() =>
  import("./Microsite_Frontend/Hyderabad/HyderabadLayout")
);
const JabalpurLayout = lazy(() =>
  import("./Microsite_Frontend/Jabalpur/JabalpurLayout")
);
const JaipurLayout = lazy(() =>
  import("./Microsite_Frontend/Jaipur/JaipurLayout")
);
const JammuLayout = lazy(() =>
  import("./Microsite_Frontend/Jammu/JammuLayout")
);
const JodhpurLayout = lazy(() =>
  import("./Microsite_Frontend/Jodhpur/JodhpurLayout")
);
const KolkataLayout = lazy(() =>
  import("./Microsite_Frontend/Kolkata/KolkataLayout")
);
const LucknowLayout = lazy(() =>
  import("./Microsite_Frontend/Lucknow/LucknowLayout")
);
const MumbaiLayout = lazy(() =>
  import("./Microsite_Frontend/Mumbai/MumbaiLayout")
);
const PatnaLayout = lazy(() =>
  import("./Microsite_Frontend/Patna/PatnaLayout")
);
const SrinagarLayout = lazy(() =>
  import("./Microsite_Frontend/Srinagar/SrinagarLayout")
);
function App() {
  const { fontSize } = useSelector((state) => state.catSliceExternal);
  const [isOpenModal, setIsOpenModal] = useState(true);
  return (
    <div className="App" style={{ fontSize: `${fontSize}px` }}>
      <TopHeader />
      <Routes>
        <Route path="/*" element={<Layout />}></Route>
        <Route
          path="/ahmedabad/*"
          element={
            <Suspense fallback={<Loader />}>
              <AhmadabadLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/delhi/*"
          element={
            <Suspense fallback={<Loader />}>
              <DelhiLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/allahabad/*"
          element={
            <Suspense fallback={<Loader />}>
              <AllahabadLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/bangalore/*"
          element={
            <Suspense fallback={<Loader />}>
              <BangaloreLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/chandigarh/*"
          element={
            <Suspense fallback={<Loader />}>
              <ChandigarhLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/chennai/*"
          element={
            <Suspense fallback={<Loader />}>
              <ChennaiLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/cuttack/*"
          element={
            <Suspense fallback={<Loader />}>
              <CuttackLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/ernakulam/*"
          element={
            <Suspense fallback={<Loader />}>
              <ErnakulamLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/guwahati/*"
          element={
            <Suspense fallback={<Loader />}>
              <GuwahatiLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/hyderabad/*"
          element={
            <Suspense fallback={<Loader />}>
              <HyderabadLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/jabalpur/*"
          element={
            <Suspense fallback={<Loader />}>
              <JabalpurLayout />
            </Suspense>
          }
        ></Route>

        <Route
          path="/jaipur/*"
          element={
            <Suspense fallback={<Loader />}>
              <JaipurLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/jammu/*"
          element={
            <Suspense fallback={<Loader />}>
              <JammuLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/jodhpur/*"
          element={
            <Suspense fallback={<Loader />}>
              <JodhpurLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/kolkata/*"
          element={
            <Suspense fallback={<Loader />}>
              <KolkataLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/lucknow/*"
          element={
            <Suspense fallback={<Loader />}>
              <LucknowLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/mumbai/*"
          element={
            <Suspense fallback={<Loader />}>
              <MumbaiLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/patna/*"
          element={
            <Suspense fallback={<Loader />}>
              <PatnaLayout />
            </Suspense>
          }
        ></Route>
        <Route
          path="/srinagar/*"
          element={
            <Suspense fallback={<Loader />}>
              <SrinagarLayout />
            </Suspense>
          }
        ></Route>
      </Routes>
      <ScrollToTop smooth color="#ffffff" style={{ background: "#ef8321" }} />

      {/* modal for bench event */}
      {/* <Modal
        className="modal-select-bench"
        show={isOpenModal}
        onHide={() => setIsOpenModal(false)}
      >
        <Modal.Header>
          Message from Mumbai bench
          <Button
            type="button"
            className="close"
            onClick={() => setIsOpenModal(false)}
          >
            &times;
          </Button>
        </Modal.Header>
        <Modal.Body className="mumbaibenchpop">
          <h4>THE CHAIRMAN, CENTRAL ADMINISTRATIVE TRIBUNAL</h4>
          <p>and</p>
          <p>His Companion Members of the Mumbai Bench of the Tribunal
          request your gracious presence for the</p>
 <h4>INAUGURATION CEREMONY OF NEW OFFICE PREMISES OF MUMBAI BENCH OF CAT</h4>
<p>on Friday, the 8th day of December, 2023</p>
<p>At the auspicious hands of</p>
<h4 className="color-w">The Hon'ble Dr. Justice D. Y. Chandrachud</h4>
<p>Chief Justice of India</p>

<p className="mt-4">In the august presence of</p>

<h4 className="color-w">The Hon'ble Shri Justice Devendra Kumar Upadhyaya</h4>
<p>Chief Justice, High Court of Judicature at Bombay</p>
<p>&</p>
<h4>Shri D. G. Vyas</h4>
<p>Additional Solicitor General of India</p>
<h4>Dr. Birendra Saraf</h4>
<p>Advocate General, State of Maharashtra</p>
<p className="small_text">live-streamed on YouTube channel of the Mumbai Bench at following link:</p>
<a href="https://www.youtube.com/@CentralAdministrativeTri-vx5uz" target="_blank">https://www.youtube.com/@CentralAdministrativeTri-vx5uz</a>

        </Modal.Body>
      </Modal> */}
      {/* modal for bench event */}
      <PopUpExternal />
    </div>
  );
}

export default App;
