import React from "react";

const UnderDevelopment = () => {
  return (
    <>
      <div className="common-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 pt-5 text-center">
              <h2>Under Development Page</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnderDevelopment;
