import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import {
  setExternalLink,
  setShowExternalPopup,
} from "../../../redux/slice/sliceInternal/externalPopupSlice";

const FooterNav = ({ menuId, benchId }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  // const printfnc = (e) => {
  //   e.preventDefault();
  //   window.print();
  // };
  const socialLinks = useGetInternalDataQuery(`get-social_link/${benchId}`);
  const footerMenus = useGetInternalDataQuery(`get-menu/${menuId}/footer`);

  if (socialLinks.isLoading || footerMenus.isLoading) {
    return <Skeleton count={3} height={20} />;
  }

  // fetching social links from store

  // function to remove
  const newSlug = (name) => {
    return name.replaceAll(" ", "-").toLowerCase();
  };

  console.log(footerMenus.data);
  return (
    <>
      <div className="footer-top-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-10 col-lg-10 copyright-sec">
              <div className="footer-nav-sec">
                <ul>
                  {footerMenus.data &&
                  footerMenus.data.status &&
                  footerMenus.data.data.length > 0 ? (
                    footerMenus.data.data.map((item, index) => (
                      <li key={index}>
                        <Link to={newSlug(item.name)}>{item.name}</Link>
                      </li>
                    ))
                  ) : (
                    <div style={{ color: "#fff" }}>No data found</div>
                  )}
                  {location.pathname === "/" && (
                    <li>
                      <Link to={"web-information-manager"}>
                        Web Information Manager
                      </Link>
                    </li>
                  )}

                  {/* <li>
                    <Link to={"feedback"}>Feedback</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 visitor-sec">
              <div className="text-right social-media">
                {socialLinks.data &&
                socialLinks.data.status &&
                socialLinks.data.data.length > 0 ? (
                  socialLinks.data.data.map((item, index) => (
                    <span
                      // href="#"
                      aria-label={item.title}
                      key={index}
                      type="button"
                      onClick={() => {
                        dispatch(setShowExternalPopup(true));
                        dispatch(setExternalLink(item.title));
                      }}
                      rel="noopener"
                    >
                      <img src={item.upload_image_path} alt={item.title} />
                    </span>
                  ))
                ) : (
                  <div style={{ color: "#fff" }}>No data found</div>
                )}
                {/* <a className="print" onClick={printfnc} href="#">
                  <i className="fas fa-print"></i>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterNav;
