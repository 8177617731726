import React, { useState } from "react";
import InnerBanner from "../../Home/InnerBanner";
import BredCrumb from "../../Home/BredCrumb";
import { CatBenches } from "../../../../Benches/Benches";
import CaseNoDaily from "./Tabs/CaseNoDaily";
import DiaryNoDaily from "./Tabs/DiaryNoDaily";
import DateWiseOrderDaily from "./Tabs/DateWiseOrderDaily";
import PartyNameDaily from "./Tabs/PartyNameDaily";

const DailyOrderParent = ({ benchDetails }) => {
  const [relatedLink, setRelatedLink] = useState("Case-No");
  const { id, name, navigateTo } = benchDetails;
  return (
    <>
      <InnerBanner title="Daily Order" />
      <BredCrumb title="Daily Order" navigateTo={navigateTo} />
      <div className="common-bg mb-3">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="case-details  wow fadeInUp">
                <ul className="nav inner toptab-one">
                  <li>
                    <a
                      className={
                        relatedLink === "Case-No"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="pill"
                      onClick={() => setRelatedLink("Case-No")}
                    >
                      Case No
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        relatedLink === "Diary-No"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="pill"
                      onClick={() => setRelatedLink("Diary-No")}
                    >
                      Diary No
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        relatedLink === "datewise-order"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="pill"
                      onClick={() => setRelatedLink("datewise-order")}
                    >
                      Datewise Order
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        relatedLink === "Party-Name"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="pill"
                      onClick={() => setRelatedLink("Party-Name")}
                    >
                      Party Name
                    </a>
                  </li>
                </ul>
                <div className="tab-content-wraper">
                  <div className="tab-pane container active" id="case_details">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="tab-content ">
                          {relatedLink === "Case-No" ? (
                            <CaseNoDaily schemaId={id} bench={name} />
                          ) : relatedLink === "Diary-No" ? (
                            <DiaryNoDaily schemaId={id} bench={name} />
                          ) : relatedLink === "datewise-order" ? (
                            <DateWiseOrderDaily schemaId={id} bench={name} />
                          ) : (
                            <PartyNameDaily schemaId={id} bench={name} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DailyOrderParent;
