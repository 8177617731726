import React, { useRef, useState } from "react";

import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import Loader from "../../shared/Loader";

const Circulars = ({ circularId }) => {
  const slider = useRef();
  const [playPause, setPlayPause] = useState(false);

  const { data: circulars, isLoading } = useGetInternalDataQuery(
    `get-notice/${circularId}`
  );

  if (isLoading) {
    return <Loader />;
  }
  const next = () => {
    slider.current.slickNext();
  };
  const prev = () => {
    slider.current.slickPrev();
  };

  const settings = {
    //dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    vertical: true,
    adaptiveHeight: true,
    cssEase: "linear",
  };

  const HandlePlayPause = () => {
    setPlayPause(!playPause);
    if (playPause) {
      slider.current.slickPlay();
    }
    if (!playPause) {
      slider.current.slickPause();
    }
  };

  return (
    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
      <div className="circulars_sec  wow fadeInLeft">
        <div className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="circular-left-right">
              <h2 className="underline_title">Circulars</h2>
            </div>
            <div className="navsec circ">
              <span className="left" onClick={prev}>
                <i className="fa fa-angle-up"></i>
              </span>
              <span className="right" onClick={HandlePlayPause}>
                {!playPause ? (
                  <i className="fas fa-pause"></i>
                ) : (
                  <i className="fas fa-play"></i>
                )}
              </span>
              <span className="right" onClick={next}>
                <i className="fa fa-angle-down"></i>
              </span>
            </div>
          </div>
        </div>
        <div className="circulars_tic">
          <div className="vticker">
            <Slider {...settings} ref={slider}>
              {circulars && circulars.status ? (
                circulars.data &&
                circulars.data.map((item, index) => (
                  <span key={index}>
                    <a href={item.aad_file} target="_blank">
                      <i className="fas fa-arrow-right"></i>
                      {item.title}
                    </a>
                  </span>
                ))
              ) : (
                <>No Data Found</>
              )}
            </Slider>
          </div>
        </div>
      </div>
      <div className="text-center">
        <Link to="notices/circulars" className="view_all">
          View All
        </Link>
      </div>
    </div>
  );
};

export default Circulars;
