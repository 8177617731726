import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";
import axios from "axios";

const initialState = {
  addVisitors: [],

  loading: false,
  error: "",
};

const decrypt = (data, key) => {
  const decrypted = CryptoJS.AES.decrypt(data, key);
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};

const getIP = async () => {
  try {
    const res = await axios.get("https://geolocation-db.com/json/");
    return res.data.IPv4;
  } catch (error) {
    console.log(error);
  }
};

export const fetchAddVisitors = createAsyncThunk(
  "footerBottomSlice/fetchAddVisitors",
  async (_, { rejectWithValue }) => {
    try {
      const ip = await getIP();
      const customHeaders = {
        "X-user": "zur1xjb4",
      };
      const response = await axios.get(
        `https://cgat.gov.in/CAT_application/public/index.php/api/v1/addVisitor/${ip}`,
        { headers: customHeaders }
      );
      return decrypt(response.data, "CatApplication");
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

export const footerBottomSlice = createSlice({
  name: "footerBottomSlice",
  initialState,
  reducers: {
    reset: (state) => {
      state.addVisitors = [];
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    //4. add visitors
    builder
      .addCase(fetchAddVisitors.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAddVisitors.fulfilled, (state, action) => {
        state.addVisitors = action.payload;
        state.loading = false;
      })
      .addCase(fetchAddVisitors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { reset } = footerBottomSlice.actions;
export default footerBottomSlice.reducer;
