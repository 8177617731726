import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fontSize: 16,
  searchedName: "",
  chairmanType: "0",
  languageType: "eng"
};

export const catSliceExternal = createSlice({
  name: "catSliceExternal",
  initialState,
  reducers: {
    fontSizeIncrease: (state) => {
      state.fontSize += 1;
    },
    fontSizeDecrease: (state) => {
      state.fontSize -= 1;
    },
    fontSizeOriginal: (state) => {
      state.fontSize = 16;
    },
    setSearchedName: (state, action) => {
      state.searchedName = action.payload;
    },
    setChairmanType: (state, action) => {
      state.chairmanType = action.payload;
    },
    setLanguageType: (state, action) => {
      state.languageType = action.payload;
    },
  },
});

export const {
  fontSizeIncrease,
  fontSizeDecrease,
  fontSizeOriginal,
  setSearchedName,
  searchedName,
  setChairmanType,
  setLanguageType,
} = catSliceExternal.actions;
export default catSliceExternal.reducer;
