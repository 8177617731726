import React, { useState } from "react";
import * as Yup from "yup";
import { Modal, Button, Table } from "react-bootstrap";
import { Formik } from "formik";
import Loader from "../../../../shared/Loader";
import { handleDate, payloadForm } from "../../../../../Utilities/FuncUtils";
import { useGetExternalDataMutation } from "../../../../../redux/slices/catApiSlice";

const CauseList = ({ schemaId, bench }) => {
  const [causeListFunc, { data: causeListData, isLoading, isError }] =
    useGetExternalDataMutation();
  const [
    causeListNoticeFunc,
    { data: causeListNoticeData, isLoading: loading1, isError: error1 },
  ] = useGetExternalDataMutation();
  // states for modals
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [displayDate, setDisplayDate] = useState("");

  //---------------- Formik ------------------------//

  // initial Values
  const initialValues = {
    date: "",
  };

  // validation
  const validationSchema = Yup.object({
    date: Yup.string().required("Required"),
  });

  // final submit formik form
  const handleSubmit = (values, { resetForm }) => {
    setDisplayDate(values.date);
    const payload = {
      catschemaId: schemaId,
      catCauseListDate: handleDate(values.date),
    };
    // dispatching actions for fetching diary wise data
    causeListFunc({
      url: "getcatCauselist",
      payload: payloadForm(payload),
    });
    const payloadNotice = {
      catschemaId: schemaId,
      Noticecauselistdate: handleDate(values.date),
    };
    // dispatching actions for fetching diary wise data
    causeListNoticeFunc({
      url: "getNotice",
      payload: payloadForm(payloadNotice),
    });

    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);
    resetForm();
  };

  return (
    <div className="tab-pane container active" id="case_number">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          errors,
          values,
          resetForm,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                {/* field 1 */}
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className=" col-form-label mandatory">
                    Listing Date: (DD/MM/YYYY)
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="date"
                    name="date"
                    onChange={handleChange}
                    value={values.date}
                    onBlur={handleBlur}
                  />
                  {touched.date && errors.date ? (
                    <div className="error">{errors.date}</div>
                  ) : null}
                </div>

                {/* submit field */}
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3 mt-3">
                  <div className="search_cancel_btn">
                    <button type="submit" className="search">
                      Search
                    </button>
                    <a type="button" className="cancel" onClick={resetForm}>
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </form>
            {isLoading && loading1 ? (
              <Loader />
            ) : (
              <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
                <Modal.Header>
                  <div>
                    <b> {bench} </b>
                    <br />
                    Cause List Report
                  </div>

                  <Button
                    type="button"
                    className="close"
                    onClick={() => setIsOpenModal(false)}
                  >
                    &times;
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  {!error1 || !isError ? (
                    <div className="model-mh">
                      {causeListData &&
                        causeListData[0] &&
                        causeListData[0].path && (
                          <div className="text-center pt-3 pb-3">
                            <>
                              <a
                                className="btn search mr-2"
                                target="_blank"
                                href={causeListData[0].path}
                              >
                                {`Print Cause List Report of ${handleDate(
                                  displayDate
                                )}`}
                              </a>
                            </>
                          </div>
                        )}
                      {causeListNoticeData &&
                        causeListNoticeData[0] &&
                        causeListNoticeData[0].paths && (
                          <div className="table-responsive model-mh">
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>Sr.No.</th>
                                  <th>Detail</th>
                                  <th>Open(PDF)</th>
                                </tr>
                              </thead>
                              {causeListNoticeData[0].message ? (
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>{causeListNoticeData[0].message}</td>
                                    <td>
                                      <a
                                        target="_blank"
                                        href={causeListNoticeData[0].paths}
                                      >
                                        <i className="fas fa-file-pdf"></i>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td colSpan="7"> No Record Found </td>
                                  </tr>
                                </tbody>
                              )}
                            </Table>
                          </div>
                        )}
                    </div>
                  ) : (
                    <h6>Cause List still not generated yet</h6>
                  )}
                </Modal.Body>
              </Modal>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default CauseList;
