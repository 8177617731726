import React, { useEffect, useState } from "react";
import InnerBanner from "../Home/InnerBanner";
import { Table } from "react-bootstrap";
import Loader from "../../shared/Loader";
import BredCrumb from "../Home/BredCrumb";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import { useDispatch } from "react-redux";
import {
  setExternalLink,
  setShowExternalPopup,
} from "../../../redux/slice/sliceInternal/externalPopupSlice";
import {
  capitalizeWords,
  filterDataByYear,
} from "../../../Utilities/FuncUtils";
import { useTitle } from "../../../Utilities/pageTitle";

const MenuPageWithTableContent = ({ benchId, endPoint, navigateTo }) => {
  useTitle(capitalizeWords(endPoint));
  const dispatch = useDispatch();
  const {
    data: pagesWithTableContent,
    isLoading,
    refetch,
  } = useGetInternalDataQuery(`get-modules/${benchId}/${endPoint}`);

  // console.log(pagesWithTableContent?.data);

  if (isLoading && !pagesWithTableContent) {
    return <Loader />;
  }

  const { archiveData, allData } = filterDataByYear(
    pagesWithTableContent?.data,
    2023
  );

  const renderTableRows = (data) => {
    return data?.map((item, index) => (
      <tr key={item.id}>
        <td headers="view-counter-table-column">{index + 1}</td>
        <td
          headers="view-cat-table-column"
          style={{ textTransform: "capitalize" }}
        >
          {navigateTo === "/" || navigateTo === "/delhi"
            ? "Principal Bench, New Delhi"
            : `${navigateTo.substr(1)}`}
        </td>
        <td headers="view-details-table-column">{item.subject}</td>
        <td headers="view-date-table-column">
          {item.date_of_issue.split("-").reverse().join("-")}
        </td>
        <td headers="view-file-hindi-table-column">
          {item.attachment && (
            <>
              <a target="_blank" href={`${item.attachment}`}>
                <i className="fas fa-file-pdf"></i>
              </a>
              <br />
              <small>
                {item.aad_file_size ? `(${item.aad_file_size})` : "(589.12 KB)"}
              </small>
              <br />
            </>
          )}
        </td>
        {/* <td headers="view-file-table-column">
          <a
            target="_blank"
            className="external-link"
            href={item.attachment}
            type="button"
            onClick={() => {
              dispatch(setShowExternalPopup(true));
              dispatch(setExternalLink(item.attachment));
            }}
          >
            <i className="fas fa-file-pdf">
              <span className="sr-only">no text</span>
            </i>
          </a>
          <br />
          <span style={{ fontSize: "12px" }}>
            ({item.aad_file_size === null ? "589.33 KB" : item.aad_file_size})
          </span>
          <br />
        </td> */}
      </tr>
    ));
  };

  return (
    <>
      <InnerBanner title={endPoint} />
      <BredCrumb title={endPoint} navigateTo={navigateTo} />
      <div className="chairman-detail">
        <div className="container">
          <div className="table-responsive model-mh">
            <Table striped bordered hover className="custom-table">
              <thead>
                <tr>
                  <th id="view-counter-table-column" scope="col">
                    Sr.No.
                  </th>
                  <th id="view-cat-table-column" scope="col">
                    CAT
                  </th>
                  <th id="view-details-table-column" scope="col">
                    Details
                  </th>
                  <th id="view-date-table-column" scope="col">
                    Date of Release
                  </th>
                  <th id="view-file-table-column" scope="col">
                    Open(PDF)
                  </th>
                </tr>
              </thead>
              <tbody>
                {allData?.length > 0 ? (
                  renderTableRows(allData)
                ) : (
                  <tr>
                    <td colSpan="7">No Data Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuPageWithTableContent;
