import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showExternalPopup: false,
  externalLink: "",
};

export const externalPopupSlice = createSlice({
  name: "externalPopupSlice",
  initialState,
  reducers: {
    setShowExternalPopup: (state, action) => {
      state.showExternalPopup = action.payload;
    },
    setExternalLink: (state, action) => {
      state.externalLink = action.payload;
    },
  },
});

export const { setShowExternalPopup, setExternalLink } =
  externalPopupSlice.actions;
export default externalPopupSlice.reducer;
