import React, { useEffect } from "react";
import Loader from "../../shared/Loader";
import BredCrumb from "./BredCrumb";
import InnerBanner from "./InnerBanner";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import { useParams } from "react-router-dom";

const ReadMoreChairman2 = ({ memberId, navigateTo }) => { 
  const params = useParams();
  console.log(params);
  const { data: hod, isLoading } = useGetInternalDataQuery(
    `get-hod/${memberId}`
  );

  console.log(hod);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <InnerBanner title={memberId == 1 ? "Principal Registrar" : "HOD"} />
      <BredCrumb
        title={memberId == 1 ? "Principal Registrar" : "HOD"}
        navigateTo={navigateTo}
      />
      <div className="chairman-detail">
        <div className="container">
          {hod.status && hod.data[1] ? (
            <div className="row">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 chairmen-detail-left">
                <div className="chairmen-detail-img-sec">
                  <img src={hod.data[1].image} alt="Chairman" />
                </div>
                <div className="chairman-sec">
                  <h5>{hod.data[1].name}</h5>
                  <h3>{hod.data[1].designation}</h3>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-9 col-lg-9 chairmen-detail-right">
                <h5>{hod.data[1].name}</h5>
                <h3>{hod.data[1].designation}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: hod.data[1].description,
                  }}
                />
              </div>
            </div>
          ) : (
            <>No Data Found</>
          )}
        </div>
      </div>
    </>
  );
};

export default ReadMoreChairman2;
