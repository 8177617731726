import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import {
  setExternalLink,
  setShowExternalPopup,
} from "../../../redux/slice/sliceInternal/externalPopupSlice";

const FooterSlider = () => {
  const [playPause, setPlayPause] = useState(false);
  const slider = useRef();

  const handlePopupLink = (link) => {
    dispatch(setShowExternalPopup(true));
    dispatch(setExternalLink(link));
  };
  const dispatch = useDispatch();
  const settings = {
    //dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const HandlePlayPause = () => {
    setPlayPause(!playPause);
    if (playPause) {
      slider.current.slickPlay();
    }
    if (!playPause) {
      slider.current.slickPause();
    }
  };

  return (
    <>
      <div className="gray-bg">
        <div className="container">
          <div className="row mt-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="text-right">
                <strong className="right" onClick={HandlePlayPause}>
                  {!playPause ? (
                    <i className="fas fa-pause"></i>
                  ) : (
                    <i className="fas fa-play"></i>
                  )}
                </strong>
              </div>
              <div id="gov_bottom_slider" className="Footer-slider">
                <Slider {...settings} ref={slider}>
                  <a
                    type="button"
                    onClick={() => handlePopupLink("https://www.india.gov.in/")}
                  >
                    <img src="images/fot-logo-1.png" alt="india gov" />
                  </a>

                  <a
                    type="button"
                    onClick={() =>
                      handlePopupLink("https://www.makeinindia.com/")
                    }
                  >
                    <img src="images/fot-logo-4.png" alt="make in india" />
                  </a>
                  <a
                    type="button"
                    onClick={() =>
                      handlePopupLink("https://www.digitalindia.gov.in/")
                    }
                  >
                    <img src="images/fot-logo-6.png" alt="digital india" />
                  </a>
                  <a
                    type="button"
                    onClick={() =>
                      handlePopupLink("https://goidirectory.nic.in")
                    }
                  >
                    <img src="images/fot-logo-2.png" alt="go india" />
                  </a>
                  <a
                    type="button"
                    onClick={() => handlePopupLink("https://data.gov.in/")}
                  >
                    <img src="images/fot-logo-3.png" alt="data gov" />
                  </a>
                  <a
                    type="button"
                    onClick={() => handlePopupLink("https://mygov.in/")}
                  >
                    <img src="images/fot-logo-5.png" alt="my gov" />
                  </a>
                  <a
                    type="button"
                    onClick={() =>
                      handlePopupLink("https://www.myscheme.gov.in/")
                    }
                  >
                    <img src="images/fot-logo-7.png" alt="my scheme" />
                  </a>
                  <a
                    type="button"
                    onClick={() =>
                      handlePopupLink("https://meripehchaan.gov.in/")
                    }
                  >
                    <img src="images/fot-logo-8.png" alt="sso" />
                  </a>
                  <a
                    type="button"
                    onClick={() => handlePopupLink("https://uidai.gov.in/en/")}
                  >
                    <img src="images/fot-logo-9.png" alt="aadhar" />
                  </a>
                  <a
                    type="button"
                    onClick={() =>
                      handlePopupLink("https://www.digilocker.gov.in/")
                    }
                  >
                    <img src="images/fot-logo-10.png" alt="digilocker" />
                  </a>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterSlider;
