import React, { useState } from "react";
import { axiosBaseURLExternal } from "../../../../../apis/catApi";
import { useGetExternalDataMutation } from "../../../../../redux/slices/catApiSlice";
import { payloadForm } from "../../../../../Utilities/FuncUtils";
import Loader from "../../../../shared/Loader";

const PartyNameMoreDetails = ({ item, schemaId }) => {
  const [diaryFunc, { isLoading }] = useGetExternalDataMutation();
  const fetchMoreDetails = (url) => {
    const [diaryNo, diaryYear] = item && item.split("/");
    const data = {
      catschemaId: schemaId,
      dairyNo: diaryNo,
      dairyYear: diaryYear,
    };
    diaryFunc({
      url,
      payload: payloadForm(data),
    })
      .then((res) => {
        const [a] = Object.values(res.data);
        window.open(a, "_blank");
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(message);
      });
  };
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <a
        className="more-details"
        style={{ cursor: "pointer" }}
        onClick={() => {
          fetchMoreDetails("getAdditionalDetail");
        }}
      >
        More&nbsp;Detail
      </a>
    </>
  );
};

export default PartyNameMoreDetails;
