import React from "react";
import HomeBanner from "./HomeBanner";
import OtherLinks from "./OtherLinks";
import BenchSlider from "./BenchSlider";
import WhatsNew from "./WhatsNew";
import Circulars from "./Circulars";
import PhotoVideoEvent from "./PhotoVideoEvent/PhotoVideoEvent";
import ChartHome from "./ChartHome";
import ChairpersonHome from "./ChairpersonHome";
import { useTitle } from "../../../Utilities/pageTitle";

const Home = () => {
  useTitle("Home");
  return (
    <>
      <HomeBanner bannerId={1} />
      <WhatsNew benchId={20} />
      <div className="benches-slide">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
              <BenchSlider />
            </div>
            {/* <ChairPerson memberId={1} /> */}
            <ChairpersonHome memberId={"1"} />
            {/* <ChairPersonLanding memberId={1} /> */}
          </div>
        </div>
      </div>

      <div className="circulars_photo_video_event_sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <div className="row">
                <Circulars circularId={20} />
                <PhotoVideoEvent galleryId={20} />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-5 ">
              {/* <Chart schemaId={CatBenches.delhi.id} /> */}
              {/* chart-sec-right */}
              <ChartHome />
            </div>
          </div>
        </div>
      </div>
      <OtherLinks navLinkId={1} />
    </>
  );
};

export default Home;
