import React from "react";
import { Table } from "react-bootstrap";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import { currentYear, filterDataByYear } from "../../../Utilities/FuncUtils";
import Loader from "../../shared/Loader";

const VacanciesReport = ({ benchId, isArchive }) => {
  const { data: vacanciesSSC, isLoading } = useGetInternalDataQuery(
    `get-modules/${benchId}/vacancies-reported-to-ssc`
  );
  // const { data: vacanciesSSC, isLoading } = useGetInternalDataQuery(
  //   `get-modules/${benchId}/vacancy`
  // );

  const { archiveData, allData } = filterDataByYear(
    vacanciesSSC?.data,
    currentYear
  );

  const renderTableRows = (data) => {
    return data?.map((item, index) => (
      <tr key={item.id}>
        <td headers="view-counter-table-column">{index + 1}</td>
        <td headers="view-details-table-column">{item.subject}</td>
        <td headers="view-date-table-column">
          {item.date_of_issue.split("-").reverse().join("-")}
        </td>
        <td headers="view-file-hindi-table-column">
          {item.attachment && (
            <>
              <a target="_blank" href={`${item.attachment}`}>
                <i className="fas fa-file-pdf"></i>
              </a>
              <br />
              <small>
                {item.aad_file_size ? `(${item.aad_file_size})` : "(589.12 KB)"}
              </small>
              <br />
            </>
          )}
        </td>
      </tr>
    ));
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <Table striped bordered hover className="custom-table">
        <thead>
          <tr>
            <th id="view-counter-table-column" scope="col">
              Sr.No.
            </th>
            <th id="view-details-table-column" scope="col">
              Details
            </th>
            <th id="view-date-table-column" scope="col">
              Date of Release
            </th>
            <th id="view-file-table-column" scope="col">
              Open(PDF)
            </th>
          </tr>
        </thead>
        <tbody>
          {!isArchive ? (
            archiveData?.length > 0 ? (
              renderTableRows(archiveData)
            ) : (
              <tr>
                <td colSpan="7">No Archive Data</td>
              </tr>
            )
          ) : allData?.length > 0 ? (
            renderTableRows(allData)
          ) : (
            <tr>
              <td colSpan="7">Content Awaited From CAT</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default VacanciesReport;
