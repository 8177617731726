import axios from "axios";

export const axiosBaseURLExternal = axios.create({
  baseURL: `${process.env.REACT_APP_EXTERNAL_URL}/catapi`,
  headers: { "Content-Type": "multipart/form-data" },
});

export const axiosBaseURLInternal = axios.create({
  baseURL: `${process.env.REACT_APP_INTERNAL_URL}/CAT_application/public/index.php/api/v1`,
  headers: { "X-user": "zur1xjb4", "Content-Type": "application/json" },
});
