import { useState, useRef } from "react";
import InnerBanner from "./Home/InnerBanner";
import BredCrumb from "./Home/BredCrumb";
import { Formik } from "formik";
// import Captcha from "react-numeric-captcha";
import * as Yup from "yup";

const FeedbackPage = ({ endPoint, navigateTo }) => {
  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    message: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required(),
    email: Yup.string().required(),
    mobile: Yup.string().required(),
    message: Yup.string().required(),
  });

  const handleSubmit = (values, { resetForm }) => {
    console.log(values);
    window.alert("Your Feedback/suggestion is submitted successfully");
    resetForm();
  };
  //   const [captchaStatus, setCaptchaStatus] = useState(false);
  //   const capt = useRef();

  return (
    <>
      <InnerBanner title={endPoint} />
      <BredCrumb title={endPoint} navigateTo={navigateTo} />
      <div className="about-content internal-content-wrap">
        <div className="container">
          <div className="tab-pane container active" id="case_number">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                errors,
                values,
                resetForm,
              }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    {/* field 1 */}
                    <div className="form-group row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                        <label className="col-form-label mandatory">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          onChange={handleChange}
                          value={values.name}
                          onBlur={handleBlur}
                        />
                        {touched.name && errors.name ? (
                          <div className="error">{errors.name}</div>
                        ) : null}
                      </div>

                      {/* field 2 */}

                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                        <label className="col-form-label mandatory">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          onChange={handleChange}
                          value={values.email}
                          onBlur={handleBlur}
                        />
                        {touched.email && errors.email ? (
                          <div className="error">{errors.email}</div>
                        ) : null}
                      </div>

                      {/* field 3 */}

                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                        <label className="col-form-label mandatory">
                          Mobile No.
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="mobile"
                          name="mobile"
                          onChange={handleChange}
                          value={values.mobile}
                          onBlur={handleBlur}
                        />
                        {touched.mobile && errors.mobile ? (
                          <div className="error">{errors.mobile}</div>
                        ) : null}
                      </div>

                      {/* field 4 */}

                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                        <label className="col-form-label mandatory">
                          Message
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="message"
                          name="message"
                          onChange={handleChange}
                          value={values.message}
                          onBlur={handleBlur}
                        />
                        {touched.message && errors.message ? (
                          <div className="error">{errors.message}</div>
                        ) : null}
                      </div>
                      {/* <Captcha
                        onChange={(status) => {
                          setCaptchaStatus(status);
                        }}
                        ref={capt}
                        required
                      /> */}

                      {/* submit field */}

                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3 mt-3">
                        <div className="search_cancel_btn">
                          <button
                            type="submit"
                            className="search"
                            // disabled={!captchaStatus}
                          >
                            Submit
                          </button>
                          <a
                            type="button"
                            className="cancel"
                            onClick={resetForm}
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackPage;
