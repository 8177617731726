import React from "react";
import { CatBenches } from "../../../Benches/Benches";
import ChairPerson from "../../../frontend/containers/Home/ChairPerson";
import Chart from "../../../frontend/containers/Home/Chart";
import Circulars from "../../../frontend/containers/Home/Circulars";
import HomeBanner from "../../../frontend/containers/Home/HomeBanner";
import OtherLinks from "../../../frontend/containers/Home/OtherLinks";
import PhotoVideoEvent from "../../../frontend/containers/Home/PhotoVideoEvent/PhotoVideoEvent";
import WhatsNew from "../../../frontend/containers/Home/WhatsNew";
import CaseCauseDailyFinalOrderHome from "../../Ahmedabad/Containers/Home/CaseCauseDailyFinalOrderAH/CaseCauseDailyFinalOrderHome";
import { useTitle } from "../../../Utilities/pageTitle";

const HomeDL = () => {
  useTitle("Home");
  return (
    <>
      <HomeBanner bannerId={20} />
      <WhatsNew benchId={20} />
      <div className="case_details_sec">
        <div className="container">
          <div className="row">
            <CaseCauseDailyFinalOrderHome
              schemaId={CatBenches.delhi.id}
              bench={CatBenches.delhi.name}
            />
            <ChairPerson memberId={20} />
          </div>
        </div>
      </div>
      <div className="circulars_photo_video_event_sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <div className="row">
                <Circulars circularId={20} />
                <PhotoVideoEvent galleryId={20} />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <Chart schemaId={CatBenches.delhi.id} />
            </div>
          </div>
        </div>
      </div>
      <OtherLinks navLinkId={1} />
    </>
  );
};

export default HomeDL;
