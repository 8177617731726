import React from "react";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../shared/Loader";
import { Link } from "react-router-dom";
import { setChairmanType } from "../../../redux/slice/sliceExternal/catSliceExternal";

const ChairpersonHome = ({ memberId }) => {
  const { data: hod, isLoading } = useGetInternalDataQuery(
    `get-hod/${memberId}`
  );
  const { languageType } = useSelector((state) => state.catSliceExternal);

  const dispatch = useDispatch();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
      {hod && hod.status && hod.data[0] ? (
        <>
          <div className="chairman-sec-Square main-chairman">
            <Link
              to="read-more-chairman"
              onClick={() => dispatch(setChairmanType("0"))}
            >
              <img src={hod.data[0]?.image} alt="chairman" />
              {languageType === "hid" ? (
                <h3>
                  माननीय <span className="hi-text">U;k;ewfrZ j.kftr</span>
                  वसंतराव मोरे
                </h3>
              ) : (
                <h3>{hod.data[0]?.name}</h3>
              )}
              <h5>{hod.data[0]?.designation}</h5>
            </Link>
          </div>
          <div className="chairman-sec main-chairman">
            <Link
              to="read-more-chairman-2"
              onClick={() => dispatch(setChairmanType("1"))}
            >
              <img src={hod.data[1]?.image} alt="registrar" />
              <h3>{hod.data[1]?.name}</h3>
              {languageType === "hid" ? (
                <h5>प्रधान रजिस्ट्रार</h5>
              ) : (
                <h5>{hod.data[1]?.designation}</h5>
              )}
            </Link>
          </div>
        </>
      ) : (
        <>No Data Found</>
      )}
    </div>
  );
};

export default ChairpersonHome;
