import React from "react";
import { Route, Routes } from "react-router-dom";
import { CatBenches } from "../Benches/Benches";
import Home from "./containers/Home/Home";

// header menu pages
import MenuPageWithTableContent from "./containers/FooterSliderPages/MenuPageWithTableContent";

// other pages
import ReadMoreChairman from "./containers/Home/ReadMoreChairman";
import ReadMoreChairman2 from "./containers/Home/ReadMoreChairman2";
import UnderDevelopment from "./containers/UnderDevelopment";
import MediaGallery from "./containers/Home/PhotoVideoEvent/MediaGallery";
import PhotoGallery from "./containers/Home/PhotoVideoEvent/PhotoGallery";
import VideoGallery from "./containers/Home/PhotoVideoEvent/VideoGallery";
import EventGallery from "./containers/Home/PhotoVideoEvent/EventGallery";
import CircularMoreDetails from "./containers/Home/CircularMoreDetails";
import ScreenReader from "./containers/ScreenReader";
import GalleryDetail from "./containers/Home/PhotoVideoEvent/GalleryDetail";
// footer slider pages
import Introduction from "../Microsite_Frontend/Ahmedabad/Containers/MicroSiteMenuPages/Introduction";
import Vacancies from "./containers/FooterSliderPages/Vacancies";
import Forms from "./containers/FooterSliderPages/Forms";
import Calendar from "./containers/FooterSliderPages/Calendar";
import TourProgramme from "./containers/FooterSliderPages/TourProgramme";
import EmployCorner from "./containers/FooterSliderPages/EmployCorner";

// footer links

import FooterMenuContentPage from "./containers/FooterPages/FooterMenuContentPage";
import ImportantLinksHome from "./containers/FooterSliderPages/ImportantLinksHome";
import FeedbackPage from "./containers/FeedbackPage";
import AboutUs from "../Microsite_Frontend/Ahmedabad/Containers/MicroSiteMenuPages/AboutUs";

const AppRouting = () => {
  return (
    <Routes>
      <Route exact path="/" index element={<Home />}></Route>
      <Route
        exact
        path="screenreader"
        element={<ScreenReader navigateTo={"/"} />}
      ></Route>
      {/* ----------------------------main menu pages----------------------------------- */}

      <>
        <Route
          exact
          path="introduction"
          element={<Introduction pageId={162} navigateTo={"/"} />}
        ></Route>
        <Route
          exact
          path="about-us"
          element={<AboutUs pageId={162} navigateTo={"/"} />}
        ></Route>
        <Route
          exact
          path="act"
          element={
            <MenuPageWithTableContent
              benchId={20}
              navigateTo={"/"}
              endPoint={"act"}
            />
          }
        ></Route>
        <Route
          exact
          path="rule"
          element={
            <MenuPageWithTableContent
              benchId={20}
              navigateTo={"/"}
              endPoint={"rule"}
            />
          }
        ></Route>
        <Route
          exact
          path="jurisdiction"
          element={
            <MenuPageWithTableContent
              benchId={20}
              navigateTo={"/"}
              endPoint={"jurisdiction"}
            />
          }
        ></Route>
        <Route
          exact
          path="benches"
          element={
            <MenuPageWithTableContent
              benchId={1}
              navigateTo={"/"}
              endPoint={"benches"}
            />
          }
        ></Route>
        {/* member */}
        <>
          <Route
            exact
            path="bench-wise-list-of-hon'ble-chairman-and-member-of-cat"
            element={
              <MenuPageWithTableContent
                benchId={1}
                navigateTo={"/"}
                endPoint={
                  "bench-wise-list-of-hon-ble-chairman-and-member-of-cat"
                }
              />
            }
          ></Route>
          <Route
            exact
            path="former-member"
            element={
              <MenuPageWithTableContent
                benchId={1}
                navigateTo={"/"}
                endPoint={"former-members"}
              />
            }
          ></Route>
          <Route
            exact
            path="list-of-hon'ble-chairman-and-members-retiring"
            element={
              <MenuPageWithTableContent
                benchId={1}
                navigateTo={"/"}
                endPoint={"list-of-honble-chairman-and-members-retiring	"}
              />
            }
          ></Route>
        </>
      </>
      {/* --------------------------------Other pages-------------------------------------- */}
      <>
        <Route
          exact
          path="read-more-chairman"
          element={<ReadMoreChairman memberId="1" navigateTo={"/"} />}
        ></Route>
        <Route
          exact
          path="read-more-chairman-2"
          element={<ReadMoreChairman2 memberId="1" navigateTo={"/"} />}
        ></Route>

        <Route
          exact
          path="photo-media-gallery"
          element={<MediaGallery navigateTo={"/"} />}
        />
        <Route
          exact
          path="photo-gallery"
          element={
            <PhotoGallery
              navigateTo={CatBenches.delhi.navigateTo}
              benchId={20}
              categoryId={2}
            />
          }
        />
        <Route
          exact
          path="video-gallery"
          element={
            <VideoGallery
              navigateTo={CatBenches.delhi.navigateTo}
              benchId={20}
              categoryId={3}
            />
          }
        />
        <Route
          exact
          path="event-gallery"
          element={
            <EventGallery
              navigateTo={CatBenches.delhi.navigateTo}
              benchId={20}
              categoryId={4}
            />
          }
        />
        <Route
          exact
          path="event-gallery/gallery-detail/:id"
          element={<GalleryDetail />}
        />
        <Route
          exact
          path="photo-gallery/gallery-detail/:id"
          element={<GalleryDetail />}
        />
        <Route
          exact
          path="notices/circulars"
          element={<CircularMoreDetails circularId={20} navigateTo={"/"} />}
        ></Route>
      </>
      {/*------------------------------ footer links------------------------------------- */}
      <>
        <Route
          exact
          path="important-links"
          element={
            <ImportantLinksHome
              menuId={1}
              navigateTo={"/"}
              pageTitle="Important Links"
            />
          }
        ></Route>
        <Route
          exact
          path="/vacancies"
          element={<Vacancies benchId={20} navigateTo={"/"} />}
        ></Route>
        <Route
          exact
          path="/forms"
          element={<Forms benchId={20} navigateTo={"/"} />}
        ></Route>
        <Route
          exact
          path="tour-programme"
          element={<TourProgramme benchId={20} navigateTo={"/"} />}
        ></Route>
        <Route
          exact
          path="employee-corner"
          element={<EmployCorner benchId={20} navigateTo={"/"} />}
        ></Route>
        <Route
          exact
          path="calendar"
          element={<Calendar benchId={1} navigateTo={"/"} />}
        ></Route>
      </>
      {/* ------------------------footer menu pages--------------------------------- */}
      <>
        <Route
          exact
          path="web-information-manager"
          element={
            <FooterMenuContentPage
              pageId={693}
              navigateTo={"/"}
              endPoint={"web-information-manager"}
            />
          }
        ></Route>
        <Route
          exact
          path="terms-&-condition"
          element={
            <FooterMenuContentPage
              pageId={56}
              navigateTo={"/"}
              endPoint={"terms-&-condition"}
            />
          }
        ></Route>{" "}
        <Route
          exact
          path="privacy-policy"
          element={
            <FooterMenuContentPage
              pageId={57}
              navigateTo={"/"}
              endPoint={"privacy-policy"}
            />
          }
        ></Route>{" "}
        <Route
          exact
          path="copyright-policy"
          element={
            <FooterMenuContentPage
              pageId={156}
              navigateTo={"/"}
              endPoint={"copyright-policy"}
            />
          }
        ></Route>{" "}
        <Route
          exact
          path="hyperlinking-policy"
          element={
            <FooterMenuContentPage
              pageId={58}
              navigateTo={"/"}
              endPoint={"hyperlinking-policy"}
            />
          }
        ></Route>{" "}
        <Route
          exact
          path="disclaimer"
          element={
            <FooterMenuContentPage
              pageId={59}
              navigateTo={"/"}
              endPoint={"disclaimer"}
            />
          }
        ></Route>{" "}
        <Route
          exact
          path="accessibility-statement"
          element={
            <FooterMenuContentPage
              pageId={157}
              navigateTo={"/"}
              endPoint={"accessibility-statement"}
            />
          }
        ></Route>{" "}
        <Route
          exact
          path="help"
          element={
            <FooterMenuContentPage
              pageId={60}
              navigateTo={"/"}
              endPoint={"help"}
            />
          }
        ></Route>
        <Route
          exact
          path="sitemap"
          element={
            <ImportantLinksHome
              menuId={1}
              navigateTo={"/"}
              pageTitle="Sitemap"
            />
          }
        ></Route>
        <Route
          exact
          path="contact-us"
          element={
            <FooterMenuContentPage
              pageId={49}
              navigateTo={"/"}
              endPoint={"contact-us"}
            />
          }
        ></Route>
        {/* other footer pages with table */}
        <Route
          exact
          path="suomoto"
          element={
            <MenuPageWithTableContent
              benchId={20}
              navigateTo={"/"}
              endPoint={"suo-moto"}
            />
          }
        ></Route>
        <Route
          exact
          path="citizen-charter"
          element={
            <MenuPageWithTableContent
              benchId={1}
              navigateTo={"/"}
              endPoint={"citizen-charter"}
            />
          }
        ></Route>
        <Route
          exact
          path="feedback"
          element={<FeedbackPage navigateTo={"/"} endPoint={"feedback"} />}
        ></Route>
      </>
      {/* ------------------------UnderDevelopment---------------------------------- */}
      <>
        <Route exact path="*" element={<UnderDevelopment />}></Route>
        <Route
          exact
          path="underDevelopment"
          element={<UnderDevelopment />}
        ></Route>
      </>
    </Routes>
  );
};

export default AppRouting;
