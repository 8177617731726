import React, { useRef, useState } from "react";
import Slider from "react-slick";

import { useGetInternalDataQuery } from "../../../../../redux/slices/catApiSlice";
import Skeleton from "react-loading-skeleton";
import GallerySkeleton from "../../../../../Skeleton/GallerySkeleton";
import Loader from "../../../../shared/Loader";

const Videos = ({ galleryId, categoryId }) => {
  const { data: gallery, isLoading } = useGetInternalDataQuery(
    `get-gallery/${galleryId}/${categoryId}`
  );

  const [playPause, setPlayPause] = useState(false);

  const slider = useRef();
  //slider settings
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const next = () => {
    slider.current.slickNext();
  };
  const prev = () => {
    slider.current.slickPrev();
  };

  const HandlePlayPause = () => {
    setPlayPause(!playPause);
    if (playPause) {
      slider.current.slickPlay();
    }
    if (!playPause) {
      slider.current.slickPause();
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="tab-pane container fade show active" id="video">
      <div id="demo3" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          <Slider {...settings} ref={slider}>
            {gallery && gallery.status ? (
              gallery.data &&
              gallery.data.map((item, index) => (
                <div key={index} className="carousel-item active">
                  <video width="320" height="240" controls>
                    <source src={item.file_path} type="video/mp4" />
                  </video>
                  <div className="container">
                    <div className="carousel-content">
                      <h2>{item.title}</h2>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>No Data Found</>
            )}
          </Slider>
        </div>
        <div className="navsec">
          <span className="left" onClick={prev}>
            <i className="fa fa-angle-left"></i>
          </span>

          <span className="right" onClick={HandlePlayPause}>
            {!playPause ? (
              <i className="fas fa-pause"></i>
            ) : (
              <i className="fas fa-play"></i>
            )}
          </span>
          <span className="right" onClick={next}>
            <i className="fa fa-angle-right"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Videos;
