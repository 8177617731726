export const sentenceCase = (str) =>
  str &&
  str
    .split(" ")
    .map((w) => w && w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(" ");

// date formatter
export const formattedDate = (date) => {
  if (date) {
    const [dd, mm, yyyy] = date.split("/");
    return [
      dd.length === 1 ? `0${dd}` : dd,
      mm.length === 1 ? `0${mm}` : mm,
      yyyy,
    ].join("/");
  }
};

export const handleDate = (value) => {
  return value.split("-").reverse().join("/");
};

//open file with new tab
export const targetOnNewTab = () => {
  document.querySelectorAll(".internal-content-wrap a").forEach((item) => {
    item.setAttribute("target", "_blank");
  });
};

//
export const targetOnNewTab1 = () => {
  var anchors = document.getElementsByTagName("a");
  for (var i = 0; i < anchors.length; i++) {
    if (anchors[i].hostname != window.location.hostname) {
      anchors[i].setAttribute("target", "_blank");
    }
  }
};

export const payloadForm = (payload) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(payload)) {
    formData.append(key, value);
  }
  return formData;
};

export const getPayload = (id) => {
  const formData = new FormData();
  formData.append("catschemaId", id);
  return formData;
};

export const generateYearList = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = currentYear; year >= 1985; year--) {
    years.push(year);
  }
  return years;
};

export const filterDataByYear = (data, year) => {
  try {
    let archiveData = [];

    data?.forEach((item) => {
      const parts = item.date_of_issue.split("-");
      const itemYear = parseInt(parts[0], 10);
      if (itemYear === year) {
        archiveData.push(item);
      }
    });

    return { archiveData, allData: data };
  } catch (error) {
    console.error("Error occurred while filtering data:", error);
    return { archiveData: [], allData: [] };
  }
};

export const currentYear = new Date().getFullYear();

export const filterCircularsDataByYear = (data, year) => {
  try {
    let archiveData = [];

    data?.forEach((item) => {
      const parts = item?.from_date?.split("-");
      if (!item?.from_date) {
        return; // Skip items with null or undefined from_date
      }
      const itemYear = parseInt(parts[0], 10);
      if (itemYear === year) {
        archiveData.push(item);
      }
    });

    return { archiveData, allData: data };
  } catch (error) {
    console.error("Error occurred while filtering data:", error);
    return { archiveData: [], allData: [] };
  }
};

export function capitalizeWords(str) {
  return str
    .replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    })
    .replace(/-/g, " ");
}
