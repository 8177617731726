import React from "react";
import HeaderMiddle from "../../../frontend/shared/header/HeaderMiddle";
import Menus from "../../../frontend/shared/header/Menus";
import TopHeader from "../../../frontend/shared/header/TopHeader";

const HeaderDL = () => {
  return (
    <header>
      {/* <TopHeader /> */}
      <HeaderMiddle bench={"Principal bench"} to={"/delhi"} />
      <Menus menuId={20} homeLink="/delhi/" />
    </header>
  );
};

export default HeaderDL;
