import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Modal, Table } from "react-bootstrap";
import { Formik } from "formik";
import {
  fetchCaseStatusAdvocateNameWiseData,
  reset,
  setSearchedName,
} from "../../../../../redux/slice/sliceExternal/catSliceExternal";
import Loader from "../../../../shared/Loader";
import PartyNameMoreDetails from "./PartyNameMoreDetails";
import {
  formattedDate,
  payloadForm,
  sentenceCase,
} from "../../../../../Utilities/FuncUtils";
import printJS from "print-js";
import { useGetExternalDataMutation } from "../../../../../redux/slices/catApiSlice";

const AdvocateName = ({ schemaId, bench }) => {
  const [advocateNameFunc, { data: advocateNameWiseData, isLoading, isError }] =
    useGetExternalDataMutation();
  // states for modals
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();

  // fetching data from store
  const { searchedName } = useSelector((state) => state.catSliceExternal);

  //---------------- Formik ------------------------//

  // initial Values
  const initialValues = {
    advType: "",
    advName: "",
  };

  // validation
  const validationSchema = Yup.object({
    advType: Yup.string()
      .required("Required")
      .typeError("Enter Correct Advocate Name"),
    advName: Yup.string().required("Required"),
  });

  // final submit formik form
  const handleSubmit = (values, { resetForm }) => {
    dispatch(setSearchedName(values.advName));
    const payload = {
      catschemaId: schemaId,
      advType: values.advType,
      advName: values.advName.toLowerCase(),
    };
    advocateNameFunc({
      url: "casedetail_adv_name_wise",
      payload: payloadForm(payload),
    });
    // dispatching actions for fetching diary wise data

    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);
    resetForm();
  };

  return (
    <>
      <div className="tab-pane container active" id="case_number">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            errors,
            values,
            resetForm,
          }) => (
            <>
              <form onSubmit={handleSubmit}>
                {/* field 1 */}
                <div className="form-group row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                    <label className="col-form-label mandatory">
                      Select Advocate :
                    </label>
                    <select
                      type="text"
                      className="form-control"
                      id="advType"
                      name="advType"
                      onChange={handleChange}
                      value={values.advType}
                      onBlur={handleBlur}
                    >
                      <option hidden>Select</option>
                      <option value="1">Applicant</option>
                      <option value="2">Respondent</option>
                    </select>
                    {touched.advType && errors.advType ? (
                      <div className="error">{errors.advType}</div>
                    ) : null}
                  </div>

                  {/* field 2 */}

                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                    <label className="col-form-label mandatory">
                      Enter Advocate Name :
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="advName"
                      name="advName"
                      onChange={handleChange}
                      value={values.advName}
                      onBlur={handleBlur}
                    />
                    {touched.advName && errors.advName ? (
                      <div className="error">{errors.advName}</div>
                    ) : null}
                  </div>

                  {/* submit field */}

                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div className="search_cancel_btn">
                      <button type="submit" className="search">
                        Search
                      </button>
                      <a type="button" className="cancel" onClick={resetForm}>
                        Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </form>
              {isLoading ? (
                <Loader />
              ) : (
                <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
                  <Modal.Header>
                    <div>
                      <div>
                        <b> {bench} </b>
                        <br />
                        Case Status - Advocate Name wise
                      </div>
                    </div>
                    <div className="button-div">
                      <i
                        className="fas fa-print"
                        onClick={() => printJS("table", "html")}
                      ></i>
                      <i
                        className="fas fa-window-close"
                        onClick={() => setIsOpenModal(false)}
                      ></i>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="table-responsive model-mh" id="table">
                      <div className="searchedName">
                        Search Results for "{searchedName}"
                      </div>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Sr.No.</th>
                            <th>Applicant vs Respondent</th>
                            <th>Case No.</th>
                            <th>Case Type</th>
                            <th>Dairy No.</th>
                            <th>Filing No</th>
                            <th>Applicant Advocate</th>
                            <th>Respondent Advocate</th>
                            <th>Date of Filing</th>
                            <th>Stage</th>
                            <th>Other Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {advocateNameWiseData &&
                          !isError &&
                          advocateNameWiseData.length > 0 ? (
                            advocateNameWiseData.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {sentenceCase(item.applicant)}
                                  <br />
                                  vs <br />
                                  {sentenceCase(item.respondent)}
                                </td>
                                <td>{item.caseno}</td>
                                <td>{item.casetype}</td>
                                <td>{item.diaryno}</td>
                                <td>{item.filingNo}</td>
                                <td>{sentenceCase(item.applicantadvocate)}</td>
                                <td>{sentenceCase(item.respondentadvocate)}</td>
                                <td>{formattedDate(item.dateoffiling)}</td>
                                <td>{item.stage}</td>
                                <td>
                                  <PartyNameMoreDetails
                                    item={item.diaryno}
                                    schemaId={schemaId}
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <>
                              <tr>
                                <td colSpan="10"> No Record Found </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AdvocateName;
