import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import Loader from "../../shared/Loader";

import { getPayload } from "../../../Utilities/FuncUtils";
import { CatBenches } from "../../../Benches/Benches";
import {
  useTodayFiledCasesDelhiQuery,
  useTodayFiledCasesAhmedabadQuery,
  useTotalFilledCasesAllahabadQuery,
  useTotalFilledCasesBangaloreQuery,
  useTotalFilledCasesChandigarhQuery,
  useTotalFilledCasesChennaiQuery,
  useTotalDisposedCasesDelhiQuery,
  useTotalDisposedCasesAhmedabadQuery,
  useTotalDisposedCasesAllahabadQuery,
  useTotalDisposedCasesBangaloreQuery,
  useTotalDisposedCasesChandigarhQuery,
  useTotalDisposedCasesChennaiQuery,
  useTotalFilledCasesDelhiQuery,
  useTotalFilledCasesAhmedabadQuery,
  useTodayFiledCasesAllahabadQuery,
  useTodayFiledCasesBangaloreQuery,
  useTodayFiledCasesChandigarhQuery,
  useTodayFiledCasesChennaiQuery,
  useTotalPendingCasesDelhiQuery,
  useTotalPendingCasesAhmedabadQuery,
  useTotalPendingCasesAllahabadQuery,
  useTotalPendingCasesBangaloreQuery,
  useTotalPendingCasesChandigarhQuery,
  useTotalFilledCasesCuttackQuery,
  useTotalPendingCasesCuttackQuery,
  useTotalDisposedCasesCuttackQuery,
  useTodayFiledCasesCuttackQuery,
  useTotalPendingCasesChennaiQuery,
  useTotalFilledCasesErnakulamQuery,
  useTotalDisposedCasesErnakulamQuery,
  useTotalPendingCasesErnakulamQuery,
  useTodayFiledCasesErnakulamQuery,
  useTotalFilledCasesHyderabadQuery,
  useTotalDisposedCasesHyderabadQuery,
  useTotalPendingCasesHyderabadQuery,
  useTodayFiledCasesHyderabadQuery,
  useTotalFilledCasesGuwahatiQuery,
  useTotalDisposedCasesGuwahatiQuery,
  useTotalPendingCasesGuwahatiQuery,
  useTodayFiledCasesGuwahatiQuery,
  useTotalFilledCasesJabalpurQuery,
  useTotalDisposedCasesJabalpurQuery,
  useTotalPendingCasesJabalpurQuery,
  useTodayFiledCasesJabalpurQuery,
  useTotalFilledCasesJaipurQuery,
  useTotalDisposedCasesJaipurQuery,
  useTotalPendingCasesJaipurQuery,
  useTodayFiledCasesJaipurQuery,
  useTotalFilledCasesJammuQuery,
  useTotalDisposedCasesJammuQuery,
  useTotalPendingCasesJammuQuery,
  useTodayFiledCasesJammuQuery,
  useTotalFilledCasesJodhpurQuery,
  useTotalDisposedCasesJodhpurQuery,
  useTotalPendingCasesJodhpurQuery,
  useTodayFiledCasesJodhpurQuery,
  useTotalFilledCasesKolkataQuery,
  useTotalDisposedCasesKolkataQuery,
  useTotalPendingCasesKolkataQuery,
  useTodayFiledCasesKolkataQuery,
  useTotalFilledCasesLucknowQuery,
  useTotalDisposedCasesLucknowQuery,
  useTotalPendingCasesLucknowQuery,
  useTodayFiledCasesLucknowQuery,
  useTotalFilledCasesMumbaiQuery,
  useTotalDisposedCasesMumbaiQuery,
  useTotalPendingCasesMumbaiQuery,
  useTodayFiledCasesMumbaiQuery,
  useTotalFilledCasesPatnaQuery,
  useTotalDisposedCasesPatnaQuery,
  useTotalPendingCasesPatnaQuery,
  useTodayFiledCasesPatnaQuery,
  useTotalFilledCasesSrinagarQuery,
  useTotalDisposedCasesSrinagarQuery,
  useTotalPendingCasesSrinagarQuery,
  useTodayFiledCasesSrinagarQuery,
} from "../../../redux/slices/catApiSliceChart";

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartHome = () => {
  const { data: todayCaseFiledDelhi, isLoading: todayCaseFiledLoadingDelhi } =
    useTodayFiledCasesDelhiQuery(getPayload(CatBenches.delhi.id));
  const {
    data: todayCaseFiledAhmedabad,
    isLoading: todayCaseFiledLoadingAhmedabad,
  } = useTodayFiledCasesAhmedabadQuery(getPayload(CatBenches.ahmedabad.id));
  const {
    data: todayCaseFiledAllahabad,
    isLoading: todayCaseFiledLoadingAllahabad,
  } = useTodayFiledCasesAllahabadQuery(getPayload(CatBenches.allahabad.id));
  const {
    data: todayCaseFiledBangalore,
    isLoading: todayCaseFiledLoadingBangalore,
  } = useTodayFiledCasesBangaloreQuery(getPayload(CatBenches.bangalore.id));
  const {
    data: todayCaseFiledChandigarh,
    isLoading: todayCaseFiledLoadingChandigarh,
  } = useTodayFiledCasesChandigarhQuery(getPayload(CatBenches.chandigarh.id));
  const {
    data: todayCaseFiledChennai,
    isLoading: todayCaseFiledLoadingChennai,
  } = useTodayFiledCasesChennaiQuery(getPayload(CatBenches.chennai.id));
  const {
    data: todayCaseFiledCuttack,
    isLoading: todayCaseFiledLoadingCuttack,
  } = useTodayFiledCasesCuttackQuery(getPayload(CatBenches.cuttack.id));
  const {
    data: todayCaseFiledErnakulam,
    isLoading: todayCaseFiledLoadingErnakulam,
  } = useTodayFiledCasesErnakulamQuery(getPayload(CatBenches.ernakulam.id));
  const {
    data: todayCaseFiledGuwahati,
    isLoading: todayCaseFiledLoadingGuwahati,
  } = useTodayFiledCasesGuwahatiQuery(getPayload(CatBenches.guwahati.id));
  const {
    data: todayCaseFiledHyderabad,
    isLoading: todayCaseFiledLoadingHyderabad,
  } = useTodayFiledCasesHyderabadQuery(getPayload(CatBenches.hyderabad.id));
  const {
    data: todayCaseFiledJabalpur,
    isLoading: todayCaseFiledLoadingJabalpur,
  } = useTodayFiledCasesJabalpurQuery(getPayload(CatBenches.jabalpur.id));
  const { data: todayCaseFiledJaipur, isLoading: todayCaseFiledLoadingJaipur } =
    useTodayFiledCasesJaipurQuery(getPayload(CatBenches.jaipur.id));
  const { data: todayCaseFiledJammu, isLoading: todayCaseFiledLoadingJammu } =
    useTodayFiledCasesJammuQuery(getPayload(CatBenches.jammu.id));
  const {
    data: todayCaseFiledJodhpur,
    isLoading: todayCaseFiledLoadingJodhpur,
  } = useTodayFiledCasesJodhpurQuery(getPayload(CatBenches.jodhpur.id));
  const {
    data: todayCaseFiledKolkata,
    isLoading: todayCaseFiledLoadingKolkata,
  } = useTodayFiledCasesKolkataQuery(getPayload(CatBenches.kolkata.id));
  const {
    data: todayCaseFiledLucknow,
    isLoading: todayCaseFiledLoadingLucknow,
  } = useTodayFiledCasesLucknowQuery(getPayload(CatBenches.lucknow.id));
  const { data: todayCaseFiledMumbai, isLoading: todayCaseFiledLoadingMumbai } =
    useTodayFiledCasesMumbaiQuery(getPayload(CatBenches.mumbai.id));
  const { data: todayCaseFiledPatna, isLoading: todayCaseFiledLoadingPatna } =
    useTodayFiledCasesPatnaQuery(getPayload(CatBenches.patna.id));
  const {
    data: todayCaseFiledSrinagar,
    isLoading: todayCaseFiledLoadingSrinagar,
  } = useTodayFiledCasesSrinagarQuery(getPayload(CatBenches.srinagar.id));

  const {
    data: totalPendingCasesDelhi,
    isLoading: totalPendingCasesLoadingDelhi,
  } = useTotalPendingCasesDelhiQuery(getPayload(CatBenches.delhi.id));
  const {
    data: totalPendingCasesAhmedabad,
    isLoading: totalPendingCasesLoadingAhmedabad,
  } = useTotalPendingCasesAhmedabadQuery(getPayload(CatBenches.ahmedabad.id));
  const {
    data: totalPendingCasesAllahabad,
    isLoading: totalPendingCasesLoadingAllahabad,
  } = useTotalPendingCasesAllahabadQuery(getPayload(CatBenches.allahabad.id));
  const {
    data: totalPendingCasesBangalore,
    isLoading: totalPendingCasesLoadingBangalore,
  } = useTotalPendingCasesBangaloreQuery(getPayload(CatBenches.bangalore.id));
  const {
    data: totalPendingCasesChandigarh,
    isLoading: totalPendingCasesLoadingChandigarh,
  } = useTotalPendingCasesChandigarhQuery(getPayload(CatBenches.chandigarh.id));
  const {
    data: totalPendingCasesChennai,
    isLoading: totalPendingCasesLoadingChennai,
  } = useTotalPendingCasesCuttackQuery(getPayload(CatBenches.chennai.id));
  const {
    data: totalPendingCasesCuttack,
    isLoading: totalPendingCasesLoadingCuttack,
  } = useTotalPendingCasesChennaiQuery(getPayload(CatBenches.cuttack.id));
  const {
    data: totalPendingCasesErnakulam,
    isLoading: totalPendingCasesLoadingErnakulam,
  } = useTotalPendingCasesErnakulamQuery(getPayload(CatBenches.ernakulam.id));
  const {
    data: totalPendingCasesHyderabad,
    isLoading: totalPendingCasesLoadingHyderabad,
  } = useTotalPendingCasesHyderabadQuery(getPayload(CatBenches.hyderabad.id));
  const {
    data: totalPendingCasesGuwahati,
    isLoading: totalPendingCasesLoadingGuwahati,
  } = useTotalPendingCasesGuwahatiQuery(getPayload(CatBenches.guwahati.id));
  const {
    data: totalPendingCasesJabalpur,
    isLoading: totalPendingCasesLoadingJabalpur,
  } = useTotalPendingCasesJabalpurQuery(getPayload(CatBenches.jabalpur.id));
  const {
    data: totalPendingCasesJaipur,
    isLoading: totalPendingCasesLoadingJaipur,
  } = useTotalPendingCasesJaipurQuery(getPayload(CatBenches.jaipur.id));
  const {
    data: totalPendingCasesJammu,
    isLoading: totalPendingCasesLoadingJammu,
  } = useTotalPendingCasesJammuQuery(getPayload(CatBenches.jammu.id));
  const {
    data: totalPendingCasesJodhpur,
    isLoading: totalPendingCasesLoadingJodhpur,
  } = useTotalPendingCasesJodhpurQuery(getPayload(CatBenches.jodhpur.id));
  const {
    data: totalPendingCasesKolkata,
    isLoading: totalPendingCasesLoadingKolkata,
  } = useTotalPendingCasesKolkataQuery(getPayload(CatBenches.kolkata.id));
  const {
    data: totalPendingCasesLucknow,
    isLoading: totalPendingCasesLoadingLucknow,
  } = useTotalPendingCasesLucknowQuery(getPayload(CatBenches.lucknow.id));
  const {
    data: totalPendingCasesMumbai,
    isLoading: totalPendingCasesLoadingMumbai,
  } = useTotalPendingCasesMumbaiQuery(getPayload(CatBenches.mumbai.id));
  const {
    data: totalPendingCasesPatna,
    isLoading: totalPendingCasesLoadingPatna,
  } = useTotalPendingCasesPatnaQuery(getPayload(CatBenches.patna.id));
  const {
    data: totalPendingCasesSrinagar,
    isLoading: totalPendingCasesLoadingSrinagar,
  } = useTotalPendingCasesSrinagarQuery(getPayload(CatBenches.srinagar.id));

  const {
    data: totalDisposedCasesDelhi,
    isLoading: totalDisposedCasesLoadingDelhi,
  } = useTotalDisposedCasesDelhiQuery(getPayload(CatBenches.delhi.id));
  const {
    data: totalDisposedCasesAhmedabad,
    isLoading: totalDisposedCasesLoadingAhmedabad,
  } = useTotalDisposedCasesAhmedabadQuery(getPayload(CatBenches.ahmedabad.id));
  const {
    data: totalDisposedCasesAllahabad,
    isLoading: totalDisposedCasesLoadingAllahabad,
  } = useTotalDisposedCasesAllahabadQuery(getPayload(CatBenches.allahabad.id));
  const {
    data: totalDisposedCasesBangalore,
    isLoading: totalDisposedCasesLoadingBangalore,
  } = useTotalDisposedCasesBangaloreQuery(getPayload(CatBenches.bangalore.id));
  const {
    data: totalDisposedCasesChandigarh,
    isLoading: totalDisposedCasesLoadingChandigarh,
  } = useTotalDisposedCasesChandigarhQuery(
    getPayload(CatBenches.chandigarh.id)
  );
  const {
    data: totalDisposedCasesChennai,
    isLoading: totalDisposedCasesLoadingChennai,
  } = useTotalDisposedCasesChennaiQuery(getPayload(CatBenches.chennai.id));
  const {
    data: totalDisposedCasesCuttack,
    isLoading: totalDisposedCasesLoadingCuttack,
  } = useTotalDisposedCasesCuttackQuery(getPayload(CatBenches.cuttack.id));
  const {
    data: totalDisposedCasesErnakulam,
    isLoading: totalDisposedCasesLoadingErnakulam,
  } = useTotalDisposedCasesErnakulamQuery(getPayload(CatBenches.ernakulam.id));
  const {
    data: totalDisposedCasesHyderabad,
    isLoading: totalDisposedCasesLoadingHyderabad,
  } = useTotalDisposedCasesHyderabadQuery(getPayload(CatBenches.hyderabad.id));
  const {
    data: totalDisposedCasesGuwahati,
    isLoading: totalDisposedCasesLoadingGuwahati,
  } = useTotalDisposedCasesGuwahatiQuery(getPayload(CatBenches.guwahati.id));
  const {
    data: totalDisposedCasesJabalpur,
    isLoading: totalDisposedCasesLoadingJabalpur,
  } = useTotalDisposedCasesJabalpurQuery(getPayload(CatBenches.jabalpur.id));
  const {
    data: totalDisposedCasesJaipur,
    isLoading: totalDisposedCasesLoadingJaipur,
  } = useTotalDisposedCasesJaipurQuery(getPayload(CatBenches.jaipur.id));
  const {
    data: totalDisposedCasesJammu,
    isLoading: totalDisposedCasesLoadingJammu,
  } = useTotalDisposedCasesJammuQuery(getPayload(CatBenches.jammu.id));
  const {
    data: totalDisposedCasesJodhpur,
    isLoading: totalDisposedCasesLoadingJodhpur,
  } = useTotalDisposedCasesJodhpurQuery(getPayload(CatBenches.jodhpur.id));
  const {
    data: totalDisposedCasesKolkata,
    isLoading: totalDisposedCasesLoadingKolkata,
  } = useTotalDisposedCasesKolkataQuery(getPayload(CatBenches.kolkata.id));
  const {
    data: totalDisposedCasesLucknow,
    isLoading: totalDisposedCasesLoadingLucknow,
  } = useTotalDisposedCasesLucknowQuery(getPayload(CatBenches.lucknow.id));
  const {
    data: totalDisposedCasesMumbai,
    isLoading: totalDisposedCasesLoadingMumbai,
  } = useTotalDisposedCasesMumbaiQuery(getPayload(CatBenches.mumbai.id));
  const {
    data: totalDisposedCasesPatna,
    isLoading: totalDisposedCasesLoadingPatna,
  } = useTotalDisposedCasesPatnaQuery(getPayload(CatBenches.patna.id));
  const {
    data: totalDisposedCasesSrinagar,
    isLoading: totalDisposedCasesLoadingSrinagar,
  } = useTotalDisposedCasesSrinagarQuery(getPayload(CatBenches.srinagar.id));

  const {
    data: totalFilledCasesDelhi,
    isLoading: totalFilledCasesLoadingDelhi,
  } = useTotalFilledCasesDelhiQuery(getPayload(CatBenches.delhi.id));
  const {
    data: totalFilledCasesAhmedabad,
    isLoading: totalFilledCasesLoadingAhmedabad,
  } = useTotalFilledCasesAhmedabadQuery(getPayload(CatBenches.ahmedabad.id));
  const {
    data: totalFilledCasesAllahabad,
    isLoading: totalFilledCasesLoadingAllahabad,
  } = useTotalFilledCasesAllahabadQuery(getPayload(CatBenches.allahabad.id));
  const {
    data: totalFilledCasesBangalore,
    isLoading: totalFilledCasesLoadingBangalore,
  } = useTotalFilledCasesBangaloreQuery(getPayload(CatBenches.bangalore.id));
  const {
    data: totalFilledCasesChandigarh,
    isLoading: totalFilledCasesLoadingChandigarh,
  } = useTotalFilledCasesChandigarhQuery(getPayload(CatBenches.chandigarh.id));
  const {
    data: totalFilledCasesChennai,
    isLoading: totalFilledCasesLoadingChennai,
  } = useTotalFilledCasesChennaiQuery(getPayload(CatBenches.chennai.id));
  const {
    data: totalFilledCasesCuttack,
    isLoading: totalFilledCasesLoadingCuttack,
  } = useTotalFilledCasesCuttackQuery(getPayload(CatBenches.cuttack.id));
  const {
    data: totalFilledCasesErnakulam,
    isLoading: totalFilledCasesLoadingErnakulam,
  } = useTotalFilledCasesErnakulamQuery(getPayload(CatBenches.ernakulam.id));
  const {
    data: totalFilledCasesHyderabad,
    isLoading: totalFilledCasesLoadingHyderabad,
  } = useTotalFilledCasesHyderabadQuery(getPayload(CatBenches.hyderabad.id));
  const {
    data: totalFilledCasesGuwahati,
    isLoading: totalFilledCasesLoadingGuwahati,
  } = useTotalFilledCasesGuwahatiQuery(getPayload(CatBenches.guwahati.id));
  const {
    data: totalFilledCasesJabalpur,
    isLoading: totalFilledCasesLoadingJabalpur,
  } = useTotalFilledCasesJabalpurQuery(getPayload(CatBenches.jabalpur.id));
  const {
    data: totalFilledCasesJaipur,
    isLoading: totalFilledCasesLoadingJaipur,
  } = useTotalFilledCasesJaipurQuery(getPayload(CatBenches.jaipur.id));
  const {
    data: totalFilledCasesJammu,
    isLoading: totalFilledCasesLoadingJammu,
  } = useTotalFilledCasesJammuQuery(getPayload(CatBenches.jammu.id));
  const {
    data: totalFilledCasesJodhpur,
    isLoading: totalFilledCasesLoadingJodhpur,
  } = useTotalFilledCasesJodhpurQuery(getPayload(CatBenches.jodhpur.id));
  const {
    data: totalFilledCasesKolkata,
    isLoading: totalFilledCasesLoadingKolkata,
  } = useTotalFilledCasesKolkataQuery(getPayload(CatBenches.kolkata.id));
  const {
    data: totalFilledCasesLucknow,
    isLoading: totalFilledCasesLoadingLucknow,
  } = useTotalFilledCasesLucknowQuery(getPayload(CatBenches.lucknow.id));
  const {
    data: totalFilledCasesMumbai,
    isLoading: totalFilledCasesLoadingMumbai,
  } = useTotalFilledCasesMumbaiQuery(getPayload(CatBenches.mumbai.id));
  const {
    data: totalFilledCasesPatna,
    isLoading: totalFilledCasesLoadingPatna,
  } = useTotalFilledCasesPatnaQuery(getPayload(CatBenches.patna.id));
  const {
    data: totalFilledCasesSrinagar,
    isLoading: totalFilledCasesLoadingSrinagar,
  } = useTotalFilledCasesSrinagarQuery(getPayload(CatBenches.srinagar.id));

  const seriesData = [
    totalPendingCasesDelhi && parseInt(totalPendingCasesDelhi[0].pending),
    totalDisposedCasesDelhi && parseInt(totalDisposedCasesDelhi[0].disposal),
    todayCaseFiledDelhi && parseInt(todayCaseFiledDelhi[0].filed),
    totalFilledCasesDelhi && parseInt(totalFilledCasesDelhi[0].total),
  ];
  const total = seriesData && seriesData.reduce((a, v) => a + v);
  const inPercent =
    seriesData && seriesData.map((v) => Math.max((v / total) * 100, 3));

  const data = {
    labels: [
      "Total no of Pending Cases",
      "Total no of Disposed Cases",
      "Today's Filed Cases",
      "Total no of Filed Cases",
      ,
    ],
    datasets: [
      {
        data: inPercent,
        backgroundColor: [
          "rgba(39, 170, 200, 1)",
          "rgba(254, 173, 42, 1)",
          "rgba(117, 203, 52, 1)",
          "rgba(13, 57, 120, 1)",
        ],
        options: [
          {
            indexAxis: "y",
            scales: {
              y: {
                display: true,
              },
              x: {
                max: 100,
                min: 0,
                display: false,
              },
            },
          },
        ],
        hoverOffset: 10,
        weight: 50,
        radius: "80%",
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        position: "right",
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem, data) => {
            if (tooltipItem.dataIndex === 3) {
              return `Total Filed Cases : ${
                parseInt(totalFilledCasesDelhi[0].total) +
                parseInt(totalFilledCasesAhmedabad[0].total) +
                parseInt(totalFilledCasesAllahabad[0].total) +
                parseInt(totalFilledCasesBangalore[0].total) +
                parseInt(totalFilledCasesChandigarh[0].total) +
                parseInt(totalFilledCasesChennai[0].total) +
                parseInt(totalFilledCasesCuttack[0].total) +
                parseInt(totalFilledCasesErnakulam?.[0]?.total) +
                parseInt(totalFilledCasesHyderabad?.[0]?.total) +
                parseInt(totalFilledCasesGuwahati?.[0]?.total) +
                parseInt(totalFilledCasesJabalpur?.[0]?.total) +
                parseInt(totalFilledCasesJaipur?.[0]?.total) +
                parseInt(totalFilledCasesJammu?.[0]?.total) +
                parseInt(totalFilledCasesJodhpur?.[0]?.total) +
                parseInt(totalFilledCasesKolkata?.[0]?.total) +
                parseInt(totalFilledCasesLucknow?.[0]?.total) +
                parseInt(totalFilledCasesMumbai?.[0]?.total) +
                parseInt(totalFilledCasesPatna?.[0]?.total) +
                parseInt(totalFilledCasesSrinagar?.[0]?.total)
              }`;
            } else if (tooltipItem.dataIndex === 2) {
              return `Today's Filed Cases : ${
                parseInt(todayCaseFiledDelhi[0].filed) +
                parseInt(todayCaseFiledAhmedabad[0].filed) +
                parseInt(todayCaseFiledAllahabad[0].filed) +
                parseInt(todayCaseFiledBangalore[0].filed) +
                parseInt(todayCaseFiledChandigarh[0].filed) +
                parseInt(todayCaseFiledChennai[0].filed) +
                parseInt(todayCaseFiledCuttack[0].filed) +
                parseInt(todayCaseFiledErnakulam?.[0]?.filed) +
                parseInt(todayCaseFiledHyderabad?.[0]?.filed) +
                parseInt(todayCaseFiledGuwahati?.[0]?.filed) +
                parseInt(todayCaseFiledJabalpur?.[0]?.filed) +
                parseInt(todayCaseFiledJaipur?.[0]?.filed) +
                parseInt(todayCaseFiledJammu?.[0]?.filed) +
                parseInt(todayCaseFiledJodhpur?.[0]?.filed) +
                parseInt(todayCaseFiledKolkata?.[0]?.filed) +
                parseInt(todayCaseFiledLucknow?.[0]?.filed) +
                parseInt(todayCaseFiledMumbai?.[0]?.filed) +
                parseInt(todayCaseFiledPatna?.[0]?.filed) +
                parseInt(todayCaseFiledSrinagar?.[0]?.filed)
              }`;
            } else if (tooltipItem.dataIndex === 1) {
              return `Total Disposed Cases : ${
                parseInt(totalDisposedCasesDelhi[0].disposal) +
                parseInt(totalDisposedCasesAhmedabad[0].disposal) +
                parseInt(totalDisposedCasesAllahabad[0].disposal) +
                parseInt(totalDisposedCasesBangalore[0].disposal) +
                parseInt(totalDisposedCasesChandigarh[0].disposal) +
                parseInt(totalDisposedCasesChennai[0].disposal) +
                parseInt(totalDisposedCasesCuttack[0].disposal) +
                parseInt(totalDisposedCasesErnakulam?.[0]?.disposal) +
                parseInt(totalDisposedCasesHyderabad?.[0]?.disposal) +
                parseInt(totalDisposedCasesGuwahati?.[0]?.disposal) +
                parseInt(totalDisposedCasesJabalpur?.[0]?.disposal) +
                parseInt(totalDisposedCasesJaipur?.[0]?.disposal) +
                parseInt(totalDisposedCasesJammu?.[0]?.disposal) +
                parseInt(totalDisposedCasesJodhpur?.[0]?.disposal) +
                parseInt(totalDisposedCasesKolkata?.[0]?.disposal) +
                parseInt(totalDisposedCasesLucknow?.[0]?.disposal) +
                parseInt(totalDisposedCasesMumbai?.[0]?.disposal) +
                parseInt(totalDisposedCasesPatna?.[0]?.disposal) +
                parseInt(totalDisposedCasesSrinagar?.[0]?.disposal)
              }`;
            } else {
              return `Total Pending Cases: ${
                parseInt(totalPendingCasesDelhi[0].pending) +
                parseInt(totalPendingCasesAhmedabad[0].pending) +
                parseInt(totalPendingCasesAllahabad[0].pending) +
                parseInt(totalPendingCasesBangalore[0].pending) +
                parseInt(totalPendingCasesChandigarh[0].pending) +
                parseInt(totalPendingCasesChennai[0].pending) +
                parseInt(totalPendingCasesCuttack[0].pending) +
                parseInt(totalPendingCasesErnakulam?.[0]?.pending) +
                parseInt(totalPendingCasesHyderabad?.[0]?.pending) +
                parseInt(totalPendingCasesGuwahati?.[0]?.pending) +
                parseInt(totalPendingCasesJabalpur?.[0]?.pending) +
                parseInt(totalPendingCasesJaipur?.[0]?.pending) +
                parseInt(totalPendingCasesJammu?.[0]?.pending) +
                parseInt(totalPendingCasesJodhpur?.[0]?.pending) +
                parseInt(totalPendingCasesKolkata?.[0]?.pending) +
                parseInt(totalPendingCasesLucknow?.[0]?.pending) +
                parseInt(totalPendingCasesMumbai?.[0]?.pending) +
                parseInt(totalPendingCasesPatna?.[0]?.pending) +
                parseInt(totalPendingCasesSrinagar?.[0]?.pending)
              }`;
            }
          },
        },
        // xAlign: "center",
        // yAlign: "bottom",
      },
    },
  };

  if (
    todayCaseFiledLoadingDelhi ||
    totalFilledCasesLoadingDelhi ||
    totalDisposedCasesLoadingDelhi ||
    totalPendingCasesLoadingDelhi ||
    todayCaseFiledLoadingAhmedabad ||
    totalFilledCasesLoadingAhmedabad ||
    totalDisposedCasesLoadingAhmedabad ||
    totalPendingCasesLoadingAhmedabad ||
    todayCaseFiledLoadingAllahabad ||
    totalFilledCasesLoadingAllahabad ||
    totalDisposedCasesLoadingAllahabad ||
    totalPendingCasesLoadingAllahabad ||
    todayCaseFiledLoadingBangalore ||
    totalFilledCasesLoadingBangalore ||
    totalDisposedCasesLoadingBangalore ||
    totalPendingCasesLoadingBangalore ||
    todayCaseFiledLoadingChandigarh ||
    totalFilledCasesLoadingChandigarh ||
    totalDisposedCasesLoadingChandigarh ||
    totalPendingCasesLoadingChandigarh ||
    todayCaseFiledLoadingChennai ||
    totalFilledCasesLoadingChennai ||
    totalDisposedCasesLoadingChennai ||
    totalPendingCasesLoadingChennai ||
    todayCaseFiledLoadingCuttack ||
    totalFilledCasesLoadingCuttack ||
    totalDisposedCasesLoadingCuttack ||
    totalPendingCasesLoadingCuttack ||
    todayCaseFiledLoadingErnakulam ||
    totalFilledCasesLoadingErnakulam ||
    totalDisposedCasesLoadingErnakulam ||
    totalPendingCasesLoadingErnakulam ||
    todayCaseFiledLoadingHyderabad ||
    totalFilledCasesLoadingHyderabad ||
    totalDisposedCasesLoadingHyderabad ||
    totalPendingCasesLoadingHyderabad ||
    todayCaseFiledLoadingGuwahati ||
    totalFilledCasesLoadingGuwahati ||
    totalDisposedCasesLoadingGuwahati ||
    totalPendingCasesLoadingGuwahati ||
    todayCaseFiledLoadingJabalpur ||
    totalFilledCasesLoadingJabalpur ||
    totalDisposedCasesLoadingJabalpur ||
    totalPendingCasesLoadingJabalpur ||
    todayCaseFiledLoadingJaipur ||
    totalFilledCasesLoadingJaipur ||
    totalDisposedCasesLoadingJaipur ||
    totalPendingCasesLoadingJaipur ||
    todayCaseFiledLoadingJammu ||
    totalFilledCasesLoadingJammu ||
    totalDisposedCasesLoadingJammu ||
    totalPendingCasesLoadingJammu ||
    todayCaseFiledLoadingJodhpur ||
    totalFilledCasesLoadingJodhpur ||
    totalDisposedCasesLoadingJodhpur ||
    totalPendingCasesLoadingJodhpur ||
    todayCaseFiledLoadingKolkata ||
    totalFilledCasesLoadingKolkata ||
    totalDisposedCasesLoadingKolkata ||
    totalPendingCasesLoadingKolkata ||
    todayCaseFiledLoadingLucknow ||
    totalFilledCasesLoadingLucknow ||
    totalDisposedCasesLoadingLucknow ||
    totalPendingCasesLoadingLucknow ||
    todayCaseFiledLoadingMumbai ||
    totalFilledCasesLoadingMumbai ||
    totalDisposedCasesLoadingMumbai ||
    totalPendingCasesLoadingMumbai ||
    todayCaseFiledLoadingPatna ||
    totalFilledCasesLoadingPatna ||
    totalDisposedCasesLoadingPatna ||
    totalPendingCasesLoadingPatna ||
    todayCaseFiledLoadingSrinagar ||
    totalFilledCasesLoadingSrinagar ||
    totalDisposedCasesLoadingSrinagar ||
    totalPendingCasesLoadingSrinagar
  ) {
    return <Loader />;
  }

  return (
    <>
      <div className="chart-sec wow fadeInRight">
        <div className="chart-wrap">
          <div className="txt-1">
            Total no of <br /> Filed Cases
          </div>
          <div className="txt-2">
            Total no <br /> of Pending Cases
          </div>
          <Doughnut data={data} options={options} />
          <div className="txt-3">Today's Filed Cases</div>
          <div className="txt-4">
            Total No of <br /> Disposed Cases
          </div>
        </div>
        <ul className="counter-sec">
          <li>
            <span href="#">
              <img src="images/total-1.png" alt="total cases" />
              <h5>Total No of Pending Cases Home</h5>
              <h2 className="count">
                {totalPendingCasesDelhi &&
                  totalPendingCasesAhmedabad &&
                  parseInt(totalPendingCasesDelhi[0].pending) +
                    parseInt(totalPendingCasesAhmedabad[0].pending) +
                    parseInt(totalPendingCasesAllahabad[0].pending) +
                    parseInt(totalPendingCasesBangalore[0].pending) +
                    parseInt(totalPendingCasesChandigarh[0].pending) +
                    parseInt(totalPendingCasesChennai[0].pending) +
                    parseInt(totalPendingCasesCuttack[0].pending) +
                    parseInt(totalPendingCasesErnakulam?.[0]?.pending) +
                    parseInt(totalPendingCasesHyderabad?.[0]?.pending) +
                    parseInt(totalPendingCasesGuwahati?.[0]?.pending) +
                    parseInt(totalPendingCasesJabalpur?.[0]?.pending) +
                    parseInt(totalPendingCasesJaipur?.[0]?.pending) +
                    parseInt(totalPendingCasesJammu?.[0]?.pending) +
                    parseInt(totalPendingCasesJodhpur?.[0]?.pending) +
                    parseInt(totalPendingCasesKolkata?.[0]?.pending) +
                    parseInt(totalPendingCasesLucknow?.[0]?.pending) +
                    parseInt(totalPendingCasesMumbai?.[0]?.pending) +
                    parseInt(totalPendingCasesPatna?.[0]?.pending) +
                    parseInt(totalPendingCasesSrinagar?.[0]?.pending)}
              </h2>
            </span>
          </li>
          <li>
            <span>
              <img src="images/total-2.png" alt="total-2" />
              <h5>Total no of Disposed Cases</h5>
              <h2 className="count">
                {totalDisposedCasesDelhi &&
                  totalDisposedCasesAhmedabad &&
                  parseInt(totalDisposedCasesDelhi[0].disposal) +
                    parseInt(totalDisposedCasesAhmedabad[0].disposal) +
                    parseInt(totalDisposedCasesAllahabad[0].disposal) +
                    parseInt(totalDisposedCasesBangalore[0].disposal) +
                    parseInt(totalDisposedCasesChandigarh[0].disposal) +
                    parseInt(totalDisposedCasesChennai[0].disposal) +
                    parseInt(totalDisposedCasesCuttack[0].disposal) +
                    parseInt(totalDisposedCasesErnakulam?.[0]?.disposal) +
                    parseInt(totalDisposedCasesHyderabad?.[0]?.disposal) +
                    parseInt(totalDisposedCasesGuwahati?.[0]?.disposal) +
                    parseInt(totalDisposedCasesJabalpur?.[0]?.disposal) +
                    parseInt(totalDisposedCasesJaipur?.[0]?.disposal) +
                    parseInt(totalDisposedCasesJammu?.[0]?.disposal) +
                    parseInt(totalDisposedCasesJodhpur?.[0]?.disposal) +
                    parseInt(totalDisposedCasesKolkata?.[0]?.disposal) +
                    parseInt(totalDisposedCasesLucknow?.[0]?.disposal) +
                    parseInt(totalDisposedCasesMumbai?.[0]?.disposal) +
                    parseInt(totalDisposedCasesPatna?.[0]?.disposal) +
                    parseInt(totalDisposedCasesSrinagar?.[0]?.disposal)}
              </h2>
            </span>
          </li>
          <li>
            <span>
              <img src="images/total-3.png" alt="total-3" />
              <h5>Today's Filed Cases</h5>
              <h2 className="count">
                {todayCaseFiledDelhi &&
                  todayCaseFiledAhmedabad &&
                  parseInt(todayCaseFiledDelhi[0].filed) +
                    parseInt(todayCaseFiledAhmedabad[0].filed) +
                    parseInt(todayCaseFiledAllahabad[0].filed) +
                    parseInt(todayCaseFiledBangalore[0].filed) +
                    parseInt(todayCaseFiledChandigarh[0].filed) +
                    parseInt(todayCaseFiledChennai[0].filed) +
                    parseInt(todayCaseFiledCuttack[0].filed) +
                    parseInt(todayCaseFiledErnakulam?.[0]?.filed) +
                    parseInt(todayCaseFiledHyderabad?.[0]?.filed) +
                    parseInt(todayCaseFiledGuwahati?.[0]?.filed) +
                    parseInt(todayCaseFiledJabalpur?.[0]?.filed) +
                    parseInt(todayCaseFiledJaipur?.[0]?.filed) +
                    parseInt(todayCaseFiledJammu?.[0]?.filed) +
                    parseInt(todayCaseFiledJodhpur?.[0]?.filed) +
                    parseInt(todayCaseFiledKolkata?.[0]?.filed) +
                    parseInt(todayCaseFiledLucknow?.[0]?.filed) +
                    parseInt(todayCaseFiledMumbai?.[0]?.filed) +
                    parseInt(todayCaseFiledPatna?.[0]?.filed) +
                    parseInt(todayCaseFiledSrinagar?.[0]?.filed)}
              </h2>
            </span>
          </li>
          <li>
            <span>
              <img src="images/total-4.png" alt="total-4" />
              <h5>Total no of Filed Cases</h5>
              <h2 className="count">
                {totalFilledCasesDelhi &&
                  totalFilledCasesAhmedabad &&
                  parseInt(totalFilledCasesAhmedabad[0].total) +
                    parseInt(totalFilledCasesDelhi[0].total) +
                    parseInt(totalFilledCasesAllahabad[0].total) +
                    parseInt(totalFilledCasesBangalore[0].total) +
                    parseInt(totalFilledCasesChandigarh[0].total) +
                    parseInt(totalFilledCasesChennai[0].total) +
                    parseInt(totalFilledCasesCuttack[0].total) +
                    parseInt(totalFilledCasesErnakulam?.[0]?.total) +
                    parseInt(totalFilledCasesHyderabad?.[0]?.total) +
                    parseInt(totalFilledCasesGuwahati?.[0]?.total) +
                    parseInt(totalFilledCasesJabalpur?.[0]?.total) +
                    parseInt(totalFilledCasesJaipur?.[0]?.total) +
                    parseInt(totalFilledCasesJammu?.[0]?.total) +
                    parseInt(totalFilledCasesJodhpur?.[0]?.total) +
                    parseInt(totalFilledCasesKolkata?.[0]?.total) +
                    parseInt(totalFilledCasesLucknow?.[0]?.total) +
                    parseInt(totalFilledCasesMumbai?.[0]?.total) +
                    parseInt(totalFilledCasesPatna?.[0]?.total) +
                    parseInt(totalFilledCasesSrinagar?.[0]?.total)}
              </h2>
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ChartHome;
