/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import * as Yup from "yup";
import { Modal, Table, Button } from "react-bootstrap";
import { Formik } from "formik";
import Loader from "../../../../shared/Loader";
import { generateYearList } from "../../../../../Utilities/FuncUtils";
import {
  formattedDate,
  sentenceCase,
  payloadForm,
} from "../../../../../Utilities/FuncUtils";
import {
  useGetExternalDataDetailMutation,
  useGetExternalDataMutation,
} from "../../../../../redux/slices/catApiSlice";
import PartyNameMoreDetails from "./PartyNameMoreDetails";

const CaseNo = ({ schemaId, bench }) => {
  // states for modals
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [
    caseDetailFunc,
    { data: caseNoWise, isLoading: loading, isError: err1 },
  ] = useGetExternalDataMutation();
  const [
    caseDetailFunc2,
    { data: caseNoWiseMoreDetail, isLoading: loading1, isError: err2 },
  ] = useGetExternalDataDetailMutation();

  //---------------- Formik ------------------------//

  // initial Values
  const initialValues = {
    caseTypeId: "",
    caseNo: "",
    caseYear: "",
  };

  // validation
  const validationSchema = Yup.object({
    caseTypeId: Yup.string().required("Required"),
    // caseNo: Yup.string().trim().required("Required"),
    caseNo: Yup.number()
      .typeError("CaseNo must be a number")
      .positive("CaseNo must be a positive number")
      .integer("CaseNo must be an integer")
      .required("CaseNo is required"),
    caseYear: Yup.number().required("Required"),
  });

  // final submit formik form
  const handleSubmit = async (values, { resetForm }) => {
    const payload = {
      casetypeId: values.caseTypeId,
      catschemaId: schemaId,
      caseNo: values.caseNo,
      caseYear: values.caseYear,
    };

    try {
      const data = await caseDetailFunc({
        url: "casedetail_individual_case_no_wise",
        payload: payloadForm(payload),
      }).unwrap();
      // const [diaryNo, diaryYear] =
      //   data && data[0] && data[0].diaryno.split("/");
      // const payload2 = {
      //   catschemaId: schemaId,
      //   dairyNo: diaryNo,
      //   dairyYear: diaryYear,
      // };
      // await caseDetailFunc2({
      //   url: "getAdditionalDetail",
      //   payload: payloadForm(payload2),
      // }).unwrap();
      setIsOpenModal(true);
      resetForm();
    } catch (error) {
      console.error(err1 || err2);
    }
  };
  if (loading || loading1) {
    return <Loader />;
  }

  return (
    <div className="tab-pane container active" id="case_number">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          errors,
          values,
          resetForm,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              {/* field 1 */}
              <div className="form-group row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className=" col-form-label mandatory">
                    Select Case Type:
                  </label>
                  <select
                    className="form-control"
                    id="caseTypeId"
                    name="caseTypeId"
                    onChange={handleChange}
                    value={values.caseTypeId}
                    onBlur={handleBlur}
                  >
                    <option hidden>Select</option>
                    <option value="1">Original Application</option>
                    <option value="2">Transfer Application</option>
                    <option value="3">Misc Application</option>
                    <option value="4">Contempt Petiton</option>
                    <option value="5">Petition for Transfer</option>
                    <option value="6">Review Application</option>
                    <option value="7">Criminal Contempt Petition</option>
                    <option value="8">Oa Obj</option>
                  </select>
                  {touched.caseTypeId && errors.caseTypeId ? (
                    <div className="error">{errors.caseTypeId}</div>
                  ) : null}
                </div>

                {/* field 2 */}

                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className="col-form-label mandatory">
                    Enter Case No:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="caseNo"
                    name="caseNo"
                    onChange={handleChange}
                    value={values.caseNo}
                    onBlur={handleBlur}
                  />
                  {touched.caseNo && errors.caseNo ? (
                    <div className="error">{errors.caseNo}</div>
                  ) : null}
                </div>

                {/* field 3 */}

                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className=" col-form-label mandatory">
                    Enter Case Year:
                  </label>
                  <select
                    type="text"
                    id="caseYear"
                    name="caseYear"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.caseYear}
                  >
                    <option hidden>Select</option>
                    {generateYearList().map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {touched.caseYear && errors.caseYear ? (
                    <div className="error">{errors.caseYear}</div>
                  ) : null}
                </div>

                {/* submit field */}

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3 mt-3">
                  <div className="search_cancel_btn">
                    <button type="submit" className="search">
                      Search
                    </button>
                    <a type="button" className="cancel" onClick={resetForm}>
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </form>
            {/* {loading ? (
              <Loader />
            ) : ( */}
            <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
              <Modal.Header>
                <div>
                  <b> {bench} </b>
                  <br />
                  Case Status - Case No. wise
                </div>

                <Button
                  type="button"
                  className="close"
                  onClick={() => setIsOpenModal(false)}
                >
                  &times;
                </Button>
              </Modal.Header>
              <Modal.Body>
                <div className="table-responsive model-mh">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Applicant vs Respondent</th>
                        <th>Dairy No.</th>
                        <th>Location</th>
                        <th>Case Type</th>
                        <th>Case No.</th>
                        <th>Date of Filing</th>
                        <th>Other Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {caseNoWise && !err1 && !err2 && caseNoWise.length > 0 ? (
                        caseNoWise.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <center>
                                {sentenceCase(item.applicant)} <br />
                                vs <br />
                                {sentenceCase(item.respondent)}
                              </center>
                            </td>
                            <td>{item.diaryno}</td>
                            <td>
                              {item.location.toLowerCase() === "delhi"
                                ? "Principal Bench"
                                : item.location}
                            </td>
                            <td>{item.caseType}</td>
                            <td>{item.caseno}</td>
                            <td>{formattedDate(item.dateoffiling)}</td>
                            <td>
                              <PartyNameMoreDetails
                                item={item.diaryno}
                                schemaId={schemaId}
                              />
                              {/* <a
                                className="more-details"
                                // onClick={() => setIsOpenModal2(true)}
                                target="_blank"
                                href={
                                  caseNoWiseMoreDetail &&
                                  caseNoWiseMoreDetail["More Detail"]
                                }
                              >
                                More&nbsp;Details
                              </a> */}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <>
                          <tr>
                            <td colSpan="7"> No Record Found </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Modal.Body>
            </Modal>
            {/* )} */}
          </>
        )}
      </Formik>
    </div>
  );
};

export default CaseNo;
