import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import Skeleton from "react-loading-skeleton";

const SelectBench = () => {
  const { data: benches, isLoading } = useGetInternalDataQuery(`get-bench`);
  const { languageType } = useSelector((state) => state.catSliceExternal);
  if (isLoading) {
    return <Skeleton count={1} width={80} height={20} />;
  }

  return (
    <div className="selectsec">
      <button className="selectbenchbtn">Select Bench</button>
      <div className="dropdown-content">
        <ul>
          {benches && benches.status ? (
            benches.data
              .map((item) => ({
                name: item.name,
                slug: "/" + item.name.toLowerCase(),
              }))
              .filter((item) => item.name !== "DELHI")
              .map((item, index) => (
                <li key={index}>
                  <NavLink to={item.slug === "/home" ? "/delhi" : item.slug}>
                    {item.name === "HOME"
                      ? "PRINCIPAL BENCH, NEW DELHI"
                      : item.name === "SRINAGAR" && languageType === "hid"
                      ? "श्रीनगर"
                      : item.name === "JAIPUR" && languageType === "hid"
                      ? "जयपुर"
                      : item.name === "ERNAKULAM" && languageType === "hid"
                      ? "एर्नाकुलम"
                      : item.name === "JABALPUR" && languageType === "hid"
                      ? "जबलपुर"
                      : item.name}
                  </NavLink>
                </li>
              ))
          ) : (
            <>No Data Found</>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SelectBench;
