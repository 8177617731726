import React from "react";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import Loader from "../../shared/Loader";
import { currentYear, filterDataByYear } from "../../../Utilities/FuncUtils";

const EmployeeCornerForms = ({ benchId, isArchive }) => {
  const { data: employCorner, isLoading } = useGetInternalDataQuery(
    `get-modules/${benchId}/employee-corner-forms`
  );
  const { archiveData, allData } = filterDataByYear(
    employCorner?.data,
    currentYear
  );
  console.log(employCorner);
  const renderTableRows = (data) => {
    return data?.map((item, index) => (
      <tr key={item.id}>
        <td headers="view-counter-table-column">{index + 1}</td>
        <td headers="view-details-table-column">{item.subject}</td>
        <td headers="view-date-table-column">
          {item.date_of_issue.split("-").reverse().join("-")}
        </td>
        <td headers="view-file-table-column">
          {item.attachment && (
            <>
              <a target="_blank" href={`${item.attachment}`}>
                <i className="fas fa-file-pdf"></i>
              </a>
              <br />
              <small>
                {item.aad_file_size ? `(${item.aad_file_size})` : "(589.12 KB)"}
              </small>
              <br />
            </>
          )}
        </td>
        <td headers="view-file-hindi-table-column">
          {item.aad_file_hindi && (
            <>
              <a target="_blank" href={`${item.aad_file_hindi}`}>
                <i className="fas fa-file-pdf"></i>
              </a>
              <br />
              <small>
                {item.aad_file_hindi_size
                  ? `(${item.aad_file_hindi_size})`
                  : "(589.12 KB)"}
              </small>
              <br />
            </>
          )}
        </td>
      </tr>
    ));
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <tbody>
      {!isArchive ? (
        allData?.length > 0 ? (
          renderTableRows(allData)
        ) : (
          <tr>
            <td colSpan="7">No Archive Data</td>
          </tr>
        )
      ) : allData?.length > 0 ? (
        renderTableRows(allData)
      ) : (
        <tr>
          <td colSpan="7">Content Awaited From CAT</td>
        </tr>
      )}
      {/* {employCorner && employCorner.status && employCorner.data.length > 0 ? (
        employCorner.data.map((item, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{item.subject}</td>
            <td>{handleDate(item.date_of_issue)}</td>
            <td>{handleDate(item.last_date)}</td>
            <td className="text-center">
              <a target="_blank" href={`${item.attachment}`}>
                <i className="fas fa-file-pdf"></i>
              </a>
            </td>
            <td className="text-center">
              {item.aad_file_hindi && (
                <a target="_blank" href={`${item.aad_file_hindi}`}>
                  <i className="fas fa-file-pdf"></i>
                </a>
              )}
            </td>
          </tr>
        ))
      ) : (
        <>
          <tr>
            <td colSpan="5"> No Record Found </td>
          </tr>
        </>
      )} */}
    </tbody>
  );
};

export default EmployeeCornerForms;
