import { configureStore } from "@reduxjs/toolkit";
import catSliceExternal from "../slice/sliceExternal/catSliceExternal";
import footerBottomSlice from "../slice/sliceInternal/footerBottomSlice";
import {
  apiSlice,
  apiSliceExternal,
  apiSliceIp,
  apiSliceExternalChart,
} from "../slices/apiSlice";
import externalPopupSlice from "../slice/sliceInternal/externalPopupSlice";

export const store = configureStore({
  reducer: {
    catSliceExternal,
    footerBottomSlice,
    externalPopupSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [apiSliceExternal.reducerPath]: apiSliceExternal.reducer,
    [apiSliceIp.reducerPath]: apiSliceIp.reducer,
    [apiSliceExternalChart.reducerPath]: apiSliceExternalChart.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(apiSlice.middleware)
      .concat(apiSliceExternal.middleware)
      .concat(apiSliceIp.middleware)
      .concat(apiSliceExternalChart.middleware),

  devTools: true,
});
