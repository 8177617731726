import React from "react";
import DelhiRouting from "./DelhiRouting";
import HeaderDL from "../Delhi/Shared/HeaderDL";
import FooterDL from "../Delhi/Shared/FooterDL";

const DelhiLayout = () => {
  return (
    <>
      <HeaderDL />
      <DelhiRouting />
      <FooterDL />
    </>
  );
};

export default DelhiLayout;
