import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Modal, Table } from "react-bootstrap";
import { Formik } from "formik";
import Loader from "../../../../shared/Loader";
import {
  formattedDate,
  handleDate,
  payloadForm,
  sentenceCase,
} from "../../../../../Utilities/FuncUtils";
import printJS from "print-js";
import { useGetExternalDataMutation } from "../../../../../redux/slices/catApiSlice";

const DateWiseOrderDaily = ({ schemaId, bench }) => {
  const [
    dateWiseDailyOrderFunc,
    { data: dateWiseOrderDateDaily, isLoading, isError },
  ] = useGetExternalDataMutation();

  // states for modals
  const [isOpenModal, setIsOpenModal] = useState(false);

  // fetching data from store

  //---------------- Formik ------------------------//

  // initial Values
  const initialValues = {
    fromDate: "",
    toDate: "",
  };

  // validation
  const validationSchema = Yup.object({
    fromDate: Yup.string().required("Required"),
    toDate: Yup.string().required("Required"),
  });

  // final submit formik form
  const handleSubmit = (values, { resetForm }) => {
    console.log(values);
    const payload = {
      catschemaId: schemaId,
      fromDate: handleDate(values.fromDate),
      toDate: handleDate(values.toDate),
    };
    dateWiseDailyOrderFunc({
      url: "getCatDailyOrderReportDatewise",
      payload: payloadForm(payload),
    });

    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);
    resetForm();
  };

  return (
    <div className="tab-pane container active" id="case_number">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          errors,
          values,
          resetForm,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                {/* field 1 */}
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className=" col-form-label mandatory">
                    From Date: (DD/MM/YYYY)
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="fromDate"
                    name="fromDate"
                    onChange={handleChange}
                    value={values.fromDate}
                    onBlur={handleBlur}
                  />
                  {touched.fromDate && errors.fromDate ? (
                    <div className="error">{errors.fromDate}</div>
                  ) : null}
                </div>

                {/* field 2 */}

                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className=" col-form-label mandatory">
                    To Date: (DD/MM/YYYY)
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="toDate"
                    name="toDate"
                    onChange={handleChange}
                    value={values.toDate}
                    onBlur={handleBlur}
                  />
                  {touched.toDate && errors.toDate ? (
                    <div className="error">{errors.toDate}</div>
                  ) : null}
                </div>

                {/* submit field */}

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div className="search_cancel_btn">
                    <button type="submit" className="search">
                      Search
                    </button>
                    <a type="button" className="cancel" onClick={resetForm}>
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </form>
            {isLoading ? (
              <Loader />
            ) : (
              <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
                <Modal.Header>
                  <div>
                    <div>
                      <b> {bench} </b>
                      <br />
                      Daily Order - Case No wise
                    </div>
                  </div>
                  <div className="button-div">
                    <i
                      className="fas fa-print"
                      onClick={() => printJS("table", "html")}
                    ></i>
                    <i
                      className="fas fa-window-close"
                      onClick={() => setIsOpenModal(false)}
                    ></i>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div className="table-responsive model-mh" id="table">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Applicant vs Respondent</th>
                          <th>Case No.</th>
                          <th>Date of Order</th>
                          <th>Daily Order(s)</th>
                          {/* <th>Dairy No.</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {dateWiseOrderDateDaily &&
                        !isError &&
                        dateWiseOrderDateDaily.length > 0 ? (
                          dateWiseOrderDateDaily.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {sentenceCase(item.applicantName)} <br />
                                vs <br />
                                {sentenceCase(item.respondentName)}
                              </td>
                              <td>{item.applicantno}</td>
                              <td>{formattedDate(item.dateoforder)}</td>
                              <td className="text-center">
                                <a
                                  target="_blank"
                                  href={`${item.dailyOrderPdf}`}
                                >
                                  <i className="fas fa-file-pdf"></i>
                                </a>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5"> No Record Found </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default DateWiseOrderDaily;
