/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import InnerBanner from "../InnerBanner";
import { useParams } from "react-router-dom";
import { useGetInternalDataQuery } from "../../../../redux/slices/catApiSlice";
import Viewer from "react-viewer";
import GalleryBreadCrumb from "./GalleryBreadCrumb";
import Loader from "../../../shared/Loader";

const GalleryDetail = () => {
  const [visible, setVisible] = React.useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { id } = useParams();
  const { data: galleryDetail, isLoading } = useGetInternalDataQuery(
    `get-multi-gallery/${id}`
  );
  console.log(galleryDetail);
  const viewerHandler = (arr) => {
    let temp = {};
    if (galleryDetail.status)
      temp = arr.map((item) => {
        return Object.create({
          title: item.title,
          src: item.file_path,
        });
      });
    return temp;
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <InnerBanner title={"Gallery Detail"} />
      <GalleryBreadCrumb />
      <div className="gallery about-content my-4">
        <div className="container">
          <h6>{"Gallery"}</h6>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 gallerysec ">
              {galleryDetail &&
              galleryDetail.data &&
              galleryDetail.data.length > 0 ? (
                galleryDetail.data.map((item, index) => (
                  <a
                    className="masonry-item"
                    key={index}
                    onClick={() => {
                      setVisible(true);
                      setActiveIndex(index);
                    }}
                  >
                    <img
                      src={item.file_path}
                      width="550"
                      height="314"
                      alt="Art and Culture"
                      loading="lazy"
                      typeof="foaf:Image"
                      className="img-responsive"
                    />
                  </a>
                ))
              ) : (
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 pt-4">
                      <div class="withouthover">
                        <a class="d-block w-100 position-relative">
                          <div class="headingofbox text-center">
                            <div class="iconphoto">
                              <span>
                                <img src="images/photo-library.png" alt="" />
                              </span>
                            </div>
                            <div class="heading-center small">
                              Multiple Images need to update
                            </div>
                          </div>
                          <div class="box-overlay"></div>
                        </a>
                      </div>
                      <div class="box-menu">
                        <img src="images/phototab.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Viewer
                visible={visible}
                onClose={() => {
                  setVisible(false);
                }}
                onMaskClick={() => {
                  setVisible(false);
                }}
                images={
                  galleryDetail &&
                  galleryDetail.data &&
                  viewerHandler(galleryDetail.data)
                }
                activeIndex={activeIndex}
                rotatable={false}
                zoomable={false}
                scalable={false}
                attribute={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryDetail;
