import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vacancies: {},
  forms: {},
  calendar: {},
  tourProgramme: {},
  employCorner: {},
  pagesWithTableContent: {},
  loading: false,
  error: "",
};

export const catSliceFooterTopMenu = createSlice({
  name: "catSliceFooterTopMenu",
  initialState,
  reducers: {},
});

export default catSliceFooterTopMenu.reducer;
