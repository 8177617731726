import React, { useState } from "react";
import InnerBanner from "../../Home/InnerBanner";
import BredCrumb from "../../Home/BredCrumb";
import CaseNo from "./Tabs/CaseNo";
import DiaryNo from "./Tabs/DiaryNo";
import PartyName from "./Tabs/PartyName";
import AdvocateName from "./Tabs/AdvocateName";

const CaseStatusParent = ({ benchDetails }) => {
  const { id, name, navigateTo } = benchDetails;
  const [relatedLink, setRelatedLink] = useState("Case-No");

  return (
    <>
      <InnerBanner title="Case Status" />
      <BredCrumb title="Case Status" navigateTo={navigateTo} /> 
      <div className="common-bg mb-3">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="case-details  wow fadeInUp">
                <ul className="nav inner toptab-one">
                  <li>
                    <a
                      className={
                        relatedLink === "Case-No"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="pill"
                      onClick={() => setRelatedLink("Case-No")}
                    >
                      Case No
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        relatedLink === "Diary-No"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="pill"
                      onClick={() => setRelatedLink("Diary-No")}
                    >
                      Diary No
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        relatedLink === "Party-NameWise"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="pill"
                      onClick={() => setRelatedLink("Party-NameWise")}
                    >
                      Party Name
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        relatedLink === "Advocate-Name"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="pill"
                      onClick={() => setRelatedLink("Advocate-Name")}
                    >
                      Advocate Name
                    </a>
                  </li>
                </ul>
                <div className="tab-content-wraper">
                  <div className="tab-pane container active" id="case_details">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="tab-content ">
                          {relatedLink === "Case-No" ? (
                            <CaseNo schemaId={id} bench={name} />
                          ) : relatedLink === "Diary-No" ? (
                            <DiaryNo schemaId={id} bench={name} />
                          ) : relatedLink === "Party-NameWise" ? (
                            <PartyName schemaId={id} bench={name} />
                          ) : (
                            <AdvocateName schemaId={id} bench={name} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStatusParent;
