import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const BredCrumb = ({ title, navigateTo }) => {
  const navigate = useNavigate();
  if (typeof title !== "string") {
    return null; // or handle the case accordingly
  }
  return (
    <div className="breadcrumb-bg">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-8 col-lg-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={navigateTo ? navigateTo : "*"}>Home</Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  style={{ textTransform: "capitalize" }}
                >
                  {title.split("-").join(" ")}
                </li>
              </ol>
            </nav>
          </div>
          {/* <div className="col-12 col-sm-12 col-md-4 col-lg-4 text-right">
            <a
              type="button"
              className="cancel"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default BredCrumb;
