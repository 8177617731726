import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Modal, Table } from "react-bootstrap";
import { Formik } from "formik";
import { setSearchedName } from "../../../../../redux/slice/sliceExternal/catSliceExternal";
import Loader from "../../../../shared/Loader";
import {
  formattedDate,
  payloadForm,
  sentenceCase,
} from "../../../../../Utilities/FuncUtils";
import printJS from "print-js";
import { useGetExternalDataMutation } from "../../../../../redux/slices/catApiSlice";

const PartyNameDaily = ({ schemaId, bench }) => {
  // states for modals
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();

  const [
    partyNameWiseDailyOrderFunc,
    { data: partyNameWiseDataDaily, isLoading, isError },
  ] = useGetExternalDataMutation();

  const { searchedName } = useSelector((state) => state.catSliceExternal);

  //---------------- Formik ------------------------//

  // initial Values
  const initialValues = {
    partyType: "",
    partyName: "",
  };

  // validation
  const validationSchema = Yup.object({
    partyType: Yup.string()
      .trim()
      .required("Required")
      .typeError("Enter Correct Party Name"),
    partyName: Yup.string().required("Required"),
  });

  // final submit formik form
  const handleSubmit = (values, { resetForm }, errors) => {
    const payload = {
      catschemaId: schemaId,
      partyType: values.partyType,
      partyName: values.partyName,
    };

    partyNameWiseDailyOrderFunc({
      url: "getCatDailyOrderReportPartywise",
      payload: payloadForm(payload),
    });

    dispatch(setSearchedName(values.partyName));

    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);
    resetForm();
  };

  return (
    <div className="tab-pane container active" id="case_number">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          errors,
          values,
          resetForm,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              {/* field 1 */}
              <div className="form-group row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className="col-form-label mandatory">
                    Select Party:
                  </label>
                  <select
                    type="text"
                    className="form-control"
                    id="partyType"
                    name="partyType"
                    onChange={handleChange}
                    value={values.partyType}
                    onBlur={handleBlur}
                  >
                    <option hidden>Select Party</option>
                    {/* <option value="1">Both</option> */}
                    <option value="2">Petitioner</option>
                    <option value="3">Respondent</option>
                  </select>
                  {touched.partyType && errors.partyType ? (
                    <div className="error">{errors.partyType}</div>
                  ) : null}
                </div>

                {/* field 2 */}

                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className=" col-form-label mandatory">
                    Enter Party Name:
                  </label>
                  <input
                    type="text"
                    id="partyName"
                    name="partyName"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.partyName}
                  />
                  {touched.partyName && errors.partyName ? (
                    <div className="error">{errors.partyName}</div>
                  ) : null}
                </div>

                {/* submit field */}

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div className="search_cancel_btn">
                    <button type="submit" className="search">
                      Search
                    </button>
                    <a type="button" className="cancel" onClick={resetForm}>
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </form>
            {isLoading ? (
              <Loader />
            ) : (
              <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
                <Modal.Header>
                  <div>
                    <div>
                      <b> {bench} </b>
                      <br />
                      Daily Order - Case No wise
                    </div>
                  </div>
                  <div className="button-div">
                    <i
                      className="fas fa-print"
                      onClick={() => printJS("table", "html")}
                    ></i>
                    <i
                      className="fas fa-window-close"
                      onClick={() => setIsOpenModal(false)}
                    ></i>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div className="table-responsive model-mh" id="table">
                    <div className="searchedName">
                      Search Results for "{searchedName}"
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Applicant vs Respondent</th>
                          <th>Dairy No.</th>
                          <th>Case No.</th>
                          <th>Date of Order</th>
                          <th>Daily Order(s)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {partyNameWiseDataDaily &&
                        partyNameWiseDataDaily.length > 0 &&
                        !isError ? (
                          partyNameWiseDataDaily.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {sentenceCase(item.applicantName)} <br />
                                vs <br />
                                {sentenceCase(item.respondentName)}
                              </td>
                              <td>{item.diaryno}</td>
                              <td>{item.applicantno}</td>
                              <td>{formattedDate(item.dateoforder)}</td>
                              <td className="text-center">
                                <a
                                  target="_blank"
                                  href={`${item.dailyOrderPdf}`}
                                >
                                  <i className="fas fa-file-pdf"></i>
                                </a>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <>
                            <tr>
                              <td colSpan="6"> No Record Found </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default PartyNameDaily;
