import React, { useState } from "react";
import CaseDetailHomeParent from "./Tabs/CaseDetailHomeParent";
import CauseListHomeParent from "./Tabs/CauseListHomeParent";
import DailyOrderHomeParent from "./Tabs/DailyOrderHomeParent";
import OralAndFinalOrderHomeParent from "./Tabs/OralAndFinalOrderHomeParent";

const CaseCauseDailyFinalOrderHome = ({ schemaId, bench }) => {
  const [relatedLink, setRelatedLink] = useState("case-detail");
  return (
    <div className="col-12 col-sm-12 col-md-8 col-lg-8">
      <div className="case-details  wow fadeInUp">
        <ul className="nav toptab-one">
          <li className="nav-item">
            <a
              className={
                relatedLink === "case-detail" ? "nav-link active" : "nav-link"
              }
              onClick={() => setRelatedLink("case-detail")}
            >
              Case Details
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                relatedLink === "cause-list" ? "nav-link active" : "nav-link"
              }
              onClick={() => setRelatedLink("cause-list")}
            >
              Cause List
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                relatedLink === "daily-order" ? "nav-link active" : "nav-link"
              }
              onClick={() => setRelatedLink("daily-order")}
            >
              Daily Order
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                relatedLink === "oral-final" ? "nav-link active" : "nav-link"
              }
              // data-toggle="pill"
              onClick={() => setRelatedLink("oral-final")}
            >
              Oral & Final Order
            </a>
          </li>
        </ul>
        <div className="tab-content">
          {relatedLink === "case-detail" ? (
            <CaseDetailHomeParent schemaId={schemaId} bench={bench} />
          ) : relatedLink === "cause-list" ? (
            <CauseListHomeParent schemaId={schemaId} bench={bench} />
          ) : relatedLink === "daily-order" ? (
            <DailyOrderHomeParent schemaId={schemaId} bench={bench} />
          ) : (
            <OralAndFinalOrderHomeParent schemaId={schemaId} bench={bench} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CaseCauseDailyFinalOrderHome;
