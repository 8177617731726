import React, { useState } from "react";
import InnerBanner from "../../Home/InnerBanner";
import BredCrumb from "../../Home/BredCrumb";
import CaseNoFinal from "./Tabs/CaseNoFinal";
import DiaryNoFinal from "./Tabs/DiaryNoFinal";
import DateWiseOrderFinal from "./Tabs/DateWiseOrderFinal";
import MemberWiseOrderFinal from "./Tabs/MemberWiseOrderFinal";

const OralAndFinalOrderParent = ({ benchDetails }) => {
  const [relatedLink, setRelatedLink] = useState("Case-No");
  const { id, name, navigateTo } = benchDetails;
  return (
    <>
      <InnerBanner title="Oral and Final order" />
      <BredCrumb title="Oral and Final order" navigateTo={navigateTo} />
      <div className="common-bg mb-3">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="case-details  wow fadeInUp">
                <ul className="nav inner toptab-one">
                  <li>
                    <a
                      className={
                        relatedLink === "Case-No"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="pill"
                      onClick={() => setRelatedLink("Case-No")}
                    >
                      Case No
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        relatedLink === "Diary-No"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="pill"
                      onClick={() => setRelatedLink("Diary-No")}
                    >
                      Diary No
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        relatedLink === "datewise-order"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="pill"
                      onClick={() => setRelatedLink("datewise-order")}
                    >
                      Datewise Order
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        relatedLink === "Party-Name"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="pill"
                      onClick={() => setRelatedLink("Party-Name")}
                    >
                      Memberwise Order
                    </a>
                  </li>
                </ul>
                <div className="tab-content-wraper">
                  <div className="tab-pane container active" id="case_details">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="tab-content ">
                          {relatedLink === "Case-No" ? (
                            <CaseNoFinal schemaId={id} bench={name} />
                          ) : relatedLink === "Diary-No" ? (
                            <DiaryNoFinal schemaId={id} bench={name} />
                          ) : relatedLink === "datewise-order" ? (
                            <DateWiseOrderFinal schemaId={id} bench={name} />
                          ) : (
                            <MemberWiseOrderFinal schemaId={id} bench={name} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OralAndFinalOrderParent;
