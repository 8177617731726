import React  from "react";
import { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import Loader from "../../shared/Loader";
import {
  useTodayFiledCasesQuery,
  useTotalDisposedCasesQuery,
  useTotalFilledCasesQuery,
  useTotalPendingCasesQuery,
} from "../../../redux/slices/catApiSlice";
import { payloadForm } from "../../../Utilities/FuncUtils";

ChartJS.register(ArcElement, Tooltip, Legend);

const Chart = ({ schemaId }) => {
  const payload = {
    catschemaId: schemaId,
  };

  const { data: todayCaseFiled, isLoading: todayCaseFiledLoading, refetch: refetch1, } = useTodayFiledCasesQuery(payloadForm(payload));
   const { data: totalPendingCases, isLoading: totalPendingCasesLoading, refetch: refetch2, } = useTotalPendingCasesQuery(payloadForm(payload)); 
  const { data: totalDisposedCases, isLoading: totalDisposedCasesLoading, refetch: refetch3, } = useTotalDisposedCasesQuery(payloadForm(payload));
   const { data: totalFilledCases, isLoading: totalFilledCasesLoading, refetch: refetch4, } = useTotalFilledCasesQuery(payloadForm(payload)); 
  
  useEffect(() => { refetch1(); refetch2(); refetch3(); refetch4(); }, []);

  const seriesData = [
    totalPendingCases && parseInt(totalPendingCases[0].pending),
    totalDisposedCases && parseInt(totalDisposedCases[0].disposal),
    todayCaseFiled && parseInt(todayCaseFiled[0].filed),
    totalFilledCases && parseInt(totalFilledCases[0].total),
  ];
  const total = seriesData && seriesData.reduce((a, v) => a + v);
  const inPercent =
    seriesData && seriesData.map((v) => Math.max((v / total) * 100, 3));

  const data = {
    labels: [
      "Total no of Pending Cases",
      "Total no of Disposed Cases",
      "Today's Filed Cases",
      "Total no of Filed Cases",
      ,
    ],
    datasets: [
      {
        data: inPercent,
        backgroundColor: [
          "rgba(39, 170, 200, 1)",
          "rgba(254, 173, 42, 1)",
          "rgba(117, 203, 52, 1)",
          "rgba(13, 57, 120, 1)",
        ],
        options: [
          {
            indexAxis: "y",
            scales: {
              y: {
                display: true,
              },
              x: {
                max: 100,
                min: 0,
                display: false,
              },
            },
          },
        ],
        hoverOffset: 10,
        weight: 50,
        radius: "80%",
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        position: "right",
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem, data) => {
            if (tooltipItem.dataIndex === 3) {
              return `Total Filed Cases : ${totalFilledCases[0].total}`;
            } else if (tooltipItem.dataIndex === 2) {
              return `Today's Filed Cases : ${todayCaseFiled[0].filed}`;
            } else if (tooltipItem.dataIndex === 1) {
              return `Total Disposed Cases : ${totalDisposedCases[0].disposal}`;
            } else {
              return `Total Pending Cases: ${totalPendingCases[0].pending}`;
            }
          },
        },
        // xAlign: "center",
        // yAlign: "bottom",
      },
    },
  };

  if (
    todayCaseFiledLoading ||
    totalFilledCasesLoading ||
    totalDisposedCasesLoading ||
    totalPendingCasesLoading
  ) {
    return <Loader />;
  }

  return (
    <>
      <div className="chart-sec wow fadeInRight">
        <div className="chart-wrap">
          <div className="txt-1">
            Total no of <br /> Filed Cases
          </div>
          <div className="txt-2">
            Total no <br /> of Pending Cases
          </div>
          <Doughnut data={data} options={options} />
          <div className="txt-3">Today's Filed Cases</div>
          <div className="txt-4">
            Total No of <br /> Disposed Cases
          </div>
        </div>
        <ul className="counter-sec">
          <li>
            <span href="#">
              <img src="images/total-1.png" />
              <h5>Total No of Pending Cases</h5>
              <h2 className="count">
                {totalPendingCases && totalPendingCases[0].pending}
              </h2>
            </span>
          </li>
          <li>
            <span>
              <img src="images/total-2.png" />
              <h5>Total no of Disposed Cases</h5>
              <h2 className="count">
                {totalDisposedCases && totalDisposedCases[0].disposal}
              </h2>
            </span>
          </li>
          <li>
            <span>
              <img src="images/total-3.png" />
              <h5>Today's Filed Cases</h5>
              <h2 className="count">
                {todayCaseFiled && todayCaseFiled[0].filed}
              </h2>
            </span>
          </li>
          <li>
            <span>
              <img src="images/total-4.png" />
              <h5>Total no of Filed Cases</h5>
              <h2 className="count">
                {totalFilledCases && totalFilledCases[0].total}
              </h2>
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Chart;
