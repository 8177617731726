/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Events from "./Tabs/Events";
import Photos from "./Tabs/Photos";
import Videos from "./Tabs/Videos";
import { Link } from "react-router-dom";

const PhotoVideoEvent = ({ galleryId }) => {
  const [relatedLink, setRelatedLink] = useState("Photo");
  const [categoryId, setCategoryId] = useState(2);

  return (
    <div className="col-12 col-sm-12 col-md-6 col-lg-6 custom_pr_0">
      <div className="photo_video-event-sec  wow fadeInUp">
        <ul className="nav gallery-toptab-one">
          <li className="nav-item">
            <a
              className={
                relatedLink === "Photo" ? "nav-link active" : "nav-link"
              }
              onClick={() => {
                setRelatedLink("Photo");
                setCategoryId(2);
              }}
            >
              Photo
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                relatedLink === "Video" ? "nav-link active" : "nav-link"
              }
              onClick={() => {
                setRelatedLink("Video");
                setCategoryId(3);
              }}
            >
              Video
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                relatedLink === "Event" ? "nav-link active" : "nav-link"
              }
              onClick={() => {
                setRelatedLink("Event");
                setCategoryId(4);
              }}
            >
              Event
            </a>
          </li>
        </ul>
        <div className="tab-content">
          {relatedLink === "Photo" ? (
            <Photos galleryId={galleryId} categoryId={categoryId} />
          ) : relatedLink === "Video" ? (
            <Videos galleryId={galleryId} categoryId={categoryId} />
          ) : (
            <Events galleryId={galleryId} categoryId={categoryId} />
          )}
        </div>
      </div>
      <div className="text-center">
        {relatedLink === "Photo" ? (
          <Link to="photo-gallery" className="view_all">
            View All
          </Link>
        ) : relatedLink === "Video" ? (
          <Link to="video-gallery" className="view_all">
            View All
          </Link>
        ) : (
          <Link to="event-gallery" className="view_all">
            View All
          </Link>
        )}
      </div>
    </div>
  );
};

export default PhotoVideoEvent;
