import React from "react";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import Loader from "../../shared/Loader";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setChairmanType } from "../../../redux/slice/sliceExternal/catSliceExternal";

const ChairPerson = ({ memberId }) => {
  const dispatch = useDispatch();
  const { data: hod, isLoading } = useGetInternalDataQuery(
    `get-hod/${memberId}`
  );

  function htmlToText(htmlString) {
    const el = document.createElement("div");
    el.innerHTML = htmlString;
    return el.textContent;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
        <div className="chairman-sec main-chairman">
          {hod && hod.status && hod.data[0] ? (
            <React.Fragment>
              <img src={hod.data[0].image} />
              <h3>{hod.data[0].name}</h3>
              <h5>{hod.data[0].designation}</h5>
              <p>{htmlToText(hod.data[0].description).substring(0, 100)}...</p>
              <Link
                to="read-more-chairman"
                onClick={() => dispatch(setChairmanType("0"))}
              >
                READ MORE
              </Link>
            </React.Fragment>
          ) : (
            <>No Data Found</>
          )}
        </div>
      </div>
    </>
  );
};

export default ChairPerson;
