import React, { useState } from "react";
import * as Yup from "yup";
import { Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import Loader from "../../../../shared/Loader";
import { handleDate, payloadForm } from "../../../../../Utilities/FuncUtils";
import { useGetExternalDataMutation } from "../../../../../redux/slices/catApiSlice";

const CaseProceedingReport = ({ schemaId, bench }) => {
  // states for modals
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [
    caseProceedingReportFunc,
    { data: tentativeCauseList, isLoading, isError },
  ] = useGetExternalDataMutation();

  //---------------- Formik ------------------------//

  // initial Values
  const initialValues = {
    date: "",
    courtName: "",
    courtNo: "",
  };

  // validation
  const validationSchema = Yup.object({
    date: Yup.string().required("Required"),
    courtName: Yup.string().required("Required"),
    courtNo: Yup.string().required("Required"),
  });

  // final submit formik form
  const handleSubmit = (values, { resetForm }) => {
    const payload = {
      catschemaId: schemaId,
      causeListDate: handleDate(values.date),
      courtNameId: values.courtName,
      courtNo: values.courtNo,
    };

    caseProceedingReportFunc({
      url: "getTentativeCauselist",
      payload: payloadForm(payload),
    });

    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);
    resetForm();
  };

  return (
    <div className="tab-pane container active" id="case_number">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          errors,
          values,
          resetForm,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                {/* field 1 */}
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className=" col-form-label mandatory">
                    Date: (DD/MM/YYYY)
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="date"
                    name="date"
                    onChange={handleChange}
                    value={values.date}
                    onBlur={handleBlur}
                  />
                  {touched.date && errors.date ? (
                    <div className="error">{errors.date}</div>
                  ) : null}
                </div>

                {/* field 2 */}
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className=" col-form-label mandatory">
                    Court Name
                  </label>
                  <select
                    className="form-control"
                    id="courtName"
                    name="courtName"
                    onChange={handleChange}
                    value={values.courtName}
                    onBlur={handleBlur}
                  >
                    <option value="">Select Court Name</option>
                    <option value="6">Larger Bench</option>
                    <option value="3">Full Bench</option>
                    <option value="2">Division Bench</option>
                    <option value="1">Single Bench</option>
                    <option value="4">Registrar</option>
                    <option value="5">Chamber</option>
                    <option value="7">Notification Cause List</option>
                  </select>
                  {touched.courtName && errors.courtName ? (
                    <div className="error">{errors.courtName}</div>
                  ) : null}
                </div>

                {/* field 3 */}
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className=" col-form-label mandatory">Court No.</label>
                  <select
                    className="form-control"
                    id="courtNo"
                    name="courtNo"
                    onChange={handleChange}
                    value={values.courtNo}
                    onBlur={handleBlur}
                  >
                    <option value="">Select Court No</option>
                    <option value="1@51285">1</option>
                    <option value="2@50279">2</option>
                  </select>
                  {touched.courtNo && errors.courtNo ? (
                    <div className="error">{errors.courtName}</div>
                  ) : null}
                </div>

                {/* submit field */}
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3 mt-3">
                  <div className="search_cancel_btn">
                    <button type="submit" className="search">
                      Search
                    </button>
                    <a type="button" className="cancel" onClick={resetForm}>
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </form>
            {isLoading ? (
              <Loader />
            ) : (
              <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
                <Modal.Header>
                  <div>
                    <b> {bench} </b>
                    <br />
                    Tentative Cause List Report
                  </div>

                  <Button
                    type="button"
                    className="close"
                    onClick={() => setIsOpenModal(false)}
                  >
                    &times;
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  {tentativeCauseList &&
                  tentativeCauseList !== "" &&
                  !isError ? (
                    <div className="model-mh">
                      <div className="text-center pt-3 pb-3">
                        <>
                          <a
                            className="btn search mr-2"
                            target="_blank"
                            href={tentativeCauseList.TentCauseListLink}
                          >
                            {`Print Tentative Cause List Report`}
                          </a>
                        </>
                      </div>
                    </div>
                  ) : (
                    <h6>Enter valid details</h6>
                  )}
                </Modal.Body>
              </Modal>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default CaseProceedingReport;
