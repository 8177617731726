import React, { useState, useEffect } from "react";

import BredCrumb from "../Home/BredCrumb";
import InnerBanner from "../Home/InnerBanner";
import VacanciesChild from "./VacanciesChild";
import VacanciesReport from "./VacanciesReport";
import { useTitle } from "../../../Utilities/pageTitle";

const Vacancies = ({ benchId, navigateTo }) => {
  useTitle("Vacancies");
  const [relatedLink, setRelatedLink] = useState("vacancies");
  const [isArchive, setIsArchive] = useState(false);

  useEffect(() => {
    return () => {
      setIsArchive(false);
    };
  }, [relatedLink]);

  return (
    <div>
      <InnerBanner title={"vacancies "} />
      <BredCrumb title={"Vacancies "} navigateTo={navigateTo} />
      <div className="common-bg">
        <div className="container">
          <div className="row mt-2">
            <div className="col-12 col-sm-12 col-md-12 col-lg-9">
              <div className="about-content">
                <h6>Vacancies</h6>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
              <a
                type="button"
                className="cancel ml-auto"
                onClick={() => setIsArchive(!isArchive)}
              >
                {isArchive ? "Back to Vacancies" : "Go to Archives"}
              </a>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="case-details wow fadeInUp">
              <ul className="nav toptab-one">
                <li className="nav-item">
                  <a
                    className={
                      relatedLink === "vacancies"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={() => setRelatedLink("vacancies")}
                  >
                    Vacancies
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      relatedLink === "vacanciesReport"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={() => setRelatedLink("vacanciesReport")}
                  >
                    Vacancies Reported to SSC
                  </a>
                </li>
              </ul>
              <div className="tab-content emp-tab-content">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="table-responsive emp-corner">
                      {relatedLink === "vacancies" ? (
                        <VacanciesChild
                          benchId={benchId}
                          isArchive={isArchive}
                        />
                      ) : (
                        <VacanciesReport
                          benchId={benchId}
                          isArchive={isArchive}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vacancies;
