import React, { useState } from "react";
import * as Yup from "yup";
import { Modal, Table, Button } from "react-bootstrap";
import { Formik } from "formik";
import { generateYearList } from "../../../../../Utilities/FuncUtils";
import Loader from "../../../../shared/Loader";
import {
  formattedDate,
  payloadForm,
  sentenceCase,
} from "../../../../../Utilities/FuncUtils";
import { useGetExternalDataMutation } from "../../../../../redux/slices/catApiSlice";

const DiaryNoFinal = ({ schemaId, bench }) => {
  const [finalOrderDiaryNoFunc, { data: diaryNoFinal, isLoading, isError }] =
    useGetExternalDataMutation();
  // states for modals
  const [isOpenModal, setIsOpenModal] = useState(false);

  //---------------- Formik ------------------------//

  // initial Values
  const initialValues = {
    diaryNo: "",
    diaryYear: "",
  };

  // validation
  const validationSchema = Yup.object({
    diaryNo: Yup.number()
      .typeError("Diary No must be a number")
      .positive("Diary No must be a positive number")
      .integer("Diary No must be an integer")
      .required("Diary No is required"),
    diaryYear: Yup.string().required("Required"),
  });

  // final submit formik form
  const handleSubmit = (values, { resetForm }) => {
    const payload = {
      catschemaId: schemaId,
      diaryNo: values.diaryNo,
      diaryYear: values.diaryYear,
    };
    console.log(payload);

    finalOrderDiaryNoFunc({
      url: "getCatFinalOrderReportDiaryNo",
      payload: payloadForm(payload),
    });

    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);
    resetForm();
  };

  return (
    <div className="tab-pane container active" id="case_number">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          errors,
          values,
          resetForm,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              {/* field 1 */}
              <div className="form-group row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className="col-form-label mandatory">
                    Enter Diary No:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="diaryNo"
                    name="diaryNo"
                    onChange={handleChange}
                    value={values.diaryNo}
                    onBlur={handleBlur}
                  />
                  {touched.diaryNo && errors.diaryNo ? (
                    <div className="error">{errors.diaryNo}</div>
                  ) : null}
                </div>

                {/* field 2 */}

                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className=" col-form-label mandatory">
                    Enter Diary Year:
                  </label>
                  <select
                    type="text"
                    id="diaryYear"
                    name="diaryYear"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.caseYear}
                  >
                    <option hidden>Select</option>
                    {generateYearList().map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                  {touched.caseYear && errors.caseYear ? (
                    <div className="error">{errors.caseYear}</div>
                  ) : null}
                </div>
                {/* submit field */}

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3 mt-3">
                  <div className="search_cancel_btn">
                    <button type="submit" className="search">
                      Search
                    </button>
                    <a type="button" className="cancel" onClick={resetForm}>
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </form>
            {isLoading ? (
              <Loader />
            ) : (
              <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
                <Modal.Header>
                  <div>
                    <b> {bench} </b>
                    <br />
                    Diary order - Diary No wise
                  </div>

                  <Button
                    type="button"
                    className="close"
                    onClick={() => setIsOpenModal(false)}
                  >
                    &times;
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <div className="table-responsive model-mh">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>Applicant vs Respondent</th>
                          <th>Applicant No.</th>
                          <th>Dairy No.</th>
                          <th>Date of Order</th>
                          <th>Diary Order</th>
                        </tr>
                      </thead>
                      <tbody>
                        {diaryNoFinal && diaryNoFinal.length > 0 && !isError ? (
                          diaryNoFinal.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {sentenceCase(item.applicantName)} <br />
                                vs <br />
                                {sentenceCase(item.respondentName)}
                              </td>
                              <td>{item.applicantno}</td>
                              <td>{item.diaryno}</td>

                              <td>{formattedDate(item.dateoffiling)}</td>
                              <td>
                                <a
                                  target="_blank"
                                  href={`${item.dailyOrderPdf}`}
                                >
                                  <i className="fas fa-file-pdf"></i>
                                </a>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <>
                            <tr>
                              <td colSpan="6"> No Record Found </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default DiaryNoFinal;
