import React from "react";
import CauseList from "../../../../../../frontend/containers/CaseManagementServices/CauseList/Tabs/CauseList";

const CauseListHomeParent = ({ schemaId, bench }) => {
  return (
    <div className="tab-pane container active" id="case_details">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4">
          <ul className="nav toptab-two h-100">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="pill">
                Cause List
              </a>
            </li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 p-0">
          <div className="tab-content">
            <CauseList schemaId={schemaId} bench={bench} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CauseListHomeParent;
