import { useEffect } from "react";

const setPageTitle = (title) => {
  document.title = title;
};

export const useTitle = (title) => {
  useEffect(() => {
    setPageTitle(`CAT - ${title}`);

    return () => {
      setPageTitle("");
    };
  }, [title]);
};
