import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Modal, Table, Button } from "react-bootstrap";
import { Formik } from "formik";
import printJS from "print-js";
import { setSearchedName } from "../../../../../redux/slice/sliceExternal/catSliceExternal";
import Loader from "../../../../shared/Loader";
import PartyNameMoreDetails from "./PartyNameMoreDetails";
import { useGetExternalDataMutation } from "../../../../../redux/slices/catApiSlice";
import { payloadForm } from "../../../../../Utilities/FuncUtils";

const PartyName = ({ schemaId, bench }) => {
  const dispatch = useDispatch();
  const [
    partyNameFunc,
    {
      data: partyNameWiseData,
      isLoading: loading,
      error: err1,
      isError: isError1,
    },
  ] = useGetExternalDataMutation();

  // states for modals
  const [isOpenModal, setIsOpenModal] = useState(false);

  // fetching data from store
  const { searchedName } = useSelector((state) => state.catSliceExternal);

  //---------------- Formik ------------------------//

  // initial Values
  const initialValues = {
    partyType: "",
    partyName: "",
  };

  // validation
  const validationSchema = Yup.object({
    partyType: Yup.string().required("Required"),
    partyName: Yup.string()
      .required("Required")
      .typeError("Enter Correct Party name"),
  });

  // final submit formik form
  const handleSubmit = (values, { resetForm }) => {
    setSearchedName(values.partyName);
    dispatch(setSearchedName(values.partyName));
    const payload = {
      catschemaId: schemaId,
      partyType: values.partyType,
      partyName: values.partyName.toLowerCase(),
    };
    // dispatching actions for fetching diary wise data
    partyNameFunc({
      url: "casedetail_party_name_wise",
      payload: payloadForm(payload),
    });
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);
    resetForm();
  };

  // date formatter
  const formattedDate = (date) => {
    if (date) {
      const [dd, mm, yyyy] = date.split("/");
      return [
        dd.length === 1 ? `0${dd}` : dd,
        mm.length === 1 ? `0${mm}` : mm,
        yyyy,
      ].join("/");
    }
  };
  // const sentenceCase = (str) =>
  //   str
  //     .split(" ")
  //     .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
  //     .join(" ");

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="tab-pane container active" id="case_number">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          errors,
          values,
          resetForm,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              {/* field 1 */}
              <div className="form-group row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className="col-form-label mandatory">
                    Select Party:
                  </label>
                  <select
                    type="text"
                    className="form-control"
                    id="partyType"
                    name="partyType"
                    onChange={handleChange}
                    value={values.partyType}
                    onBlur={handleBlur}
                  >
                    <option hidden>Select</option>
                    <option value="1">Applicant</option>
                    <option value="2">Respondent</option>
                    <option value="3">Both</option>
                  </select>
                  {touched.partyType && errors.partyType ? (
                    <div className="error">{errors.partyType}</div>
                  ) : null}
                </div>

                {/* field 2 */}

                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className="col-form-label mandatory">
                    Enter Party Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="partyName"
                    name="partyName"
                    onChange={handleChange}
                    value={values.partyName}
                    onBlur={handleBlur}
                  />
                  {touched.partyName && errors.partyName ? (
                    <div className="error">{errors.partyName}</div>
                  ) : null}
                </div>

                {/* submit field */}

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div className="search_cancel_btn">
                    <button type="submit" className="search">
                      Search
                    </button>
                    <a type="button" className="cancel" onClick={resetForm}>
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </form>

            <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
              <Modal.Header>
                <div>
                  <div>
                    <b> {bench} </b>
                    <br />
                    Case Status - Party Name wise
                  </div>
                </div>
                <div className="button-div">
                  <i
                    className="fas fa-print"
                    onClick={() => printJS("table", "html")}
                  ></i>
                  <i
                    className="fas fa-window-close"
                    onClick={() => setIsOpenModal(false)}
                  ></i>
                </div>
              </Modal.Header>
              <Modal.Body>
                <div className="table-responsive model-mh" id="table">
                  <div className="searchedName">
                    Search Results for "{searchedName}"
                  </div>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>Applicant vs Respondent</th>
                        <th>Case No.</th>
                        <th>Case Type</th>
                        <th>Dairy No.</th>
                        <th>Date of Filing</th>
                        <th>Stage</th>
                        <th>Other Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {partyNameWiseData &&
                      !isError1 &&
                      partyNameWiseData.length > 0 ? (
                        partyNameWiseData.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {item.applicant} <br />
                              vs <br />
                              {item.respondent}
                            </td>
                            <td>{item.caseno}</td>
                            <td>{item.casetype}</td>
                            <td>{item.diaryno}</td>
                            <td>{formattedDate(item.dateoffiling)}</td>
                            <td>{item.stage}</td>
                            <td>
                              <PartyNameMoreDetails
                                item={item.diaryno}
                                schemaId={schemaId}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <>
                          <tr>
                            <td colSpan="7"> No Record Found </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
      </Formik>
    </div>
  );
};

export default PartyName;
