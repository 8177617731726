import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BredCrumb from "../../../../frontend/containers/Home/BredCrumb";
import InnerBanner from "../../../../frontend/containers/Home/InnerBanner";
import { useEffect } from "react";

const MemberDetailPage = () => {
  const navigate = useNavigate();
  const [memberDetail, setMemberDetail] = useState("");

  const getMemberDetail = () => {
    const a = localStorage.getItem("member-detail");
    setMemberDetail(a);
    console.log(a);
  };

  useEffect(() => {
    getMemberDetail();
    alert(memberDetail);
  }, []);

  return (
    <>
      <InnerBanner title={"Member"} />
      <BredCrumb title={"Member"} />
      <button onClick={() => navigate(-1)}>Back</button>

      <div className="chairman-detail">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 chairmen-detail-left">
              <div className="chairmen-detail-img-sec">
                <img src={memberDetail && memberDetail.image} alt="Chairman" />
              </div>
              <div className="chairman-sec">
                <h3>{memberDetail && memberDetail.id}</h3>
                <h5>{"hod.data[0].name"}</h5>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 chairmen-detail-right">
              <h3>{"hod.data[0].designation"}</h3>
              {/* <p
                dangerouslySetInnerHTML={{
                  __html: hod.data[0].description,
                }}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberDetailPage;
