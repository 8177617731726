import React, { useState } from "react";
import BredCrumb from "../Home/BredCrumb";
import InnerBanner from "../Home/InnerBanner";
import EmployeeCornerOrder from "./EmployeeCornerOrder";
import EmployeeCornerForms from "./EmployeeCornerForms";
import { useTitle } from "../../../Utilities/pageTitle";
// import { decryptResponseTransform } from "../../../redux/slices/catApiSlice";

function EmployCorner({ benchId, navigateTo }) {
  useTitle("Employee Corner");
  const [relatedLink, setRelatedLink] = useState("forms");
  const [isArchive, setIsArchive] = useState(false);

  return (
    <div>
      <InnerBanner title={"Employee Corner "} />
      <BredCrumb title={"Employee Corner "} navigateTo={navigateTo} />
      <div className="common-bg">
        <div className="container">
          <div className="row mt-2">
            <div className="col-12 col-sm-12 col-md-12 col-lg-9">
              <div className="about-content">
                <h6>Employee Corner</h6>
              </div>
            </div>
            {/* <div className="col-12 col-sm-12 col-md-12 col-lg-3">
              <a
                type="button"
                className="cancel ml-auto"
                onClick={() => setIsArchive(!isArchive)}
              >
                {isArchive ? "Back to Employee Corner" : "Go to Archive"}
              </a>
            </div> */}
          </div>
          <div className="col-12 col-sm-12 col-md-8 col-lg-12">
            <div className="case-details wow fadeInUp">
              <ul className="nav toptab-one">
                <li className="nav-item">
                  <a
                    className={
                      relatedLink === "forms" ? "nav-link active" : "nav-link"
                    }
                    onClick={() => setRelatedLink("forms")}
                  >
                    Forms
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      relatedLink === "orders" ? "nav-link active" : "nav-link"
                    }
                    onClick={() => setRelatedLink("orders")}
                  >
                    R.R./Seniority List/Orders
                  </a>
                </li>
              </ul>
              <div className="tab-content emp-tab-content">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="table-responsive emp-corner">
                      <table className="table table-striped table-bordered common-table bluetable">
                        <thead>
                          <tr>
                            <th id="view-counter-table-column" scope="col">
                              Sr.No.
                            </th>
                            <th id="view-subject-table-column" scope="col">
                              Subject
                            </th>
                            <th id="view-date-table-column" scope="col">
                              Date&nbsp;of&nbsp;Release
                            </th>
                            <th id="view-file-table-column" scope="col">
                              Documents English
                            </th>
                            <th id="view-file-hindi-table-column" scope="col">
                              Documents Hindi
                            </th>
                          </tr>
                        </thead>
                        {relatedLink === "forms" ? (
                          <EmployeeCornerForms
                            benchId={benchId}
                            isArchive={isArchive}
                          />
                        ) : (
                          <EmployeeCornerOrder
                            benchId={benchId}
                            isArchive={isArchive}
                          />
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployCorner;
