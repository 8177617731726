import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Modal, Table } from "react-bootstrap";
import { Formik } from "formik";
import Loader from "../../../../shared/Loader";
import {
  formattedDate,
  payloadForm,
  sentenceCase,
} from "../../../../../Utilities/FuncUtils";
import printJS from "print-js";
import {
  useGetExternalDataDetailMutation,
  useGetExternalDataMutation,
} from "../../../../../redux/slices/catApiSlice";

const MemberWiseOrderFinal = ({ schemaId, bench }) => {
  const [memberWiseFunc, { data: memberWiseFinalOrder, isLoading, isError }] =
    useGetExternalDataMutation();
  const [memberListFunc, { data: memberNameList }] =
    useGetExternalDataDetailMutation();

  useEffect(() => {
    const payload = { catschemaId: schemaId };
    memberListFunc({
      url: "MemberName",
      payload: payloadForm(payload),
    });
  }, []);

  const [isOpenModal, setIsOpenModal] = useState(false);

  //---------------- Formik ------------------------//

  // initial Values
  const initialValues = {
    member: "",
  };

  // validation
  const validationSchema = Yup.object({
    member: Yup.string().required("Required"),
  });

  // final submit formik form
  const handleSubmit = (values, { resetForm }) => {
    const payload = {
      catschemaId: schemaId,
      JudgeCode: values.member,
    };

    memberWiseFunc({
      url: "getCatFinalOrderReportMemberwise",
      payload: payloadForm(payload),
    });

    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);
    resetForm();
  };

  return (
    <div className="tab-pane container active" id="case_number">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          errors,
          values,
          resetForm,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className=" col-form-label mandatory">
                    Select Member:
                  </label>
                  <select
                    className="form-control"
                    id="member"
                    name="member"
                    onChange={handleChange}
                    value={values.member}
                    onBlur={handleBlur}
                  >
                    <option hidden>Select</option>
                    {memberNameList &&
                      memberNameList.map((item) => (
                        <option value={item.judge_code}>
                          {item.judge_name}
                        </option>
                      ))}
                  </select>
                  {touched.caseTypeId && errors.caseTypeId ? (
                    <div className="error">{errors.caseTypeId}</div>
                  ) : null}
                </div>

                {/* submit field */}

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div className="search_cancel_btn">
                    <button type="submit" className="search">
                      Search
                    </button>
                    <a type="button" className="cancel" onClick={resetForm}>
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </form>
            {isLoading ? (
              <Loader />
            ) : (
              <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
                <Modal.Header>
                  <div>
                    <div>
                      <b> {bench} </b>
                      <br />
                      Daily Order - Member Name wise
                    </div>
                    {/* <div className="searchedName">
                      Search Results for "{searchedName}"
                    </div> */}
                  </div>
                  <div className="button-div">
                    <i
                      className="fas fa-print"
                      onClick={() => printJS("table", "html")}
                    ></i>
                    <i
                      className="fas fa-window-close"
                      onClick={() => setIsOpenModal(false)}
                    ></i>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div className="table-responsive model-mh" id="table">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Diary No.</th>
                          <th>Case No.</th>
                          <th>Applicant vs Respondent</th>
                          <th>Disposal Date</th>
                          <th>Final Order(s)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {memberWiseFinalOrder &&
                        memberWiseFinalOrder.length > 0 &&
                        !isError ? (
                          memberWiseFinalOrder.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.diaryno}</td>
                              <td>{item.applicantno}</td>
                              <td>
                                {sentenceCase(item.applicantName)} <br />
                                vs <br />
                                {sentenceCase(item.respondentName)}
                              </td>
                              <td>{formattedDate(item.dateoffiling)}</td>
                              <td className="text-center">
                                <a
                                  target="_blank"
                                  href={`${item.dailyOrderPdf}`}
                                >
                                  <i className="fas fa-file-pdf"></i>
                                </a>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6"> No Record Found </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default MemberWiseOrderFinal;
