export const CatBenches = {
  hyderabad: {
    id: 21,
    name: "Central Administrative Tribunal - Hyderabad",
    navigateTo: "/Hyderabad",
  },
  guwahati: {
    id: 40,
    name: "Central Administrative Tribunal - Guwahati",
    navigateTo: "/guwahati",
  },
  chandigarh: {
    id: 60,
    name: "Central Administrative Tribunal - Chandigarh",
    navigateTo: "/chandigarh",
  },
  delhi: {
    id: 100,
    name: `Central Administrative Tribunal - (Principal Bench)`,
    navigateTo: "/delhi",
  },
  bangalore: {
    id: 103,
    name: "Central Administrative Tribunal - Bangalore",
    navigateTo: "/bangalore",
  },
  jodhpur: {
    id: 111,
    name: "Central Administrative Tribunal - Jodhpur",
    navigateTo: "/jodhpur",
  },
  patna: {
    id: 116,
    name: "Central Administrative Tribunal - Patna",
    navigateTo: "/patna",
  },
  jammu: {
    id: 117,
    name: "Central Administrative Tribunal - Jammu",
    navigateTo: "/jammu",
  },
  srinagar: {
    id: 119,
    name: "Central Administrative Tribunal - Srinagar",
    navigateTo: "/srinagar",
  },
  ahmedabad: {
    id: 120,
    name: "Central Administrative Tribunal - Ahmedabad",
    navigateTo: "/ahmedabad",
  },
  ernakulam: {
    id: 180,
    name: "Central Administrative Tribunal - Ernakulam",
    navigateTo: "/ernakulam",
  },
  jabalpur: {
    id: 200,
    name: "Central Administrative Tribunal - Jabalpur",
    navigateTo: "/jabalpur",
  },
  mumbai: {
    id: 210,
    name: "Central Administrative Tribunal - Mumbai",
    navigateTo: "/mumbai",
  },
  cuttack: {
    id: 260,
    name: "Central Administrative Tribunal - Cuttack",
    navigateTo: "/cuttack",
  },
  jaipur: {
    id: 291,
    name: "Central Administrative Tribunal - Jaipur",
    navigateTo: "/jaipur",
  },
  chennai: {
    id: 310,
    name: "Central Administrative Tribunal - Chennai",
    navigateTo: "/chennai",
  },
  allahabad: {
    id: 330,
    name: "Central Administrative Tribunal - Allahabad",
    navigateTo: "/allahabad",
  },
  lucknow: {
    id: 332,
    name: "Central Administrative Tribunal - Lucknow",
    navigateTo: "/lucknow",
  },
  kolkata: {
    id: 350,
    name: "Central Administrative Tribunal - Kolkata",
    navigateTo: "/kolkata",
  },
};

// Schema Name With Schema ID and Bhench Name
// schema_id	schema_name	name
// 21	telangana	Central Administrative Tribunal Hyderabad(Telangana)
// 40	guwahati	Central Administrative Tribunal Guwahati
// 60	chandigarh	Central Administrative Tribunal Chandigarh Bench
// 100	delhi	Central Administrative Tribunal(Principal Bench)
// 103	bangalore	Central Administrative Tribunal Bangalore
// 111	jodhpur	Central Administrative Tribunal Jodhpur
// 116	patna	Central Administrative Tribunal Patna
// 117	jammu	Central Administrative Tribunal JAMMU
// 119	srinagar	Central Administrative Tribunal Srinagar
// 120	ahmedabad	Central Administrative Tribunal AHMEDABAD
// 180	ernakulam	Central Administrative Tribunal Ernakulam
// 200	jabalpur	Central Administrative Tribunal Jabalpur
// 210	mumbai	Central Administrative Tribunal Mumbai
// 260	cuttack	Central Administrative Tribunal Cuttack
// 291	jaipur	Central Administrative Tribunal Jaipur
// 310	chennai	Central Administrative Tribunal Chennai
// 330	allahabad	Central Administrative Tribunal Allahabad
// 332	lucknow	Central Administrative Tribunal Lucknow
// 350	kolkata	Central Administrative Tribunal Kolkata
