import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BredCrumb from "../../../../frontend/containers/Home/BredCrumb";
import InnerBanner from "../../../../frontend/containers/Home/InnerBanner";
import Loader from "../../../../frontend/shared/Loader";

import { useGetInternalDataQuery } from "../../../../redux/slices/catApiSlice";

const Officers = ({ pageId, navigateTo }) => {
  const { data: pageData, isLoading } = useGetInternalDataQuery(
    `get-page/${pageId}`
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <InnerBanner title="Officers" />
      <BredCrumb title="Officers" navigateTo={navigateTo} />
      <div
        className={`about-content internal-content-wrap officer ${navigateTo.substr(
          1
        )}-officer`}
      >
        <div className="container officers-table">
          <div className="row justify-content-center">
            {pageId === 169 && (
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 office-page">
                <div className="chairman-sec">
                  <img src={"images/registrar.png"} />
                  <h3>Smt.&nbsp;Preeti&nbsp;Singh </h3>
                  <h5>Principal Registrar / HoO </h5>
                </div>
              </div>
            )}
          </div>

          {pageData && pageData.status && pageData.data ? (
            <>
              <p
                dangerouslySetInnerHTML={{
                  __html: pageData.data.body,
                }}
                id="wrapper"
              />
            </>
          ) : (
            <>NO Data Found</>
          )}
        </div>
      </div>
    </>
  );
};

export default Officers;
