import React from "react";
import FooterBottom from "./FooterBottom";
import FooterNav from "./FooterNav";
import FooterSlider from "./FooterSlider";

const Footer = () => {
  return (
    <>
      {/* govt websites links */}
      <FooterSlider />
      <footer>
        <FooterNav menuId={20} benchId={1} />
        <FooterBottom />
      </footer>
    </>
  );
};

export default Footer;
