import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
const HeaderMiddle = ({ bench, to }) => {
  const { languageType } = useSelector((state) => state.catSliceExternal);

  return (
    <header>
      <div>
        <div className="logo-sec-wraper">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-5 logo-sec wow fadeInLeft">
                <NavLink className="logo-align" to={`${to}`}>
                  <img src="images/logo.png" alt="emblem" />
                  <div className="brand-text">
                    <h4>
                      केंद्रीय प्रशासनिक अधिकरण
                      <span>
                        {languageType === "hid"
                          ? "केंद्रीय प्रशासनिक अधिकरण"
                          : "CENTRAL ADMINISTRATIVE TRIBUNAL"}
                        <br />
                        {bench === "principal bench" ? null : bench}
                      </span>
                    </h4>
                  </div>
                </NavLink>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-7 loginbtn-sec wow fadeInRight">
                <ul>
                  <li className="e-filling">
                    <a
                      className="btn common-btn"
                      referrerPolicy="no-referrer"
                      rel="noreferrer"
                      href="https://efiling.cgat.gov.in/ecat/onlinefiling.php"
                      target="_blank"
                    >
                      <span className="mdi mdi-file-document-outline"></span>
                      {languageType === "hid" ? "ई-फाइलिंग" : "e-FILLING"}
                      <span className="arrow-icon">
                        <i className="fas fa-angle-right"></i>
                      </span>
                    </a>
                  </li>
                  <li className="int-user-sec">
                    <a
                      className="btn common-btn"
                      referrerPolicy="no-referrer"
                      rel="noreferrer"
                      href="https://cis.cgat.gov.in/catlive/internal_login.php"
                      target={"_blank"}
                      tabIndex="0"
                    >
                      <span className="mdi mdi-account"></span>INTERNAL USER{" "}
                      <span className="arrow-icon">
                        <i className="fas fa-angle-right"></i>
                      </span>
                    </a>
                  </li>
                  <li className="user-sec">
                    <a
                      className="btn common-btn"
                      referrerPolicy="no-referrer"
                      rel="noreferrer"
                      href="https://catjudgements.nic.in/"
                      target={"_blank"}
                    >
                      Advance Search/CAT Judgements
                    </a>
                  </li>
                  {/* <li className="search-sec">
                    <a className="btn common-btn" href="#">
                      <i className="fa fa-search"></i>
                    </a>
                  </li> */}
                  <li className="embelam-sec">
                    <img src="images/indian-embelam.png" alt="indian-emblem " />
                  </li>
                </ul>
                <a
                  className="quadrat-animation quadrat3"
                  referrerPolicy="no-referrer"
                  rel="noreferrer"
                  href="https://cis.cgat.gov.in/catlive/display_causelist.php"
                  target="_blank"
                >
                  Causelist
                </a>
                <a
                  className="quadrat-animation quadrat2 "
                  referrerPolicy="no-referrer"
                  rel="noreferrer"
                  href="https://edba.in "
                  target="_blank"
                >
                  Click here to apply for Proximity Cards
                </a>
                <a
                  className="quadrat float-right"
                  referrerPolicy="no-referrer"
                  rel="noreferrer"
                  href="https://cis.cgat.gov.in/catlive/display_bench1.php"
                  target="_blank"
                >
                  Display Board
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderMiddle;
