import React from "react";
import BredCrumb from "../../../../frontend/containers/Home/BredCrumb";
import InnerBanner from "../../../../frontend/containers/Home/InnerBanner";
import Loader from "../../../../frontend/shared/Loader";
import { useGetInternalDataQuery } from "../../../../redux/slices/catApiSlice";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTitle } from "../../../../Utilities/pageTitle";

const AboutUs = ({ pageId, navigateTo }) => {
  useTitle("About Us");
  const { languageType } = useSelector((state) => state.catSliceExternal);
  const params = useParams();

  const { data: pageData, isLoading } = useGetInternalDataQuery(
    `get-page/${pageId}`
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <InnerBanner title={params["*"]} />
      <BredCrumb title={params["*"]} navigateTo={navigateTo} />
      <div className="about-content internal-content-wrap">
        <div className="container">
          {pageData && pageData.status && pageData.data ? (
            <>
              {/* <h6>{pageData.data.title}</h6> */}
              <h6>About Us</h6>
              {languageType === "hid" ? (
                <>
                  <p>
                    केंद्रीय प्रशासनिक अधिकरण की स्थापना संविधान के अनुच्छेद
                    323- ए के तहत संघ या सरकार द्वारा नियंत्रित किसी अन्य
                    प्राधिकरण से जुड़े मामलों के संबंध में सार्वजनिक सेवाओं और
                    पदों पर नियुक्त व्यक्तियों की भर्ती और सेवा की शर्तों के
                    संबंध में विवादों और शिकायतों के निपटारे के लिए की गई थी।
                    प्रशासनिक अधिकरण अधिनियम, 1985 के परिचय में उद्देश्य और
                    कारणों के विवरण में इसका उल्लेख किया गया था
                    <br />
                    <br /> "यह उम्मीद की जाती है कि विशेष रूप से सेवा मामलों से
                    निपटने के लिए ऐसे प्रशासनिक अधिकरणों की स्थापना से न केवल
                    विभिन्न न्यायालयों के बोझ को कम करने में मदद मिलेगी और जिससे
                    उन्हें अन्य मामलों को शीघ्रता से निपटाने के लिए अधिक समय
                    मिलेगा, बल्कि प्रशासनिक अधिकरणों के अंतर्गत आने वाले
                    व्यक्तियों को उनकी शिकायतों के संबंध में त्वरित राहत प्रदान
                    की की जा सकेगी। "<br />
                    <br /> पूरे भारत में केंद्रीय प्रशासनिक अधिकरण की 19
                    न्यायपीठ और 19 सर्किट बेंच हैं। भारत सरकार ने समय-समय पर
                    केंद्रीय प्रशासनिक अधिकरण के अधिकार क्षेत्र में लाने के लिए
                    प्रशासनिक अधिकरण अधिनियम, 1985 की धारा 14 (2) के अंतर्गत
                    केंद्र सरकार के मंत्रालयों और विभागों सहित 215 संगठनों को
                    अधिसूचित किया है। इसके अलावा केंद्रीय प्रशासनिक अधिकरण,
                    प्रधान न्यायपीठ राष्ट्रीय राजधानी क्षेत्र दिल्ली सरकार के
                    मामलों का भी निपटान करता है।<br></br>
                    <br></br> केंद्रीय प्रशासनिक अधिकरण का नेतृत्व माननीय
                    अध्यक्ष न्यायमूर्ति श्री रणजित वसंतराव मोरे, सेवानिवृत्त
                    मुख्य न्यायाधीश, मेघालय उच्च न्यायालय द्वारा किया जा रहा है।
                    अधिकरण की विभिन्न न्यायपीठों में 69 माननीय सदस्य हैं जिनमें
                    से 34 न्यायिक सदस्य और 35 प्रशासनिक सदस्य हैं। अधिनियम के
                    अन्य प्रावधानों के अधीन एक न्यायपीठ में एक न्यायिक सदस्य और
                    एक प्रशासनिक सदस्य होता है। केंद्रीय प्रशासनिक अधिकरण को एक
                    विशेषज्ञ निकाय के रूप में स्थापित किया गया है जिसमें
                    प्रशासनिक सदस्य और न्यायिक सदस्य शामिल हैं जो अपने विशेष
                    ज्ञान के आधार पर त्वरित और प्रभावी न्याय देने के लिए बेहतर
                    ढंग से सुसज्जित हैं।<br></br>
                    <br></br> 1985 में अधिकरण की स्थापना के बाद, इसे प्रशासनिक
                    अधिकरण अधिनियम, 1985 की धारा 29 के तहत उच्च न्यायालयों और
                    अधीनस्थ न्यायालयों से स्थानांतरण पर 13,350 लंबित मामले
                    प्राप्त हुए । 1985 में इसकी स्थापना के बाद से, 30 जून, 2022
                    तक, अधिकरण में लगभग 8,82,085 मामले संस्थापित किये गये। इनमें
                    से 8,04,272 मामलों का निपटारा किया जा चुका है। यह 91.18% की
                    निपटान दर है। प्रशासनिक अधिकरण अपने क्षेत्राधिकार और
                    प्रक्रिया के संबंध में सामान्य न्यायालयों से अलग है। अधिकरण
                    केवल अधिनियम के अंतर्गत आने वाले वादकारियों के सेवा मामलों
                    के संबंध में क्षेत्राधिकार का प्रयोग करता है। इसके अलावा,
                    अधिकरण सामान्य अदालतों की कई प्रक्रियात्मक तकनीकियों से भी
                    मुक्त है। । अधिनियम की प्रक्रियात्मक सरलता की सराहना इस तथ्य
                    से की जा सकती है कि एक पीड़ित सरकारी कर्मचारी भी ट्रिब्यूनल
                    के समक्ष व्यक्तिगत रूप से उपस्थित हो सकता है। इसके अतिरिक्त,
                    अधिकरण के समक्ष मूल आवेदन दाखिल करने के लिए आवेदकों को केवल
                    50/- रूपए का किफायती और नाममात्र शुल्क का भुगतान करना होगा।
                    नियमों में यह भी प्रावधान किया गया है कि जहां अधिकरण संतुष्ट
                    है कि कोई आवेदक गरीबी के आधार पर निर्धारित शुल्क का भुगतान
                    करने में असमर्थ है, तो वह ऐसे आवेदक को शुल्क के भुगतान से
                    छूट दे सकता है। इस प्रकार. अधिकरण ने लंबित मामलों के त्वरित
                    और सस्ते निपटान के माध्यम से अपनी स्थापना को सही साबित किया
                    है। अधिकरण मामलों का निर्णय लेने में प्राकृतिक न्याय के
                    सिद्धांतों पर चलता है और सिविल प्रक्रिया संहिता द्वारा
                    निर्धारित प्रक्रिया से बंधा नहीं है। केंद्रीय प्रशासनिक
                    अधिकरण को अपने प्रक्रिया और पद्धति नियम बनाने का अधिकार है।
                    अधिनियम के उक्त प्रावधान के अंतर्गत, अधिकरण के सुचारू कामकाज
                    को सुनिश्चित करने के लिए केंद्रीय प्रशासनिक अधिकरण (
                    प्रक्रिया) नियम, 1987 और केंद्रीय प्रशासनिक अधिकरण पद्धति
                    नियम, 1993 को अधिसूचित किया गया है। प्रशासनिक अधिकरण
                    अधिनियम, 1985 की धारा 17 के अंतर्गत, अधिकरण को उच्च न्यायालय
                    के रूप में स्वयं की अवमानना के संबंध में समान क्षेत्राधिकार
                    और अधिकार का प्रयोग करने की शक्ति प्रदान की गई है। <br></br>
                    <br></br>
                    प्रारंभ में अधिकरण के निर्णय को विशेष अनुमति याचिका दायर
                    करके माननीय सर्वोच्च न्यायालय के समक्ष चुनौती दी जा सकती थी
                    । हालाँकि एल. चंद्र कुमार के मामले में सर्वोच्च न्यायालय के
                    फैसले के बाद, केंद्रीय प्रशासनिक अधिकरण के आदेशों को अब
                    संबंधित उच्च न्यायालय, जिसके क्षेत्रीय अधिकार क्षेत्र में
                    अधिकरण की न्यायपीठ स्थित है, के समक्ष संविधान के अनुच्छेद
                    226/227 के तहत रिट याचिका के माध्यम से चुनौती दी जा रही है।
                    । केंद्रीय प्रशासनिक अधिकरण के कर्मचारियों को अध्यक्ष के
                    सामान्य पर्यवेक्षण में अपने कर्तव्यों का निर्वहन करना आवश्यक
                    है। ट्रिब्यूनल के अधिकारियों और अन्य कर्मचारियों के वेतन और
                    भत्ते एवं सेवाशर्तें केंद्र सरकार द्वारा निर्दिष्ट की जाती
                    हैं। इन प्रावधानों के अनुसरण में. केंद्र सरकार ने केंद्रीय
                    प्रशासनिक अधिकरण कर्मचारी (सेवाशर्तें) नियम, 1985 को
                    अधिसूचित किया है। अधिकरण के कार्यों के निर्वहन के लिए 35
                    श्रेणियों में 1373 पद वर्गीकृत हैं। केंद्रीय प्रशासनिक
                    अधिकरण एक गतिशील संगठन है जिसका अधिकार क्षेत्र,
                    जिम्मेदारियाँ और कार्य भार बढ़ता जा रहा है। अब केंद्रीय
                    प्रशासनिक अधिकरण ने एक नई गतिशील वेबसाइट, केस सूचना प्रणाली,
                    वीडियो कॉन्फ्रेंसिंग आदि के माध्यम से अपनी गतिविधियों के
                    आधुनिकीकरण और कम्प्यूटरीकरण के लिए एक महत्वाकांक्षी परियोजना
                    शुरू की है। यह परियोजना पूरा होने पर वादियों वकीलों.
                    शोधकर्ताओं और सामान्य रूप से जनता को अधिकरण के आदेशों एवं
                    निर्णयों तक शीघ्र पहुंचने की सुविधा प्रदान करेगी, इसके
                    अतिरिक्त अभिलेखों के कुशल रखरखाव और प्रबंधन तथा मामलों के
                    त्वरित निपटान में भी सहायता करेगी।
                  </p>
                </>
              ) : languageType === "eng" ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: pageData.data.body.replace(
                      /href/g,
                      "target='_blank' href"
                    ),
                  }}
                />
              ) : (
                "No data found"
              )}
            </>
          ) : (
            <>NO Data Found</>
          )}
        </div>
      </div>
    </>
  );
};

export default AboutUs;
