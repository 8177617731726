import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setExternalLink,
  setShowExternalPopup,
} from "../../redux/slice/sliceInternal/externalPopupSlice";

const PopUpExternal = () => {
  const dispatch = useDispatch();
  const { showExternalPopup, externalLink } = useSelector(
    (state) => state.externalPopupSlice
  );
  return (
    <Modal
      className="external-link-modal"
      show={showExternalPopup}
      onHide={() => {
        dispatch(setExternalLink(""));
        dispatch(setShowExternalPopup(false));
      }}
    >
      <Modal.Header>
        <Modal.Title>External Links</Modal.Title>
        <Button
          type="button"
          className="close"
          onClick={() => {
            dispatch(setExternalLink(""));
            dispatch(setShowExternalPopup(false));
          }}
        >
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>
        You are being redirected to an external website. Click yes to continue{" "}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(setExternalLink(""));
            dispatch(setShowExternalPopup(false));
          }}
        >
          No
        </Button>
        <Button
          className="yes-button"
          //   variant="primary"
          href={externalLink}
          target="_blank"
          onClick={() => {
            // dispatch(setExternalLink(""));
            dispatch(setShowExternalPopup(false));
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopUpExternal;
