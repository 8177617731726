import React, { useRef, useState } from "react";
import Slider from "react-slick";

import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import Loader from "../../shared/Loader";

const HomeBanner = ({ bannerId }) => {
  const slider = useRef();
  const [playPause, setPlayPause] = useState(false);

  const { data: banner, isLoading } = useGetInternalDataQuery(
    `/get-banners/${bannerId}`
  );

  //slider settings
  const settings = {
    //dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const next = () => {
    slider.current.slickNext();
  };
  const prev = () => {
    slider.current.slickPrev();
  };

  const HandlePlayPause = () => {
    setPlayPause(!playPause);
    if (playPause) {
      slider.current.slickPlay();
    }
    if (!playPause) {
      slider.current.slickPause();
    }
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="home-banner">
      <Slider {...settings} ref={slider}>
        {banner && banner.status ? (
          banner.data &&
          banner.data.map((item, index) => (
            <div className="carousel-item active" key={index}>
              <img
                referrerPolicy="no-referrer"
                src={item.image_url}
                className="img-fluid"
                alt="Home slider"
              />
              <div className="container">
                <div className="carousel-content">
                  <h2>{item.title}</h2>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>No Data Found</>
        )}
      </Slider>
      <a className="carousel-control-prev" onClick={prev}>
        <span className="carousel-control-prev-icon">
          <i className="fa fa-angle-left" aria-hidden="true"></i>
        </span>
      </a>
      <a className="carousel-control-next" onClick={next}>
        <span className="carousel-control-next-icon">
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </span>
      </a>
      <a className="carousel-control-play-pause" onClick={HandlePlayPause}>
        <span className="carousel-control-play-pause-icon">
          {!playPause ? (
            <i className="fas fa-pause" aria-hidden="true"></i>
          ) : (
            <i className="fas fa-play" aria-hidden="true"></i>
          )}
        </span>
      </a>
    </div>
  );
};

export default HomeBanner;
