import React, { useState } from "react";
import CaseNoFinal from "../../../../../../frontend/containers/CaseManagementServices/OralAndFinalOrder/Tabs/CaseNoFinal";
import DateWiseOrderFinal from "../../../../../../frontend/containers/CaseManagementServices/OralAndFinalOrder/Tabs/DateWiseOrderFinal";
import DiaryNoFinal from "../../../../../../frontend/containers/CaseManagementServices/OralAndFinalOrder/Tabs/DiaryNoFinal";
import MemberWiseOrderFinal from "../../../../../../frontend/containers/CaseManagementServices/OralAndFinalOrder/Tabs/MemberWiseOrderFinal";

const OralAndFinalOrderHomeParent = ({ schemaId, bench }) => {
  const [relatedLink, setRelatedLink] = useState("case-number");
  return (
    <div className="tab-pane container active" id="case_details">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4">
          <ul className="nav toptab-two">
            <li className="nav-item">
              <a
                className={
                  relatedLink === "case-number" ? "nav-link active" : "nav-link"
                }
                data-toggle="pill"
                onClick={() => setRelatedLink("case-number")}
              >
                Case Number
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  relatedLink === "diary-number"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => setRelatedLink("diary-number")}
              >
                Diary Number
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  relatedLink === "datewise-order"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => setRelatedLink("datewise-order")}
              >
                Datewise Order
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  relatedLink === "Party-Name" ? "nav-link active" : "nav-link"
                }
                onClick={() => setRelatedLink("Party-Name")}
              >
                Member-Wise
              </a>
            </li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 p-0">
          <div className="tab-content">
            {relatedLink === "case-number" ? (
              <CaseNoFinal schemaId={schemaId} bench={bench} />
            ) : relatedLink === "diary-number" ? (
              <DiaryNoFinal schemaId={schemaId} bench={bench} />
            ) : relatedLink === "datewise-order" ? (
              <DateWiseOrderFinal schemaId={schemaId} bench={bench} />
            ) : (
              <MemberWiseOrderFinal schemaId={schemaId} bench={bench} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OralAndFinalOrderHomeParent;
