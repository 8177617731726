import React, { useState } from "react";
import * as Yup from "yup";
import { Modal, Table, Button } from "react-bootstrap";
import { Formik } from "formik";
import { generateYearList } from "../../../../../Utilities/FuncUtils";
import {
  formattedDate,
  payloadForm,
  sentenceCase,
} from "../../../../../Utilities/FuncUtils";
import {
  useGetExternalDataDetailMutation,
  useGetExternalDataMutation,
} from "../../../../../redux/slices/catApiSlice";
import Skeleton from "react-loading-skeleton";

const DiaryNo = ({ schemaId, bench }) => {
  const [
    diaryFunc,
    { data: diaryNoWise, isLoading: loading, error: err1, isError: isError1 },
  ] = useGetExternalDataMutation();
  const [
    diaryFunc2,
    { data: diaryNoWiseMoreData, isLoading: loading1, error: err2, isError },
  ] = useGetExternalDataDetailMutation();
  // states for modals
  const [isOpenModal, setIsOpenModal] = useState(false);

  //---------------- Formik ------------------------//

  // initial Values
  const initialValues = {
    caseNo: "",
    caseYear: "",
  };

  // validation
  const validationSchema = Yup.object({
    caseNo: Yup.number()
      .typeError("Diary No. must be a number")
      .positive("Diary No must be a positive number")
      .integer("Diary No must be an integer")
      .required("Diary No is required")
      .required("Required"),
    caseYear: Yup.string().required("Required"),
  });

  // final submit formik form
  const handleSubmit = async (values, { resetForm }) => {
    const payload = {
      catschemaId: schemaId,
      dairyNo: values.caseNo,
      caseYear: values.caseYear,
    };

    try {
      const data = await diaryFunc({
        url: "casedetail_individual_dairy_no_wise",
        payload: payloadForm(payload),
      }).unwrap();
      const [diaryNo, diaryYear] = data && data.diaryno.split("/");
      const payload2 = {
        catschemaId: schemaId,
        dairyNo: diaryNo,
        dairyYear: diaryYear,
      };
      await diaryFunc2({
        url: "getAdditionalDetail",
        payload: payloadForm(payload2),
      }).unwrap();
      setIsOpenModal(true);
      resetForm();
    } catch (error) {
      console.error(err1 || err2);
    }
    setIsOpenModal(true);
    resetForm();
  };
  if (loading || loading1) {
    return <Skeleton />;
  }
  return (
    <div className="tab-pane container active" id="case_number">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          errors,
          values,
          resetForm,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              {/* field 1 */}
              <div className="form-group row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className="col-form-label mandatory">
                    Enter Diary No:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="caseNo"
                    name="caseNo"
                    onChange={handleChange}
                    value={values.caseNo}
                    onBlur={handleBlur}
                  />
                  {touched.caseNo && errors.caseNo ? (
                    <div className="error">{errors.caseNo}</div>
                  ) : null}
                </div>

                {/* field 2 */}

                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                  <label className=" col-form-label mandatory">
                    Enter Diary Year:
                  </label>
                  <select
                    type="text"
                    id="caseYear"
                    name="caseYear"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.caseYear}
                  >
                    <option hidden>Select</option>
                    {generateYearList().map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                  {touched.caseYear && errors.caseYear ? (
                    <div className="error">{errors.caseYear}</div>
                  ) : null}
                </div>

                {/* submit field */}

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div className="search_cancel_btn">
                    <button type="submit" className="search">
                      Search
                    </button>
                    <a type="button" className="cancel" onClick={resetForm}>
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </form>

            <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
              <Modal.Header>
                <div>
                  <b> {bench} </b>
                  <br />
                  Case Status - Diary No. wise
                </div>
                <Button
                  type="button"
                  className="close"
                  onClick={() => setIsOpenModal(false)}
                >
                  &times;
                </Button>
              </Modal.Header>
              <Modal.Body>
                <div className="table-responsive model-mh">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Applicant vs Respondent</th>
                        <th>Dairy No.</th>
                        <th>Location</th>
                        {/* <th>Case Type</th> */}
                        <th>Case No.</th>
                        <th>Date of Filing</th>
                        <th>Other Details</th>
                      </tr>
                    </thead>
                    {diaryNoWise && !isError && !isError1 ? (
                      <tbody>
                        <tr>
                          <td>
                            <center>
                              {sentenceCase(diaryNoWise.applicant)} <br />
                              vs <br />
                              {sentenceCase(diaryNoWise.respondent)}
                            </center>
                          </td>
                          <td>{diaryNoWise.diaryno}</td>
                          <td>
                            {diaryNoWise.location.toLowerCase() === "delhi"
                              ? "Principal Bench"
                              : diaryNoWise.location}
                          </td>
                          <td>{diaryNoWise.caseno}</td>
                          <td>{formattedDate(diaryNoWise.dateoffiling)}</td>
                          <td>
                            <a
                              className="more-details"
                              // onClick={() => setIsOpenModal2(true)}
                              target="_blank"
                              href={
                                diaryNoWiseMoreData &&
                                diaryNoWiseMoreData["More Detail"]
                              }
                            >
                              More&nbsp;Details
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan="7"> No Record Found </td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
      </Formik>
    </div>
  );
};

export default DiaryNo;
