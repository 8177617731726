import React, { useEffect } from "react";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import { useDispatch } from "react-redux";
import { fetchAddVisitors } from "../../../redux/slice/sliceInternal/footerBottomSlice";
import Skeleton from "react-loading-skeleton";

const FooterBottom = () => {
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();

  const { data: getVisitors, isLoading: loading1 } =
    useGetInternalDataQuery(`getVisitor`);
  const { data: lastUpdated, isLoading: loading2 } =
    useGetInternalDataQuery(`getlastupdate`);

  useEffect(() => {
    dispatch(fetchAddVisitors());
  }, []);

  if (loading1 || loading2) {
    return <Skeleton count={2} height={20} />;
  }

  return (
    <>
      <div className="footer-bottom-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 copyright-sec">
              <div className="footer-copy-sec">
                <p>
                  Copyright &#169; {currentYear} CENTRAL ADMINISTRATIVE
                  TRIBUNAL. All Rights Reserved.
                </p>
                <p>
                  Visitors: {getVisitors && getVisitors.data} | Last Updated :{" "}
                  {lastUpdated && lastUpdated.data}{" "}
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-3 newsletter-sec">
              {/* <div className="news-sec">
                <form>
                  <input
                    className="inptfld"
                    name="email"
                    placeholder="Newsletter Subscribe"
                    type="text"
                  />
                  <input
                    className="subscribebtn"
                    value="SUBSCRIBE"
                    type="submit"
                  />
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterBottom;
