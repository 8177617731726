import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import Loader from "../../shared/Loader";
import BredCrumb from "../Home/BredCrumb";
import InnerBanner from "../Home/InnerBanner";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import { currentYear, filterDataByYear } from "../../../Utilities/FuncUtils";
import { useTitle } from "../../../Utilities/pageTitle";

function Calendar({ benchId, navigateTo }) {
  useTitle("Calendar");
  const { data: calendar, isLoading } = useGetInternalDataQuery(
    `/get-modules/${benchId}/calendar`
  );

  const [isArchive, setIsArchive] = useState(false);

  useEffect(() => {
    return () => {
      setIsArchive(false);
    };
  }, []);

  const { archiveData, allData } = filterDataByYear(
    calendar?.data,
    currentYear - 1
  );

  const renderTableRows = (data) => {
    return data?.map((item, index) => (
      <tr key={item.id}>
        <td headers="view-counter-table-column">{index + 1}</td>
        <td headers="view-details-table-column">{item.subject}</td>
        <td headers="view-date-table-column">
          {new Date(item.date_of_issue).getFullYear()}
        </td>
        <td headers="view-file-table-column">
          <a
            target="_blank"
            className="external-link"
            type="button"
            href={item.attachment}
          >
            <i className="fas fa-file">
              <span className="sr-only">no text</span>
            </i>
          </a>
          <br />
          <small> size 589kb</small>
          <br />
        </td>
      </tr>
    ));
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <>
        <InnerBanner title={"Calendar"} />
        <BredCrumb title={"Calendar"} navigateTo={navigateTo} />
        <div className="chairman-detail">
          <div className="container">
            <div className="row mt-2">
              <div className="col-12 col-sm-12 col-md-12 col-lg-9">
                <div className="about-content">
                  <h6>Calendar</h6>
                </div>
              </div>
              {/* <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                <a
                  type="button"
                  className="cancel ml-auto"
                  onClick={() => setIsArchive(!isArchive)}
                >
                  {isArchive ? "Back to Calendars" : "Go to Archives"}
                </a>
              </div> */}
            </div>
            <div className="table-responsive model-mh">
              <Table striped bordered hover className="custom-table">
                <thead>
                  <tr>
                    <th id="view-counter-table-column" scope="col">
                      Sr.No.
                    </th>
                    <th id="view-details-table-column" scope="col">
                      CAT
                    </th>
                    <th id="view-date-table-column" scope="col">
                      Year
                    </th>
                    <th id="view-file-table-column" scope="col">
                      Open(PDF)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allData?.length > 0 ? (
                    renderTableRows(allData)
                  ) : (
                    <tr>
                      <td colSpan="7">No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Calendar;
