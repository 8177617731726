import React from "react";

const InnerBanner = ({ title }) => {
  if (typeof title !== "string") {
    return null; // or handle the case accordingly
  }

  return (
    <div className="inner-bg">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="b-title">
              <h5>{title.split("-").join(" ")}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerBanner;
