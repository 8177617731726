import React, { useEffect } from "react";
import Loader from "../../shared/Loader";
import BredCrumb from "./BredCrumb";
import InnerBanner from "./InnerBanner";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const ReadMoreChairman = ({ memberId, navigateTo }) => {
  const { chairmanType, languageType } = useSelector(
    (state) => state.catSliceExternal
  );

  const params = useParams();
  console.log(params);
  const { data: hod, isLoading } = useGetInternalDataQuery(
    `get-hod/${memberId}`
  );

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <InnerBanner title={memberId == 1 ? "Chairman" : "HOD"} />
      <BredCrumb
        title={memberId == 1 ? "Chairman" : "HOD"}
        navigateTo={navigateTo}
      />
      <div className="chairman-detail">
        <div className="container">
          {hod.status && hod.data[0] ? (
            <div className="row">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 chairmen-detail-left">
                <div className="chairmen-detail-img-sec">
                  <img src={hod.data[0].image} alt="Chairman" />
                </div>
                <div className="chairman-sec">
                  {languageType === "hid" ? (
                    <>
                      <h5>
                        माननीय <span className="hi-text">U;k;ewfrZ j.kftr</span>
                        वसंतराव मोरे
                      </h5>
                    </>
                  ) : (
                    <h5>{hod.data[0].name}</h5>
                  )}
                  <h3>{hod.data[0].designation}</h3>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-9 col-lg-9 chairmen-detail-right">
                {languageType === "hid" ? (
                  <>
                    <h5>
                      माननीय <span className="hi-text">U;k;ewfrZ j.kftr</span>
                      वसंतराव मोरे
                    </h5>
                  </>
                ) : (
                  <h5>{hod.data[0]?.name}</h5>
                )}
                <h3>{hod.data[0].designation}</h3>

                {languageType === "hid" ? (
                  <>
                    <p>
                      माननीय न्यायमूर्ति श्री रणजित वसंतराव मोरे का जन्म 4
                      नवंबर, 1959 को हुआ था। वह निम्सोड, तालुक्का खटाऊ, जिला
                      सतारा (महाराष्ट्र) के रहने वाले हैं। उन्होंने अपनी स्कूली
                      शिक्षा निम्सोड, जिला सतारा से और बीए (ऑनर्स) कोल्हापुर से
                      की। उन्होंने सांगली से कानून की डिग्री प्राप्त की। वे
                      एलएलबी में शिवाजी विश्वविद्यालय, कोल्हापुर की योग्यता क्रम
                      में दूसरे स्थान पर रहे । आगे की पढ़ाई के लिए उन्हें
                      राष्ट्रीय योग्यता छात्रवृत्ति से सम्मानित किया गया।
                      उन्होंने मुंबई विश्वविद्यालय से एलएलएम किया।
                      <br/> <br/> वह 15 सितंबर, 1983 को एक वकील के रूप में
                      नामांकित हुए और श्री एपी शाह (मद्रास और दिल्ली उच्च
                      न्यायालयों के पूर्व मुख्य न्यायाधीश) के चैम्बर में शामिल
                      हुए। उन्होंने मुख्य रूप से बॉम्बे उच्च न्यायालय के अपीलीय
                      पक्ष में प्रैक्टिस की । वह नगर परिषद, नगर निगमों, जिला
                      केंद्रीय सहकारी बैंकों, चीनी कारखानों, चुनाव आयोग और
                      महाराष्ट्र लोक सेवा आयोग की ओर से कई संवैधानिक, नागरिक और
                      आपराधिक मामलों की पैरवी की। उन्होंने कई महत्वपूर्ण मामलों
                      और जनहित याचिकाओं में भी पैरवी की।
                      <br/> <br/>उन्हें 8 सितंबर, 2006 को बॉम्बे उच्च न्यायालय के
                      न्यायाधीश के रूप में पदोन्नत किया गया । इसके बाद उन्हें
                      मेघालय उच्च न्यायालय में स्थानांतरित किया गया एवं उन्होंने
                      9 मार्च, 2020 को न्यायाधीश के रूप में शपथ ली । उन्हें 12
                      अक्टूबर, 2021 को मेघालय के उच्च न्यायालय के मुख्य
                      न्यायाधीश के रूप में पदोन्नत किया गया और वह 3 नवंबर, 2021
                      वहां से सेवानिवृत हुए।
                      <br />
                      <br /> माननीय न्यायमूर्ति श्री रणजित वसंतराव मोरे ने 30
                      जुलाई, 2022 को केंद्रीय प्रशासनिक अधिकरण के अध्यक्ष का
                      पदभार ग्रहण किया।
                    </p>
                  </>
                ) : (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: hod.data[0].description,
                    }}
                  />
                )}
              </div>
            </div>
          ) : chairmanType === "1" ? (
            <>No Data Found</>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default ReadMoreChairman;
