import React from "react";
import { Table } from "react-bootstrap";

import Loader from "../../shared/Loader";
import BredCrumb from "../Home/BredCrumb";
import InnerBanner from "../Home/InnerBanner";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import { useTitle } from "../../../Utilities/pageTitle";

const Forms = ({ benchId, navigateTo }) => {
  useTitle("Forms");
  const { data: forms, isLoading } = useGetInternalDataQuery(
    `get-modules/${benchId}/forms`
  );

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <>
        <InnerBanner title={"Forms"} />
        <BredCrumb title={"Forms"} navigateTo={navigateTo} />
        <div className="chairman-detail">
          <div className="container">
            <div className="table-responsive model-mh">
              <Table striped bordered hover className="custom-table">
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>Title</th>
                    <th> Files</th>
                  </tr>
                </thead>
                <tbody>
                  {forms && forms.status && forms.data.length > 0 ? (
                    forms.data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.subject}</td>
                        <td className="text-center">
                          {/* <a className="mr-5" target="_blank" href={`${item.file_path_doc}`}>
                          <i className="fas fa-file-word"></i>
                        </a> */}
                          <a target="_blank" href={`${item.attachment}`}>
                            <i className="fas fa-file-pdf"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <tr>
                        <td colSpan="7"> No Record Found </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Forms;
