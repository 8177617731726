import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import Loader from "../../shared/Loader";

const BenchSlider = () => {
  const { data: benches, isLoading } = useGetInternalDataQuery(`get-bench`);
  const { languageType } = useSelector((state) => state.catSliceExternal);
  const slider = useRef();

  const [playPause, setPlayPause] = useState(false);
  const HandlePlayPause = () => {
    setPlayPause(!playPause);
    if (playPause) {
      slider.current.slickPlay();
    }
    if (!playPause) {
      slider.current.slickPause();
    }
  };

  const settings = {
    arrows: true,
    infinite: true,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="benches-slide-sec">
      <h1>Benches</h1>
      <Slider {...settings} ref={slider}>
        {benches && benches.status && benches.data ? (
          benches.data
            .map((item) => ({
              name: item.name,
              slug: "/" + item.name.toLowerCase(),
              image: item.image,
            }))
            .filter((item) => item.name !== "DELHI")
            .map((item, index) => (
              <div className="bench-item" key={index}>
                <NavLink
                  to={item.slug === "/home" ? "/delhi" : item.slug}
                  aria-label={item.name}
                >
                  <span>
                    <img src={item.image} alt={item.name} />
                  </span>
                  {item.name === "HOME"
                    ? "PRINCIPAL BENCH, NEW DELHI"
                    : item.name === "SRINAGAR" && languageType === "hid"
                    ? "श्रीनगर"
                    : item.name === "JAIPUR" && languageType === "hid"
                    ? "जयपुर"
                    : item.name === "ERNAKULAM" && languageType === "hid"
                    ? "एर्नाकुलम"
                    : item.name === "JABALPUR" && languageType === "hid"
                    ? "जबलपुर"
                    : item.name}
                </NavLink>
              </div>
            ))
        ) : (
          <>No Data Found</>
        )}
      </Slider>
      <div className="news-control mx-auto">
        <div className="news-left-right">
          {/* <span>What's New</span> */}
          <a className="pause-btn" onClick={HandlePlayPause}>
            {!playPause ? (
              <i className="fas fa-pause"></i>
            ) : (
              <i className="fas fa-play"></i>
            )}
          </a>
        </div>
      </div>
    </div>
  );
};

export default BenchSlider;
