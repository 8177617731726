import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import BredCrumb from "./BredCrumb";
import InnerBanner from "./InnerBanner";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import Skeleton from "react-loading-skeleton";
import {
  currentYear,
  filterCircularsDataByYear,
} from "../../../Utilities/FuncUtils";

const CircularMoreDetails = ({ circularId, navigateTo }) => {
  const [isArchive, setIsArchive] = useState(false);

  // fetching benches from store

  const { data: circulars, isLoading } = useGetInternalDataQuery(
    `get-notice/${circularId}`
  );

  const { archiveData, allData } = filterCircularsDataByYear(
    circulars?.data,
    currentYear
  );

  const renderTableRows = (data) => {
    return data?.map((item, index) => (
      <tr key={item.id}>
        <td headers="view-counter-table-column">{index + 1}</td>
        <td headers="view-details-table-column">{item.title}</td>
        <td headers="view-date-table-column">
          {item.from_date?.split("-").reverse().join("-")}
        </td>

        <td headers="view-file-table-column">
          {item.aad_file && (
            <>
              <a target="_blank" href={`${item.aad_file}`}>
                <i className="fas fa-file-pdf"></i>
              </a>
              <br />
              <small>
                {item.aad_file_size ? `(${item.aad_file_size})` : "(589.12 KB)"}
              </small>
              <br />
            </>
          )}
        </td>
        <td headers="view-file-hindi-table-column">
          {item.aad_file_hindi && (
            <>
              <a target="_blank" href={`${item.aad_file_hindi}`}>
                <i className="fas fa-file-pdf"></i>
              </a>
              <br />
              <small>
                {item.aad_file_hindi_size
                  ? `(${item.aad_file_hindi_size})`
                  : "(589.12 KB)"}
              </small>
              <br />
            </>
          )}
        </td>
      </tr>
    ));
  };

  if (isLoading) {
    return <Skeleton />;
  }
  return (
    <>
      <InnerBanner title={"Notices/Circulars"} />
      <BredCrumb title={"Notices/Circulars"} navigateTo={navigateTo} />
      <div className="chairman-detail">
        <div className="container">
          <div className="row mt-2">
            <div className="col-12 col-sm-12 col-md-12 col-lg-9">
              <div className="about-content">
                <h6>Notices / Circulars</h6>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
              <a
                type="button"
                className="cancel ml-auto"
                onClick={() => setIsArchive(!isArchive)}
              >
                {isArchive ? "Back to Circulars/Notices" : "Go to Archives"}
              </a>
            </div>
          </div>
          <div className="table-responsive model-mh">
            <Table striped bordered hover className="custom-table">
              <thead>
                <tr>
                  <th id="view-counter-table-column" scope="col">
                    Sr.No.
                  </th>
                  <th id="view-title-table-column" scope="col">
                    Title
                  </th>
                  <th id="view-date-table-column" scope="col">
                    Date&nbsp;of&nbsp;Release
                  </th>
                  <th id="view-file-table-column" scope="col">
                    Documents&nbsp;English
                  </th>
                  <th id="view-file-hindi-table-column" scope="col">
                    Documents&nbsp;Hindi
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isArchive ? (
                  archiveData?.length > 0 ? (
                    renderTableRows(archiveData)
                  ) : (
                    <tr>
                      <td colSpan="7">No Archive Data</td>
                    </tr>
                  )
                ) : allData?.length > 0 ? (
                  renderTableRows(allData)
                ) : (
                  <tr>
                    <td colSpan="5">Content Awaited From CAT</td>
                  </tr>
                )}
                {/* {circulars && circulars.status && circulars.data.length > 0 ? (
                  circulars &&
                  circulars.data.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.title}</td>
                      <td>{handleDate(item.from_date)}</td>
                      <td className="text-center">
                        {item.aad_file && (
                          <a target="_blank" href={`${item.aad_file}`}>
                            <i className="fas fa-file-pdf"></i>
                          </a>
                        )}
                      </td>
                      <td className="text-center">
                        {item.aad_file_hindi && (
                          <a target="_blank" href={`${item.aad_file_hindi}`}>
                            <i className="fas fa-file-pdf"></i>
                          </a>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan="5"> No Record Found </td>
                    </tr>
                  </>
                )} */}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CircularMoreDetails;
