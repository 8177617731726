import React, { useState } from "react";
import AdvocateName from "../../../../../../frontend/containers/CaseManagementServices/CaseDetail/Tabs/AdvocateName";
import CaseNo from "../../../../../../frontend/containers/CaseManagementServices/CaseDetail/Tabs/CaseNo";
import DiaryNo from "../../../../../../frontend/containers/CaseManagementServices/CaseDetail/Tabs/DiaryNo";
import PartyName from "../../../../../../frontend/containers/CaseManagementServices/CaseDetail/Tabs/PartyName";
import CaseNoDaily from "../../../../../../frontend/containers/CaseManagementServices/DailyOrder/Tabs/CaseNoDaily";
import DateWiseOrderDaily from "../../../../../../frontend/containers/CaseManagementServices/DailyOrder/Tabs/DateWiseOrderDaily";
import DiaryNoDaily from "../../../../../../frontend/containers/CaseManagementServices/DailyOrder/Tabs/DiaryNoDaily";
import PartyNameDaily from "../../../../../../frontend/containers/CaseManagementServices/DailyOrder/Tabs/PartyNameDaily";

const DailyOrderHomeParent = ({ schemaId, bench }) => {
  const [relatedLink, setRelatedLink] = useState("case-number");
  return (
    <div className="tab-pane container active" id="case_details">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4">
          <ul className="nav toptab-two">
            <li className="nav-item">
              <a
                className={
                  relatedLink === "case-number" ? "nav-link active" : "nav-link"
                }
                data-toggle="pill"
                onClick={() => setRelatedLink("case-number")}
              >
                Case Number
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  relatedLink === "diary-number"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => setRelatedLink("diary-number")}
              >
                Diary Number
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  relatedLink === "datewise-order"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => setRelatedLink("datewise-order")}
              >
                Datewise Order
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  relatedLink === "Party-Name" ? "nav-link active" : "nav-link"
                }
                onClick={() => setRelatedLink("Party-Name")}
              >
                Party Name
              </a>
            </li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 p-0">
          <div className="tab-content">
            {relatedLink === "case-number" ? (
              <CaseNoDaily schemaId={schemaId} bench={bench} />
            ) : relatedLink === "diary-number" ? (
              <DiaryNoDaily schemaId={schemaId} bench={bench} />
            ) : relatedLink === "datewise-order" ? (
              <DateWiseOrderDaily schemaId={schemaId} bench={bench} />
            ) : (
              <PartyNameDaily schemaId={schemaId} bench={bench} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyOrderHomeParent;
