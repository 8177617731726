import { apiSlice, apiSliceExternal, apiSliceIp } from "./apiSlice";
import CryptoJS from "crypto-js";

const decryptResponseTransform = (response) =>
  JSON.parse(
    CryptoJS.AES.decrypt(response, "CatApplication").toString(CryptoJS.enc.Utf8)
  );

const catApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInternalData: builder.query({
      query: (url) => ({
        url,
        responseHandler: async (response) => await response.text(),
      }),
      // query: (url) => ({
      //   url,
      //   responseHandler: async (response) => (await response.text()).trim(),
      // }),
      transformResponse: async (response) =>
        await decryptResponseTransform(response),
    }),
  }),
});

const catApiSliceExternal = apiSliceExternal.injectEndpoints({
  endpoints: (builder) => ({
    getExternalData: builder.mutation({
      query: ({ url, payload }) => ({
        url,
        method: "POST",
        body: payload,
      }),
    }),
    getExternalDataDetail: builder.mutation({
      query: ({ url, payload }) => ({
        url,
        method: "POST",
        body: payload,
      }),
    }),
    todayFiledCases: builder.query({
      query: (payload) => ({
        url: "todayfiled",
        method: "POST",
        body: payload,
      }),
    }),
    totalPendingCases: builder.query({
      query: (payload) => ({
        url: "todaypending",
        method: "POST",
        body: payload,
      }),
    }),
    totalDisposedCases: builder.query({
      query: (payload) => ({
        url: "todaydisposal",
        method: "POST",
        body: payload,
      }),
    }),
    totalFilledCases: builder.query({
      query: (payload) => ({
        url: "totalfinal",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

const catApiSliceIp = apiSliceIp.injectEndpoints({
  endpoints: (builder) => ({
    getIp: builder.query({
      query: () => ({
        url: "",
      }),
    }),
  }),
});

export const { useGetInternalDataQuery } = catApiSlice;
export const { useGetIpQuery } = catApiSliceIp;
export const {
  useGetExternalDataMutation,
  useGetExternalDataDetailMutation,
  useTotalFilledCasesQuery,
  useTotalDisposedCasesQuery,
  useTotalPendingCasesQuery,
  useTodayFiledCasesQuery,
} = catApiSliceExternal;
